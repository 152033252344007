import React, { useEffect } from 'react';  
import ContentWrapper from './ContentWrapper';
import ShowSocialNetworks from '../../Pages/UserConsole/ShowSocialNetworks';
import logoImage from '../../img/sherut-leumi/logoNavRightNew.svg';
import './Pages.scss'; // We'll create this file next
import axios from 'axios';

const Pages = ({ isMobile, ...props }) => {
    const containerClass = `body-container ${isMobile ? 'mobile' : 'desktop'}`;

    return (
        <div className={containerClass}>
            <header className="main-header">
                <a href="https://sherut-leumi.co.il/" target="_self" rel="noopener noreferrer">
                    <img src={logoImage} alt="האגודה להתנדבות" className="logo-client" />
                </a>
            </header>

            <main className="content">
                {isMobile ? (
                    <div className="mobile-content-container">
                        <ContentWrapper {...props} />
                    </div>
                ) : (
                    <ContentWrapper {...props} />
                )}
            </main>

            <footer className="main-footer">
                <div className="footer-content">
                    <ShowSocialNetworks />
                    <div className="credit">
                        <a href="https://sherut-leumi.co.il/" target="_self" rel="noopener noreferrer">
                            © {new Date().getFullYear()} האגודה להתנדבות שירות לאומי
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Pages;
