import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import loader from "../../../img/preLoader.gif";
import CustomFloatInput from '../../../Components/-Helpers-/forms/CustomFloatInput';
import ModalDefaul from '../../../Components/-Helpers-/ModalDefaul';



// Helper function to convert Gregorian year to Hebrew year
const convertToHebrewYear = (gregorianYear) => {
    // Hebrew year is generally Gregorian year + 3760
    const hebrewYearNum = gregorianYear + 3760;
    
    // Map numbers to Hebrew letters
    const getHebrewYearString = (year) => {
        // Hebrew year mapping (2 years back and 2 years forward)
        const hebYearMap = {
            5786: 'תשפ"ו',
            5785: 'תשפ"ה',
            5784: 'תשפ"ד',
            5783: 'תשפ"ג',
            5782: 'תשפ"ב',
        };
        
        // If we don't have a mapping for this year, generate a more detailed error message
        // instead of showing the numeric year
        if (!hebYearMap[year]) {
            console.error(`Hebrew year mapping missing for ${year}`);
        }
        
        return hebYearMap[year] || `שנה לא ידועה (${year})`;
    };
    
    return getHebrewYearString(hebrewYearNum);
};

const Studies = () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;

    // Hebrew year mapping
    const hebYearMap = {
        5786: 'תשפ"ו',
        5785: 'תשפ"ה',
        5784: 'תשפ"ד',
        5783: 'תשפ"ג',
        5782: 'תשפ"ב',
    };
    
    // Create an array of available years based only on our Hebrew mapping
    const availableYears = Object.entries(hebYearMap).map(([hebYear, hebText]) => {
        const gregYear = parseInt(hebYear) - 3760;
        return {
            id: String(gregYear),
            value: hebText
        };
    });

    const [state, setState] = useState({
        idno: user.IDNO || '',
        SessionKey: user.SessionKey || '',
        PrvSchool: '',
        PrvSchoolText: '',
        sh_year1: '',
        sh_year1_text: '',
        loading: true,
        btnSendClass: 'success',
        responseEditRegister: false,
        years: [],
        checkInputs: false,
        checkInputsPage: false
    });

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IDNO: user.IDNO,
                    SessionKey: user.SessionKey
                })
            });

            const data = await response.json();
            console.log('Raw data from server:', data);
            
            if (data) {
                // Filter years to only include those in our Hebrew mapping
                const yearsArray = data.years && data.years.length > 0 
                    ? data.years.filter(item => {
                        const yearId = typeof item.id === 'string' ? item.id.substring(1) : item.id;
                        const hebYear = parseInt(yearId) + 3760;
                        // Only include years that exist in our Hebrew mapping
                        return hebYear >= 5782 && hebYear <= 5786;
                    }).map(item => {
                        const yearId = typeof item.id === 'string' ? item.id.substring(1) : item.id;
                        const hebYear = parseInt(yearId) + 3760;
                        
                        return {
                            id: yearId,
                            value: hebYearMap[hebYear]
                        };
                    })
                    : availableYears;

                // Handle the case where sh_year1 is returned as Hebrew text instead of ID
                let shYear1Id = data.sh_year1 || '';
                let shYear1Text = data.sh_year1_text || '';
                
                // If sh_year1 contains Hebrew characters, it's the text value not the ID
                if (data.sh_year1 && /[\u0590-\u05FF]/.test(data.sh_year1)) {
                    shYear1Text = data.sh_year1;
                    // Find the corresponding ID from the years array
                    const matchingYear = yearsArray.find(y => y.value === data.sh_year1);
                    if (matchingYear) {
                        shYear1Id = matchingYear.id;
                    }
                }
                
                setState(prev => ({
                    ...prev,
                    PrvSchool: data.prvschool || '',
                    PrvSchoolText: data.PrvSchoolText || '',
                    sh_year1: shYear1Id,
                    sh_year1_text: shYear1Text,
                    years: yearsArray,
                    loading: false
                }));
            } else {
                setState(prev => ({
                    ...prev,
                    years: availableYears,
                    loading: false
                }));
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('שגיאה בטעינת נתוני המשתמש');
            setState(prev => ({ ...prev, loading: false, years: availableYears }));
        }
    };          

    const sendForm = (sendRules) => {
        setState(prev => ({
            ...prev,
            checkInputs: true,
            checkInputsPage: true,
        }));

        if (sendRules) {
            setState(prev => ({ ...prev, loading: true }));
            
            const sendObj = {
                sessionKey: user.SessionKey,
                requestID: "string",
                idno: user.IDNO,
                deviceID: "string",
                prvschool: state.PrvSchool,
                sh_year1: state.sh_year1_text || state.sh_year1
            };
            // if environment is dev, then don't send the categoray
            if (environment !== "dev") sendObj.category = "3";

            sendtoAdmin(sendObj);
        } else {
            toast.error("נא למלא שדות חובה");
            setState(prev => ({ ...prev, btnSendClass: "danger" }));
        }
    };

    const sendtoAdmin = async (objectToSend) => {
        setState(prev => ({ ...prev, loading: true }));

        try {
            const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/set", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(objectToSend)
            });

            if (response.status === 200) {
                setState(prev => ({ 
                    ...prev,
                    responseEditRegister: { ok: "הפרטים עודכנו בהצלחה" }
                }));

                await fetchUserData();
            } else {
                const getData = await response.json();
                setState(prev => ({ 
                    ...prev,
                    responseEditRegister: { error: getData.ErrorMessage || 'שגיאה בעדכון הנתונים' },
                    loading: false 
                }));
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            setState(prev => ({ 
                ...prev,
                responseEditRegister: { error: 'שגיאה בעדכון נתוני המשתמש' },
                loading: false 
            }));
        }
    };

    const updateValue = (newValue) => {
        // If updating the year field, also store its text value
        if (newValue.sh_year1 && state.years.length > 0) {
            const selectedYear = state.years.find(y => y.id === newValue.sh_year1);
            if (selectedYear) {
                newValue.sh_year1_text = selectedYear.value;
            }
        }
        setState(prev => ({ ...prev, ...newValue }));
    };

    const sendRules = state.PrvSchool && state.sh_year1;

    return (
        <div>
            <div className="firstRegister animate__animated animate__fadeIn">
                <img 
                    src={loader} 
                    alt="loader" 
                    className={!state.loading ? "loader" : "loader active animate__animated animate__fadeIn"} 
                />

                <div className="inputs">
                    <div className="lineLabel">
                        <label style={{fontSize: '13px', color: 'grey'}}>בית הספר בו למדתי</label>
                    </div>

                    <div className="rows2 clear line">
                        <CustomFloatInput 
                            name='PrvSchool' 
                            dbParams={{ 
                                function: environment === "dev" ? 'getSchools4SelectDev' : 'getSchools4Select', 
                                controller: 'app', 
                                valueField: 'id' 
                            }} 
                            updateValue={updateValue} 
                            value={state.PrvSchool} 
                            placeholder={state.PrvSchoolText || "בחר/י בית ספר"} 
                            cssClass='' 
                            validationRules={{ required: true }} 
                            typeInput="searchDB" 
                            checkInputs={state.checkInputs} 
                            checked={() => setState(prev => ({ ...prev, checkInputs: false }))} 
                        />

                        <CustomFloatInput 
                            name="sh_year1" 
                            selectOptions={state.years} 
                            updateValue={updateValue} 
                            value={state.sh_year1} 
                            placeholder="שנת סיום לימודים" 
                            cssClass="" 
                            validationRules={{ required: true }} 
                            typeInput="select" 
                            checkInputs={state.checkInputs} 
                            checked={() => setState(prev => ({ ...prev, checkInputs: false }))} 
                        />
                    </div>

                    <div className="text-center">
                        <br/>
                        <Button 
                            className="sendBlueBtn" 
                            onClick={() => sendForm(sendRules)} 
                            size="lg" 
                            variant={sendRules ? 'success animate__animated animate__bounceIn' : state.btnSendClass}
                        >
                            שמירת שינויים
                        </Button>
                    </div>
                </div>

                {state.responseEditRegister?.error && (
                    <ModalDefaul 
                        variant="error" 
                        params={{ 
                            title: "שגיאה", 
                            text: state.responseEditRegister.error 
                        }} 
                        callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))} 
                    />
                )}

                {state.responseEditRegister?.ok && (
                    <ModalDefaul 
                        params={{ 
                            title: "תודה רבה", 
                            text: state.responseEditRegister.ok 
                        }} 
                        callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))} 
                    />
                )}
            </div>
        </div>
    );
};

export default Studies;
