import { RestUrls } from "../config";

/**
 * Fetches data from the API.
 * 
 * @param {string} url - The endpoint URL.
 * @param {Object} objectToSend - The object to send in the request body.
 * @param {string} controller - The controller name.
 * @param {string} [auth="all"] - The authorization type.
 * @param {string} [baseUrl=RestUrls.baseApiUrl] - The base URL for the API.
 * @returns {Object} The response data from the API.
 */
export default async function getDataFromApi(
  url,
  objectToSend,
  controller,
  auth = "all",
  baseUrl = RestUrls.baseApiUrl
) {
  // Construct the full API URL
  const API = `${baseUrl}${controller}/${url}`;

  console.log("API::", API);
  console.log("object to send", objectToSend);

  // Create request payload with development flag and auth details
  const payload = {
    ...objectToSend,
    isRotemDev: true,
    auth: auth,
    token: RestUrls.Code,
  };

  // Log request details in development mode
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    console.log({
      API,
      controller,
      url,
      payload: JSON.stringify(payload)
    });
  }

  try {
    // Send POST request to API
    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Parse response data
    const data = await res.json();

    // Log response in development mode
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      console.log("API Response:", data);
    }

    // Handle unauthorized response
    if (data === "unauthorized" || res.status === 403) {
      console.log("unauthorized, removing userData 1");
      localStorage.removeItem("userData");
      window.location.href = "/login";
      throw new Error('Unauthorized access');
    }

    // Return response data with status
    if (data) {
      return { headerStatus: res.status, ...data };
    }

    throw new Error('Empty response received');
  } catch (error) {
    console.error("API Error:", error);
    
    // Throw error for proper handling in components
    throw new Error(
      `Failed to fetch data: ${error.message || 'Unknown error'}`
    );
  }
}
