const md5 = require("md5");
let code = "seb-webProject!sherut-leumi!wd+=111@$%+";

//console.log(md5(today));

//const baseApiUrl = 'https://otzarhaaretz.wdev.co.il/api/';
const baseApiUrl = "https://sherut-leumi.wdev.co.il/api/";
const baseApiUrl2 = "https://sherut-leumi-dev.wdev.co.il/";
// const baseApiUrl = 'https://sherut-leumi-dev.wdev.co.il/api/';

export const RestUrls = {
  Code: md5(code),
  baseApiUrl: baseApiUrl,
  baseApiUrl2: baseApiUrl2,
  // REACT_APP_API_BASE_URL=https://vu-apiws.azurewebsites.net/api/
  // use env
  sherutLeumiApi: process.env.REACT_APP_API_BASE_URL,
  pictures: baseApiUrl + "assets/img/pictures/",
  pagesPictures: baseApiUrl + "assets/img/pages/",
  img: baseApiUrl + "assets/img/",
  appUrl: "https://app.sherut-leumi.co.il/",
};
