import React, { useState, useRef, useCallback, useEffect } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import SearchAutocompleteAndDbFocus from "./../../-Helpers-/api/SearchAutocompleteAndDbFocus";
import { FormValidator } from "./validation/FormValidator";
import { ValidationMessage } from "./validation/ValidationMessage";
import { ValidateForm } from "./validation/wholeFormValidation";
import { toast } from "react-toastify";

/**
 * CustomFloatInput component that provides various input types with floating labels and validation
 */
const CustomFloatInput = React.memo((props) => {
  const {
    value,
    selectOptions,
    noEnglish,
    validationRules,
    name,
    checkInputs,
    checked,
    dbParams,
    typeInput,
    cssClass,
    zIndexClass = "",
    disabled,
    placeholder,
    dateText,
    BirthDateText2,
    updateValue
  } = props;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const [isError, setIsError] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const myInputRef = useRef();
  useEffect(() => {
    if (environment === "dev") {
      console.log("environment", environment);
    }
  }, [environment]);

  const rules = {
    inputValue: validationRules
  };

  const handleChange = useCallback((event) => {
    let newValue = event.target.value;

    if (noEnglish) {
      if (event.target.value !== newValue.replace(/[A-Za-z]/gi, "")) {
        toast.error("יש להקליד אותיות בעברית", {
          toastId: "customId",
        });
      }
      newValue = newValue.replace(/[A-Za-z]/gi, "");
    }

    // Special handling for phone numbers when validationRules.phone is true
    if (validationRules && validationRules.phone && name.toLowerCase().includes('mobile')) {
      // Allow digits, dashes, plus signs, and parentheses for phone numbers
      if (typeInput === "text") {
        // Format the phone number with dashes for readability
        const digitsOnly = newValue.replace(/\D/g, '');
        
        // Format as XXX-XXXXXXX if it starts with 05
        if (digitsOnly.startsWith('05') && digitsOnly.length >= 3) {
          if (digitsOnly.length <= 6) {
            // Format as 05X-XXX
            newValue = `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
          } else {
            // Format as 05X-XXX-XXXX
            newValue = `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
          }
        } 
        // Handle international format
        else if (digitsOnly.startsWith('972') && digitsOnly.length > 3) {
          newValue = `+${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
        }
        // If it doesn't match known patterns, keep original input but limit length
        else if (digitsOnly.length > 10) {
          newValue = digitsOnly.slice(0, 10);
        }
      } else if (typeInput === "number") {
        // Only keep digits for the actual value if typeInput is number
        newValue = newValue.replace(/\D/g, '');
      }
    }

    updateValue({ [name]: newValue });
  }, [name, noEnglish, updateValue, validationRules, typeInput]);

  const isOK = useCallback((validationResult) => {
    const isValid = validationResult.inputValue === undefined || 
                   (validationRules.required ? value && value.length > 0 : true);
    setIsError(!isValid);
    return isValid;
  }, [validationRules, value]);

  useEffect(() => {
    if (checkInputs) {
      isOK({ inputValue: value });
      checked();
    }
  }, [checkInputs, isOK, checked]);

  const handleChangeSelect = useCallback((event) => {
    const newValue = event.target.value;
    updateValue({ [name]: newValue });
  }, [name, updateValue]);

  const setInputFocus = useCallback(() => {
    setShowInput(true);
    setTimeout(() => {
      myInputRef.current?.focus();
    });
  }, []);

  const handleSearchSelected = useCallback((item) => {
    const field = dbParams.valueField;
    const newValue = item[field];
    setIsError(false);
    updateValue({ [name]: newValue });
  }, [dbParams, name, updateValue]);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <div className={`input inputType${typeInput} ${zIndexClass}`}>
      {(typeInput === "text" || typeInput === "password" || typeInput === "number") && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <div className="form-floating">
            <div className="password-input-container">
              <label 
                htmlFor={name}
                style={{ 
                  fontWeight: "400",
                  color: "#6c757d"
                }}
              >{placeholder}</label>
              <Form.Control
                className={`customFloatInput ${cssClass}`}
                isInvalid={isError}
                type={typeInput === "password" ? (showPassword ? "text" : "password") : typeInput}
                name="inputValue"
                id={name}
                disabled={disabled}
                onChange={handleChange}
                placeholder=" "
                value={value || ""}
                style={{ 
                  padding: "0.75rem 0.75rem",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #ced4da",
                  transition: "all 0.2s ease-in-out",
                  fontSize: "16px",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                  height: "48px"
                }}
              />
              {typeInput === "password" && (
                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={togglePasswordVisibility}
                  tabIndex="-1"
                >
                  <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} />
                </button>
              )}
              <Form.Control.Feedback type="invalid">
                <ValidationMessage field="inputValue" />
              </Form.Control.Feedback>
            </div>
          </div>
        </FormValidator>
      )}

      {typeInput === "searchDB" && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <div className={isError ? "customInputSearch errorClass" : "customInputSearch"}>
            <SearchAutocompleteAndDbFocus
              keys={["name"]}
              dbParams={{
                functionName: dbParams.function,
                controller: dbParams.controller,
              }}
              placeholder={placeholder}
              submit={handleSearchSelected}
              DBItems={environment === "dev" ? "app/getCities4SelectDev" : "app/getCities4Select"}
              disabled={disabled}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            <ValidationMessage field="inputValue" />
          </Form.Control.Feedback>
        </FormValidator>
      )}

      {typeInput === "select" && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <Form.Control
            as="select"
            className={`customSelectInput ${cssClass}`}
            isInvalid={isError}
            type={typeInput}
            name="inputValue"
            onChange={handleChangeSelect}
            value={value || ""}
            disabled={disabled}
            style={{ 
              padding: "0.75rem 0.75rem",
              backgroundColor: "#fff",
              borderRadius: "8px",
              border: "1px solid #ced4da",
              transition: "all 0.2s ease-in-out",
              fontSize: "16px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
              height: "48px"
            }}
          >
            <option value="">{placeholder}</option>
            {(selectOptions || []).map((item) => (
              <option value={item.id} key={item.id}>
                {item.value}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            <ValidationMessage field="inputValue" />
          </Form.Control.Feedback>
        </FormValidator>
      )}

      {typeInput === "date" && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <FloatingLabel 
            controlId={name} 
            label={placeholder}
            style={{ 
              fontWeight: "400",
              color: "#6c757d"
            }}
          >
            <Form.Control
              style={{ 
                marginTop: "16px", 
                textAlign: "center",
                padding: "0.75rem 0.75rem",
                backgroundColor: "#fff",
                borderRadius: "8px",
                border: "1px solid #ced4da",
                transition: "all 0.2s ease-in-out",
                fontSize: "16px",
                boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                height: "48px"
              }}
              ref={myInputRef}
              className={`customFloatInput ${cssClass}`}
              isInvalid={isError}
              disabled={disabled}
              type="date"
              max="9999-12-31"
              name="inputValue"
              placeholder={dateText}
              onChange={(e) => {
                handleChange(e);
                setDirty(true);
              }}
              value={value || ""}
            />
            <Form.Control.Feedback type="invalid">
              <ValidationMessage field="inputValue" />
            </Form.Control.Feedback>
          </FloatingLabel>
        </FormValidator>
      )}
    </div>
  );
});

CustomFloatInput.displayName = 'CustomFloatInput';

export default CustomFloatInput;
