import React, { useState, useEffect } from "react";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import { RestUrls } from "../../Components/-Helpers-/config";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import loader from "../../img/preLoader.gif";
import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";
import { 
  Paper, 
  Typography,
  Box,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import SmsIcon from '@mui/icons-material/Sms';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

// פונקציית עזר לקבלת צבע לפי סטטוס השלב
const getStepColor = (stepIndex, currentStep) => {
  if (stepIndex < currentStep - 1) return "#4caf50"; // שלב הושלם - ירוק
  if (stepIndex === currentStep - 1) return "#1976d2"; // שלב נוכחי - כחול
  return "#ccc"; // שלב עתידי - אפור
};

const CustomStepperIcon = ({ icon, isActive, isCompleted }) => {
  const bgColor = isCompleted ? "#4caf50" : isActive ? "#1976d2" : "#ccc";
  
  // סגנון מיוחד לאייקון פעיל - מסגרת והבהוב עדין
  const activeStyle = isActive ? {
    border: '2px solid #1976d2',
    animation: 'pulse 2s infinite',
    boxShadow: '0 0 0 4px rgba(25, 118, 210, 0.2), 0 4px 10px 0 rgba(0,0,0,.25)'
  } : {};
  
  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: '#fff',
        width: 40,
        height: 40,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: isActive ? '0 4px 10px 0 rgba(0,0,0,.25)' : 'none',
        ...activeStyle
      }}
    >
      {isCompleted ? <CheckCircleOutlineIcon /> : icon}
    </div>
  );
};

// סטפר מותאם אישית
const CustomStepper = ({ steps, activeStep }) => {
  return (
    <div className="custom-stepper-container" style={{ marginBottom: 30 }}>
      {/* סגנון CSS לאנימציית פעימה */}
      <style>
        {`
          @keyframes pulse {
            0% { box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4); }
            70% { box-shadow: 0 0 0 6px rgba(25, 118, 210, 0); }
            100% { box-shadow: 0 0 0 0 rgba(25, 118, 210, 0); }
          }
          
          @keyframes activeStep {
            0% { transform: translateY(0); }
            50% { transform: translateY(-2px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
      
      <div 
        style={{ 
          display: 'flex', 
          justifyContent: 'center',
          position: 'relative',
          margin: '20px 0',
          direction: 'rtl'
        }}
      >
        {steps.map((step, index) => {
          const isActive = activeStep === index + 1;
          const isCompleted = activeStep > index + 1;
          
          return (
            <div 
              key={index} 
              style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: `${100 / steps.length}%`,
                position: 'relative',
                zIndex: 1,
                // אנימציה קלה לשלב הפעיל
                animation: isActive ? 'activeStep 2s ease-in-out infinite' : 'none',
                // רקע לטקסט של השלב הפעיל
                padding: '5px',
                borderRadius: '8px',
                backgroundColor: isActive ? 'rgba(25, 118, 210, 0.08)' : 'transparent'
              }}
            >
              {/* חץ מלמעלה עבור השלב הפעיל */}
              {isActive && (
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid #1976d2',
                    marginBottom: '5px'
                  }}
                />
              )}
              
              <CustomStepperIcon 
                icon={step.icon}
                isActive={isActive}
                isCompleted={isCompleted}
              />
              
              <div 
                style={{ 
                  marginTop: 8,
                  fontWeight: isActive ? 'bold' : 'normal',
                  color: getStepColor(index, activeStep),
                  fontSize: isActive ? '1rem' : '0.9rem',
                  // רקע לטקסט של השלב הפעיל
                  padding: isActive ? '4px 8px' : '0',
                  borderRadius: isActive ? '4px' : '0',
                  backgroundColor: isActive ? 'rgba(25, 118, 210, 0.1)' : 'transparent'
                }}
              >
                {step.label}
              </div>
              
              {/* מספר השלב לכל שלב */}
              <div
                style={{
                  position: 'absolute',
                  top: -18,
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  color: isActive ? '#1976d2' : isCompleted ? '#4caf50' : '#999'
                }}
              >
                שלב {index + 1}
              </div>
              
              {/* קו מחבר בין השלבים */}
              {index < steps.length - 1 && (
                <div 
                  style={{ 
                    position: 'absolute',
                    top: 53,  // התאמה למיקום האיקונים
                    right: '60%',
                    left: '40%',
                    height: 2,
                    backgroundColor: getStepColor(index, activeStep),
                    zIndex: 0
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      
      {/* סרגל התקדמות */}
      <div 
        style={{ 
          width: '100%', 
          height: '6px', 
          backgroundColor: '#eaeaea', 
          borderRadius: '3px',
          marginTop: '15px',
          overflow: 'hidden'
        }}
      >
        <div 
          style={{ 
            width: `${(activeStep - 1) / (steps.length - 1) * 100}%`, 
            height: '100%', 
            backgroundColor: '#1976d2',
            borderRadius: '3px',
            transition: 'width 0.5s ease-in-out'
          }}
        />
      </div>
    </div>
  );
};

const ForgotPasswordNew = ({ siteInfo }) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;

  const [IDNO, setIDNO] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseLogin, setResponseLogin] = useState(false);
  const [btnSendClass, setBtnSendClass] = useState("");
  const [checkInputs, setCheckInputs] = useState(false);
  const [step, setStep] = useState(1); // 1: Enter ID, 2: Enter OTP, 3: Enter new password
  const [keyGuid, setKeyGuid] = useState(""); // Store the Key from requestOTP response
  const [authToken, setAuthToken] = useState(""); // Store the JWT token
  const ReactNativeWebView = window.ReactNativeWebView;

  // Steps for the stepper
  const steps = [
    { label: "תעודת זהות", icon: <PersonIcon /> },
    { label: "קוד אימות", icon: <SmsIcon /> },
    { label: "סיסמה חדשה", icon: <VpnKeyIcon /> }];

  useEffect(() => {
    console.log(window.ReactNativeWebView, "window.ReactNativeWebView");
  }, []);

  const updateValue = (newValue) => {
    if (newValue.IDNO !== undefined) {
      setIDNO(newValue.IDNO);
    }
    if (newValue.otpCode !== undefined) {
      setOtpCode(newValue.otpCode);
    }
    if (newValue.newPassword !== undefined) {
      setNewPassword(newValue.newPassword);
    }
  };

  // Step 1: Request OTP
  const requestOtp = () => {
    setLoading(true);
    setBtnSendClass("");

    const sendObj = {
      SocialId: IDNO,
    };

    sendToApi("/api/v2/Volunteer/login/requestOtp", "Volunteer", sendObj);
  };

  // Step 2: Verify OTP
  const verifyOtp = () => {
    setLoading(true);
    setBtnSendClass("");

    const sendObj = {
      Otp: otpCode,
      Key: keyGuid,
    };

    sendToApi("/api/v2/Volunteer/login/Otp", "Volunteer", sendObj);
  };

  // Step 3: Change Password
  const changePassword = () => {
    // Validate password: exactly 1 uppercase letter, 1 lowercase letter, followed by exactly 6 digits
    const passwordRegex = /^[A-Z][a-z]\d{6}$/;
    
    console.log("Testing password:", newPassword, "Result:", passwordRegex.test(newPassword));
    
    if (!passwordRegex.test(newPassword)) {
      setResponseLogin({
        error: "הסיסמה חייבת להכיל אות גדולה אחת, אות קטנה אחת, ולאחר מכן 6 ספרות"
      });
      return;
    }

    setLoading(true);
    setBtnSendClass("");

    const sendObj = {
      NewPassword: newPassword,
    };

    sendToApi("/api/v2/Volunteer/changePassword", "Volunteer", sendObj, "auth");
  };

  const sendToApi = async (url, controller, objectToSend, authType = "all") => {
    console.log("sending...");
    setLoading(true);

    try {
      // Prepare request options
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(objectToSend)
      };

      // Add authorization header for authenticated requests
      if (authType === "auth" && authToken) {
        options.headers.Authorization = `Bearer ${authToken}`;
      }

      // Make the fetch request to the local endpoint
      const response = await fetch(`${API_BASE_URL}/${url}`, options);
      const getData = await response.json();
      
      console.log("Response from server:", getData);
      
      setLoading(false);

      // Check for business errors in all endpoints first
      if (getData.IsBusinessError !== undefined) {
        if (getData.IsBusinessError === true) {
          // Business error - show the specific error message
          setResponseLogin({
            error: getData.ErrorMessage || "שגיאת משתמש"
          });
          return;
        } else if (getData.ErrorMessage) {
          // System error - show generic message
          setResponseLogin({
            error: "שגיאת מערכת"
          });
          return;
        }
      }

      // Handle response based on the current step
      if (step === 1 && getData.Key) {
        setKeyGuid(getData.Key);
        setStep(2);
        setResponseLogin({
          success: true,
          message: "קוד אימות נשלח לנייד שלך"
        });
      } else if (step === 2 && getData.Token) {
        setAuthToken(getData.Token);
        setStep(3);
        setResponseLogin({
          success: true,
          message: "האימות הצליח, כעת הזן סיסמה חדשה"
        });
      } else if (getData.Result === 'Success') {
        // בדיקת ההצלחה המקורית
        if (step === 1) {
          // Save key and move to OTP verification
          setKeyGuid(getData.Key);
          setStep(2);
          setResponseLogin({
            success: true,
            message: "קוד אימות נשלח לנייד שלך"
          });
        } else if (step === 2) {
          // Save token and move to password change
          setAuthToken(getData.Token);
          setStep(3);
          setResponseLogin({
            success: true,
            message: "האימות הצליח, כעת הזן סיסמה חדשה"
          });
        } else if (step === 3) {
          // Password change successful
          setResponseLogin({
            success: true,
            message: "הסיסמה שונתה בהצלחה"
          });
          
          // Wait 2 seconds before redirecting to login
          setTimeout(() => {
            window.open("/login", "_self");
          }, 2000);
        }
      } else if (getData.ErrorMessage) {
        setResponseLogin({
          error: getData.ErrorMessage
        });
      } else if (getData.error) {
        setResponseLogin({
          error: getData.error
        });
      }
    } catch (error) {
      console.error("Error sending data:", error);
      setLoading(false);
      setResponseLogin({
        error: "שגיאת מערכת" // Changed from "אירעה שגיאה בתהליך" to a generic system error
      });
    }
  };

  const getStepForm = () => {
    switch (step) {
      case 1:
        return (
          <>
            <header style={{ textAlign: "center" }}>
              <h1 className="boldTypeFamily">איפוס סיסמה</h1>
            
              <p style={{ paddingTop: "0px", paddingBottom: "8px" }}>בהזנת תעודת זהות ישלח קוד אימות לנייד</p>
             
            </header>
            <div className="inputs" style={{ textAlign: "center" }}>
              <div className="line">
                <CustomFloatInput
                  name="IDNO"
                  updateValue={updateValue}
                  value={IDNO}
                  placeholder="תעודת זהות"
                  cssClass=""
                  validationRules={{ required: true, tz: true }}
                  typeInput="text"
                  checkInputs={checkInputs}
                  checked={() => setCheckInputs(false)}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <br />
                <Button
                  disabled={loading}
                  className="sendBlueBtn"
                  onClick={requestOtp}
                  size="lg"
                  variant={IDNO ? "success animate__animated animate__bounceIn" : btnSendClass}
                >
                  שלח קוד אימות לנייד
                </Button>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <header style={{ textAlign: "center" }}>
              <h1 className="boldTypeFamily">אימות קוד</h1>
              <br/>
              <p>הזן את הקוד שנשלח לנייד שלך</p>
              <br/>
            </header>
            <div className="inputs" style={{ textAlign: "center" }}>
              <div className="line">
                <CustomFloatInput
                  name="otpCode"
                  updateValue={updateValue}
                  value={otpCode}
                  placeholder="קוד אימות"
                  cssClass=""
                  validationRules={{ required: true }}
                  typeInput="text"
                  checkInputs={checkInputs}
                  checked={() => setCheckInputs(false)}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <br />
                <Button
                  disabled={loading}
                  className="sendBlueBtn"
                  onClick={verifyOtp}
                  size="lg"
                  variant={otpCode ? "success animate__animated animate__bounceIn" : btnSendClass}
                >
                  אמת קוד
                </Button>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <header style={{ textAlign: "center" }}>
              <h1 className="boldTypeFamily">הגדרת סיסמה חדשה</h1>
              
              <p>הזן סיסמה חדשה העומדת בדרישות: אות גדולה אחת, אות קטנה אחת, ולאחר מכן 6 ספרות</p>
              <p>דוגמא: Ab123456</p>
            </header>
            <div className="inputs" style={{ textAlign: "center" }}>
              <div className="line">
                <CustomFloatInput
                  name="newPassword"
                  updateValue={updateValue}ולאחר
                  value={newPassword}
                  placeholder="סיסמה חדשה"
                  cssClass=""
                  validationRules={{ required: true }}
                  typeInput="password"
                  checkInputs={checkInputs}
                  checked={() => setCheckInputs(false)}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <br />
                <Button
                  disabled={loading}
                  className="sendBlueBtn"
                  onClick={changePassword}
                  size="lg"
                  variant={newPassword ? "success animate__animated animate__bounceIn" : btnSendClass}
                >
                  שמור סיסמה חדשה
                </Button>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const isMobile = siteInfo.isMobile ? siteInfo.isMobile : false;
  let m_picTop = RestUrls.pagesPictures + "register/m_registerTop.jpg?v=3";

  return (
    <div className="firstRegister loginPage ForgotPasswordNew animate__animated animate__fadeIn" style={{ textAlign: "center" }}>
      <img
        src={loader}
        alt="loader"
        className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"}
      />

      {isMobile && <img className="topPic" src={m_picTop} alt="top" />}

      <div className="responsive-container" style={{ 
        maxWidth: "600px", 
        margin: "0 auto", 
        padding: "0 15px",
        width: "100%"
      }}>
        <Paper 
          elevation={3} 
          style={{ 
            padding: "20px", 
            marginTop: "20px", 
            marginBottom: "20px",
            borderRadius: "8px",
            direction: "rtl"
          }}
        >
          <Box mb={3} textAlign="center">
            <Typography variant="h5" component="h1" sx={{ fontWeight: 'semi-bold', color: 'black' }}>
              שכחת סיסמא?
              <br/>
              כאן תוכלו ליצור סיסמא חדשה
            </Typography>
         
          </Box>
        
          {/* סטפר מותאם אישית */}
          <Box sx={{ width: '100%', mb: 4 }}>
            <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold', mb: 1, color: '#1976d2' }}>
              <strong>שלב {step} מתוך 3</strong>: {steps[step-1].label}
            </Typography>
            
            <CustomStepper steps={steps} activeStep={step} />
          </Box>
          
          {/* Feedback Message */}
          {responseLogin && responseLogin.success && (
            <Box 
              mt={2} 
              p={1} 
              sx={{ 
                backgroundColor: '#e8f5e9', 
                borderRadius: 1,
                marginBottom: 2
              }}
            >
              <Typography color="success" align="center">
                {responseLogin.message}
              </Typography>
            </Box>
          )}
          
          {responseLogin && responseLogin.error && (
            <Box 
              mt={2} 
              p={1} 
              sx={{ 
                backgroundColor: '#ffebee', 
                borderRadius: 1,
                marginBottom: 2
              }}
            >
              <Typography color="error" align="center">
                {responseLogin.error}
              </Typography>
            </Box>
          )}

          <Box mt={3}>
            {getStepForm()}
          </Box>
        </Paper>

        {ReactNativeWebView === undefined && step === 1 && (
          <div className="text-center">
            <div className="singIn">
              <NavLink
                className="jumpPage"
                role="menuitem"
                onClick={() => scroll.scrollTo(0)}
                to={"/register"}
              >
                <span>
                  עדין לא רשומים? <strong>לחצו להרשמה (נרשמים חדשים)</strong>
                </span>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordNew;
