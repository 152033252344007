/**
 * ExternalLink - קומפוננט לטיפול בקישורים חיצוניים
 * 
 * מציג כפתור שפותח קישור חיצוני (למשל לדיווח תקלה או שאלון)
 * 
 * Props:
 * - formData: נתוני הטופס כולל ה-URL
 * - texts: טקסטים להצגה
 */
import React, { useState } from 'react';
import v from '../../../img/sherut-leumi/svg/files/v.svg';
import link from '../../../img/sherut-leumi/svg/files/link.svg';
import { Modal, Button } from 'react-bootstrap';
import infoModal from '../../../img/sherut-leumi/svg/files/infoModal.svg';
import { Grid } from '@material-ui/core';
import fileBgQuad from '../../../img/sherut-leumi/svg/files/fileBgQuad.png';
import x from '../../../img/sherut-leumi/svg/files/x.svg';

export default function ExternalLink({ formData, texts }) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleGoToUrl = () => {
    setShowModal(false);
    const url = formData?.URL || '#';
    window.open(url, '_blank');
  };

  const isDocumentExists = formData.Status !== "Missing";

  return (
    <div className="fileItem">
      <div className="header">
        <h3>
          <img src={link} alt="link" />
          <span>{texts.title}</span>
        </h3>
        <p>{texts.filesType}</p>
      </div>

      {isDocumentExists ? (
        <div className="blueQuad docExist fullBorder">
          <img src={v} alt="המסמך קיים במערכת" />
          <p>המסמך קיים במערכת</p>
        </div>
      ) : (
        <div className="blueQuad externalLink fullBorder">
          <div className="linkP">(לינק חיצוני)</div>
          <button className="blueBtn" onClick={handleOpenModal}>
            {texts.btnName}
          </button>
        </div>
      )}

      <Modal
        className="animate__animated animate__fadeInDown animate__faster modalSite filesModal"
        animation={false}
        onHide={handleCloseModal}
        show={showModal}
        dialogClassName="modal-90w"
        aria-labelledby="external-link-modal"
      >
        <Modal.Body>
          <Button className="closeBtn" onClick={handleCloseModal} variant="secondary">
            X
          </Button>

          <div className="Message">
            <img src={infoModal} alt={texts.startText} />
            <h2>{texts.startText}</h2>
            {texts?.startTextP && <p>{texts.startTextP}</p>}
          </div>

          <Grid 
            className="btnCont" 
            container 
            spacing={2} 
            alignItems="center" 
            direction="row" 
            justifyContent="center"
          >
            <Grid item md={6}>
              <div className="blueBtn" onClick={handleGoToUrl}>
                {texts.btnName}
              </div>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>
    </div>
  );
}
