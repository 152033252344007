import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import loader from "../../../img/preLoader.gif";
import CustomFloatInput from "../../../Components/-Helpers-/forms/CustomFloatInput";
import man from "../../../img/sherut-leumi/svg/man.svg";
import woman from "../../../img/sherut-leumi/svg/woman.svg";
import ModalDefaul from "../../../Components/-Helpers-/ModalDefaul";
import { toast } from "react-toastify";

function get17YearsAgo() {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date();
  date.toLocaleDateString("he-IL", options);
  date.setFullYear(date.getFullYear() - 17);
  return date.toISOString().substr(0, 10);
}

export default function PersonalInformation({ isMobile = false }) {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL; 
  const user = JSON.parse(localStorage.getItem("userData"));
  
  const [state, setState] = useState({
    idno: user.IDNO || '',
    SessionKey: user.SessionKey || '',
    FirstName: '',
    LastName: '',
    Mobile: '',
    BirthDate: '',
    BirthDateText: '',
    Email: '',
    sex: '',
    loading: true,
    btnSendClass: "success",
    checkInputsPage: false,
    checkInputs: false,
    responseEditRegister: false,
    isFormLocked: false
  });

  useEffect(() => {
    fetchUserData();
    checkIdDocumentStatus();
  }, []);

  const checkIdDocumentStatus = async () => {
    try {
      const response = await fetch(API_BASE_URL + "/api/v2/volunteer/forms/list", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          IDNumber: user.IDNO,
          SessionKey: user.SessionKey
        })
      });

      const data = await response.json();
      console.log('Form status response:', data.Forms);
      
      if (data?.Forms) {
        const idCardDocument = data.Forms.find(form => form.FormID === '1');
        console.log('ID Card document:', idCardDocument);
        
        if (idCardDocument && idCardDocument.Status === "Exist") {
          console.log('Setting form to locked state - ID document is approved');
          setState(prev => ({ 
            ...prev, 
            isFormLocked: true
          }));
          toast.info("תעודת הזהות כבר אושרה, לא ניתן לערוך את הפרטים האישיים");
        }
      }
    } catch (error) {
      console.error('Error checking ID document status:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          IDNO: user.IDNO,
          SessionKey: user.SessionKey
        })
      });

      const data = await response.json();
      console.log('Raw data from server:', data);
      
      if (data) {
        let sexValue = '';
        if (data.sex === 'זכר') sexValue = '1';
        else if (data.sex === 'נקבה') sexValue = '2';

        let formattedDate = '';
        if (data.birthdate) {
          console.log('Original birthdate:', data.birthdate);
          formattedDate = `${data.birthdate.substr(0,4)}-${data.birthdate.substr(4,2)}-${data.birthdate.substr(6,2)}`;
          console.log('Formatted birthdate:', formattedDate);
        }

        setState(prev => ({
          ...prev,
          FirstName: data.firstname || '',
          LastName: data.lastname || '',
          Mobile: data.mobile || '',
          BirthDate: formattedDate,
          BirthDateText: data.BirthDateText || '',
          Email: data.email || '',
          sex: sexValue,
          loading: false
        }));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('שגיאה בטעינת נתוני המשתמש');
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const sendForm = (sendRules) => {
    setState(prev => ({
      ...prev,
      checkInputs: true,
      checkInputsPage: true,
    }));

    if (sendRules) {
      setState(prev => ({ ...prev, loading: true }));
      
      const sendObj = {
        sessionKey: user.SessionKey,
        requestID: "string",
        idno: user.IDNO,
        deviceID: "string",
      };
      if (environment !== "dev") sendObj.category = "3";

      if (!state.isFormLocked) {
        if (state.sex === "1" || state.sex === "2") sendObj.sex = state.sex;
        if (state.FirstName) sendObj.firstName = state.FirstName;
        if (state.LastName) sendObj.lastName = state.LastName;
        if (state.BirthDate) sendObj.birthDate = state.BirthDate.replace(/-/g, '');
      }
      if (state.Mobile) sendObj.mobile = state.Mobile;
      if (state.Email) sendObj.email = state.Email;

      sendtoAdmin(sendObj);
    } else {
      toast.error("נא למלא שדות חובה");
      setState(prev => ({ ...prev, btnSendClass: "danger" }));
    }
  };

  const sendtoAdmin = async (objectToSend) => {
    setState(prev => ({ ...prev, loading: true }));

    try {
      const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/set", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(objectToSend)
      });

      if (response.status === 200) {
        let userData = JSON.parse(localStorage.getItem("userData"));
        userData["FirstName"] = objectToSend.firstName;
        userData["LastName"] = objectToSend.lastName;
        userData["Sex"] = objectToSend.sex;
        localStorage.setItem("userData", JSON.stringify(userData));
        
        setState(prev => ({ 
          ...prev,
          responseEditRegister: { ok: "הפרטים עודכנו בהצלחה" }
        }));

        await fetchUserData();
      } else {
        const getData = await response.json();
        setState(prev => ({ 
          ...prev,
          responseEditRegister: { error: getData.ErrorMessage || 'שגיאה בעדכון הנתונים' },
          loading: false 
        }));
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      setState(prev => ({ 
        ...prev,
        responseEditRegister: { error: 'שגיאה בעדכון נתוני המשתמש' },
        loading: false 
      }));
    }
  };

  const updateValue = (newValue) => {
    const fieldName = Object.keys(newValue)[0];
    console.log('Attempting to update field:', fieldName, 'Form locked:', state.isFormLocked);
    
    // If form is locked and trying to update a locked field, prevent the update
    if (state.isFormLocked && fieldName !== 'Mobile') {
      console.log('Update prevented - field is locked');
      return;
    }
    
    console.log('Update allowed');
    setState(prev => ({ ...prev, ...newValue }));
  };

  // Prevent default on input events when field should be locked
  const handleInputEvent = (e) => {
    if (state.isFormLocked && e.target.name !== 'Mobile') {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const sendRules = () => {
    // Always validate Mobile and Email
    const mobileAndEmailValid = state.Mobile && state.Email;

    // If form is locked, only check Mobile and Email
    if (state.isFormLocked) {
      return mobileAndEmailValid;
    }

    // If form is not locked, check all fields
    return state.sex &&
           state.FirstName &&
           state.LastName &&
           state.idno &&
           mobileAndEmailValid &&
           state.BirthDate;
  };

  // If form is locked, button is disabled unless we have mobile and email
  // If form is not locked, button is never disabled
  const isSaveButtonDisabled = state.isFormLocked && (!state.Mobile || !state.Email);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <div className="firstRegister animate__animated animate__fadeIn">
        <img
          src={loader}
          alt="loader"
          className={
            !state.loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        {state.isFormLocked ? (
          <>
            <div className="notification-message" style={{ 
              backgroundColor: '#fff3cd', 
              padding: '15px 20px', 
              borderRadius: '8px', 
              margin: '15px 0',
              border: '1px solid #ffeeba',
              textAlign: 'center',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
            }}>
              <span style={{ fontSize: '24px' }}>🔒</span>
              <p style={{ 
                margin: 0, 
                color: '#856404',
                fontWeight: 'bold'
              }}>
                תעודת הזהות כבר אושרה, לא ניתן לערוך את הפרטים האישיים
              </p>
            </div>

            <div className="info-card">
              <div className="info-section">
                <div className="info-row">
                  <div className="info-label">שם פרטי</div>
                  <div className="info-value">{state.FirstName}</div>
                </div>
                <div className="info-row">
                  <div className="info-label">שם משפחה</div>
                  <div className="info-value">{state.LastName}</div>
                </div>
                <div className="info-row">
                  <div className="info-label">תעודת זהות</div>
                  <div className="info-value">{state.idno}</div>
                </div>
                <div className="info-row">
                  <div className="info-label">תאריך לידה</div>
                  <div className="info-value">{formatDate(state.BirthDate)}</div>
                </div>
                <div className="info-row">
                  <div className="info-label">מין</div>
                  <div className="info-value">
                    {state.sex === "1" ? (
                      <div className="gender-display">
                        <img src={man} alt="זכר" style={{width: '20px', marginLeft: '8px'}} />
                        זכר
                      </div>
                    ) : state.sex === "2" ? (
                      <div className="gender-display">
                        <img src={woman} alt="נקבה" style={{width: '20px', marginLeft: '8px'}} />
                        נקבה
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="info-row">
                  <div className="info-label">טלפון נייד</div>
                  <div className="info-value">{state.Mobile}</div>
                </div>
                <div className="info-row">
                  <div className="info-label">דוא"ל</div>
                  <div className="info-value">{state.Email}</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="inputs">
            <div className="rows2 clear line">
              <CustomFloatInput
                disabled={state.isFormLocked}
                name="FirstName"
                updateValue={updateValue}
                value={state.FirstName}
                placeholder="שם פרטי"
                cssClass={state.isFormLocked ? "disabled" : ""}
                validationRules={{ required: true, minlength: 2, alpha: true }}
                typeInput="text"
                checkInputs={state.checkInputs}
                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                onInput={handleInputEvent}
                readOnly={state.isFormLocked}
              />
              <CustomFloatInput
                disabled={state.isFormLocked}
                name="LastName"
                updateValue={updateValue}
                value={state.LastName}
                placeholder="שם משפחה"
                cssClass={state.isFormLocked ? "disabled" : ""}
                validationRules={{ required: true, minlength: 2, alpha: true }}
                typeInput="text"
                checkInputs={state.checkInputs}
                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                onInput={handleInputEvent}
                readOnly={state.isFormLocked}
              />
            </div>

            <div className="rows2 clear line">
              <CustomFloatInput
                disabled={true}
                name="idno"
                updateValue={updateValue}
                value={state.idno}
                placeholder="תעודת זהות"
                cssClass="disabled"
                validationRules={{ required: true, tz: true }}
                typeInput="text"
                checkInputs={state.checkInputs}
                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                readOnly={true}
              />
              <CustomFloatInput
                disabled={false}
                name="Mobile"
                updateValue={updateValue}
                value={state.Mobile}
                placeholder="טלפון נייד"
                cssClass=""
                validationRules={{ required: true, phone: true }}
                typeInput="text"
                checkInputs={state.checkInputs}
                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
              />
            </div>

            <div className="clear line">
              <CustomFloatInput
                isMobile={isMobile}
                disabled={state.isFormLocked}
                name="BirthDate"
                updateValue={updateValue}
                value={state.BirthDate}
                placeholder="תאריך לידה"
                cssClass={state.isFormLocked ? "disabled" : ""}
                validationRules={{ required: true }}
                typeInput="date"
                dateText={state.BirthDateText}
                checkInputs={state.checkInputs}
                startDate={get17YearsAgo()}
                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                onInput={handleInputEvent}
                readOnly={state.isFormLocked}
              />
              <br/>
              <CustomFloatInput
                name="Email"
                updateValue={updateValue}
                value={state.Email}
                placeholder='דוא"ל'
                cssClass={state.isFormLocked ? "disabled" : ""}
                validationRules={{ required: true, email: true }}
                typeInput="text"
                checkInputs={state.checkInputs}
                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                onInput={handleInputEvent}
                readOnly={state.isFormLocked}
                disabled={state.isFormLocked}
              />
            </div>

            <div className="line selectButtons">
              <label
                className={
                  state.checkInputsPage && !state.sex
                    ? "errorClass"
                    : "1"
                }
              >
                {state.sex === null ? "מין לא הוזן" : "מין"}
              </label>

              <div className="buttonsCont">
                <button
                  disabled={state.isFormLocked}
                  onClick={() =>
                    setState(prev => ({ ...prev, sex: "1", checkInputsPage: false }))
                  }
                  className={
                    state.sex === "1" ? "choseBtn selected" : "choseBtn"
                  }
                >
                  <img src={man} alt="זכר" />
                  <span>זכר</span>
                </button>

                <button
                  disabled={state.isFormLocked}
                  onClick={() =>
                    setState(prev => ({ ...prev, sex: "2", checkInputsPage: false }))
                  }
                  className={
                    state.sex === "2" ? "choseBtn selected" : "choseBtn"
                  }
                >
                  <img src={woman} alt="נקבה" />
                  <span>נקבה</span>
                </button>
              </div>
            </div>

            <div className="text-center">
              <br />
              <br />
              <Button
                className="sendBlueBtn"
                onClick={() => sendForm(sendRules())}
                size="lg"
                disabled={state.isFormLocked && !state.Mobile && !state.Email}
                variant={
                  state.isFormLocked && !state.Mobile && !state.Email ? "secondary" :
                  sendRules()
                    ? "success animate__animated animate__bounceIn"
                    : state.btnSendClass
                }
              >
                {state.isFormLocked && !state.Mobile && !state.Email ? "לא ניתן לערוך" : "שמירת שינויים"}
              </Button>
              {state.isFormLocked && !state.Mobile && !state.Email && (
                <p style={{ margin: '10px 0 0', fontSize: '13px', color: '#666' }}>
                  לא ניתן לערוך את הפרטים האישיים לאחר העלאת ואישור צילום ת.ז+ספח
                </p>
              )}
            </div>
          </div>
        )}

        {state.responseEditRegister &&
        state.responseEditRegister.error ? (
          <ModalDefaul
            variant="error"
            params={{
              title: "שגיאה",
              text: state.responseEditRegister.error,
            }}
            callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))}
          />
        ) : null}

        {state.responseEditRegister &&
        state.responseEditRegister.ok ? (
          <ModalDefaul
            params={{
              title: "תודה רבה",
              text: state.responseEditRegister.ok,
            }}
            callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))}
          />
        ) : null}
      </div>

      <style>
        {`
          .disabled input {
            background-color: transparent !important;
            color: #495057 !important;
            cursor: default !important;
            border: none !important;
            padding-right: 0 !important;
            font-weight: 500 !important;
          }
          .disabled label {
            color: #6c757d !important;
            font-size: 0.9em !important;
          }
          .disabled input:hover,
          .disabled input:focus {
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;
          }
          .choseBtn:disabled {
            background-color: transparent !important;
            color: #495057 !important;
            cursor: default !important;
            border: none !important;
            opacity: 1 !important;
          }
          .choseBtn:disabled.selected {
            background-color: transparent !important;
            font-weight: 500 !important;
          }
          .disabled .form-floating {
            border-bottom: 1px solid #dee2e6;
          }
          .info-card {
            background: white;
            border-radius: 12px;
            padding: 24px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.05);
            margin-top: 20px;
          }
          .info-section {
            border-bottom: 1px solid #eee;
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
          .info-row {
            display: flex;
            margin-bottom: 16px;
            align-items: center;
          }
          .info-label {
            color: #6c757d;
            width: 120px;
            font-size: 0.9em;
          }
          .info-value {
            color: #212529;
            font-weight: 500;
            flex: 1;
          }
          .gender-display {
            display: flex;
            align-items: center;
          }
          .section-title {
            font-size: 1.1em;
            color: #495057;
            margin-bottom: 20px;
            font-weight: 600;
          }
          .editable-section {
            background: #f8f9fa;
            padding: 20px;
            border-radius: 8px;
          }
        `}
      </style>
    </div>
  );
}
