import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Accordion } from "react-bootstrap";
import { RestUrls } from "../../Components/-Helpers-/config";
import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import loader from "../../img/preLoader.gif";
import PersonalInformation from "./userData/PersonalInformation";
import Address from "./userData/Address";
import Emergency from "./userData/Emergency";
import Studies from "./userData/Studies";
import Sherut from "./userData/Sherut";
import pInfo from "../../img/sherut-leumi/svg/userData/pInfo.svg";
import ic2 from "../../img/sherut-leumi/svg/userData/2.svg";
import ic3 from "../../img/sherut-leumi/svg/userData/3.svg";
import ic4 from "../../img/sherut-leumi/svg/userData/4.svg";
import ic5 from "../../img/sherut-leumi/svg/userData/5.svg";
import AvatarPratimMobilePic from "./closeApp/AvatarPratimMobilePic";

const UserConsoleDataIndex = ({ siteInfo }) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const fetchUserData = useCallback(async () => {
    let user;
    try {
      const rawUserData = localStorage.getItem("userData");
      // Print the raw userData from localStorage to see what's actually there
      console.log("Raw userData from localStorage:", rawUserData);
      
      if (!rawUserData) {
        console.error("userData not found in localStorage");
        return;
      }
      user = JSON.parse(rawUserData);
      
      // Print the parsed userData structure
      console.log("Parsed userData structure:", JSON.stringify(user, null, 2));
      console.log("Available keys in userData:", Object.keys(user));
      
      // Also log keys from localStorage for debugging
      const allLocalStorageKeys = Object.keys(localStorage);
      console.log("All localStorage keys:", allLocalStorageKeys);
    } catch (error) {
      console.error("Error accessing or parsing userData from localStorage:", error.message);
      return;
    }

    if (user && user.IDNO && user.SessionKey) {
      // Format 3 (current format)
      setLoading(true);
      
      try {
          const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idno: user.IDNO,
            sessionKey: user.SessionKey
          })
        });

        const data = await response.json();
        console.log('Raw data from server (Format 3):', data);
        
        if (data) {
          if (data.Result === "Success") {
            // The API is returning user data directly in the response, not in a userData property
            // Create a userData object to maintain compatibility with the rest of the code
            const formattedData = {
              userData: {}
            };
            
            // Copy all properties to userData
            Object.keys(data).forEach(key => {
              if (key !== 'Result' && key !== 'ErrorMessage') {
                formattedData.userData[key] = data[key] === null ? '' : data[key];
              }
            });
            
            setUserData(formattedData);
            try {
              localStorage.setItem("cachedUserData", JSON.stringify(formattedData));
              localStorage.setItem("cachedUserDataTimestamp", new Date().getTime().toString());
            } catch (error) {
              console.error("Error saving to localStorage:", error);
            }
          } else {
            console.error("API Error:", data.ErrorMessage || "Unknown error");
            // Also show a more helpful user-facing message if the API returns an error
            setUserData({
              userData: {},
              error: data.ErrorMessage || "שגיאה בטעינת נתוני המשתמש"
            });
          }
        } else {
          console.error("Invalid data format received: data is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    } else if (user && user.user?.id && user.token) {
      // Format 1
      setLoading(true);
      
      try {
          const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: user.user.id,
            token: user.token
          })
        });

        const data = await response.json();
        console.log('Raw data from server (Format 1):', data);
        
        if (data) {
          if (data.Result === "Success") {
            // The API is returning user data directly in the response, not in a userData property
            // Create a userData object to maintain compatibility with the rest of the code
            const formattedData = {
              userData: {}
            };
            
            // Copy all properties to userData
            Object.keys(data).forEach(key => {
              if (key !== 'Result' && key !== 'ErrorMessage') {
                formattedData.userData[key] = data[key] === null ? '' : data[key];
              }
            });
            
            setUserData(formattedData);
            try {
              localStorage.setItem("cachedUserData", JSON.stringify(formattedData));
              localStorage.setItem("cachedUserDataTimestamp", new Date().getTime().toString());
            } catch (error) {
              console.error("Error saving to localStorage:", error);
            }
          } else {
            console.error("API Error:", data.ErrorMessage || "Unknown error");
            // Also show a more helpful user-facing message if the API returns an error
            setUserData({
              userData: {},
              error: data.ErrorMessage || "שגיאה בטעינת נתוני המשתמש"
            });
          }
        } else {
          console.error("Invalid data format received: data is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    } else if (user && user.user?.IDNO && user.SessionKey) {
      // Format 2
      setLoading(true);
      
      try {
        const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idno: user.user.IDNO,
            sessionKey: user.SessionKey
          })
        });

        const data = await response.json();
        console.log('Raw data from server (Format 2):', data);
        
        if (data) {
          if (data.Result === "Success") {
            // The API is returning user data directly in the response, not in a userData property
            // Create a userData object to maintain compatibility with the rest of the code
            const formattedData = {
              userData: {}
            };
            
            // Copy all properties to userData
            Object.keys(data).forEach(key => {
              if (key !== 'Result' && key !== 'ErrorMessage') {
                formattedData.userData[key] = data[key] === null ? '' : data[key];
              }
            });
            
            setUserData(formattedData);
            try {
              localStorage.setItem("cachedUserData", JSON.stringify(formattedData));
              localStorage.setItem("cachedUserDataTimestamp", new Date().getTime().toString());
            } catch (error) {
              console.error("Error saving to localStorage:", error);
            }
          } else {
            console.error("API Error:", data.ErrorMessage || "Unknown error");
            // Also show a more helpful user-facing message if the API returns an error
            setUserData({
              userData: {},
              error: data.ErrorMessage || "שגיאה בטעינת נתוני המשתמש"
            });
          }
        } else {
          console.error("Invalid data format received: data is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      // Log specific missing properties to help debugging
      const missingFields = [];
      if (!user) missingFields.push('user object');
      else {
        if (!user.user && !user.IDNO) missingFields.push('user.user object or IDNO');
        else if (user.user && !user.user.id) missingFields.push('user.user.id');
        if (!user.token && !user.SessionKey) missingFields.push('user.token or SessionKey');
      }
      
      console.error(`Invalid user data in localStorage. Missing: ${missingFields.join(', ')}`);
    }
  }, []);

  const getUserData = useCallback(() => {
    let cachedUserData;
    let cachedTimestamp;
    try {
      cachedUserData = localStorage.getItem("cachedUserData");
      cachedTimestamp = localStorage.getItem("cachedUserDataTimestamp");
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }

    const currentTime = new Date().getTime();
    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;

    if (cachedUserData && cachedTimestamp && (currentTime - parseInt(cachedTimestamp) < fourHoursInMilliseconds)) {
      try {
        const parsedData = JSON.parse(cachedUserData);
        // Ensure userData exists and has required fields
        if (parsedData && parsedData.userData) {
          setUserData(parsedData);
          setLoading(false);
        } else {
          fetchUserData();
        }
      } catch (error) {
        console.error("Error parsing cachedUserData:", error);
        fetchUserData();
      }
    } else {
      fetchUserData();
    }
  }, [fetchUserData]);

  useEffect(() => {
    if (!userData) {
      getUserData();
    }
  }, [userData, getUserData]);

  const isMobile = siteInfo?.isMobile || false;
  const topPic = isMobile
    ? `${RestUrls.pagesPictures}/register/m_registerTop.jpg?v=2`
    : `${RestUrls.pagesPictures}/search/searchTop.jpg?v=1`;

  return (
    <div className="DataSection">
      <img className="topPic full_width" src={topPic} alt="top" />
      {isMobile && <AvatarPratimMobilePic />}
      <header>
        <h1>פרטים אישיים</h1>
        <p>תודה שבחרת להתנדב איתנו!</p>
      </header>
      <div className="AccordionCont">
        <img
          src={loader}
          alt="loader"
          className={`loader ${loading ? "active animate__animated animate__fadeIn" : ""}`}
        />
        {userData && userData.userData && (
          <>
            <AccordionItem
              eventKey="0"
              icon={pInfo}
              title="פרטים אישיים"
              component={PersonalInformation}
              userData={userData.userData}
              isMobile={isMobile}
            />
            <AccordionItem
              eventKey="1"
              icon={ic2}
              title="כתובת"
              component={Address}
              userData={userData.userData}
              isMobile={isMobile}
            />
            <AccordionItem
              eventKey="2"
              icon={ic3}
              title="פרטים לשעת חירום"
              component={Emergency}
              userData={userData.userData}
              isMobile={isMobile}
            />
            <AccordionItem
              eventKey="3"
              icon={ic4}
              title="לימודים והשכלה"
              component={Studies}
              userData={userData.userData}
              isMobile={isMobile}
              style={{ position: 'relative', zIndex: '10' }}
            />
            <AccordionItem
              eventKey="4"
              icon={ic5}
              title="שירות לאומי"
              component={Sherut}
              userData={userData.userData}
              isMobile={isMobile}
            />
          </>
        )}
      </div>
    </div>
  );
};

const AccordionItem = React.memo(({ eventKey, icon, title, component: Component, userData, isMobile, style }) => (
  <Accordion className={`accordions acc${parseInt(eventKey) + 1}`} style={style}>
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <img src={icon} alt={title} />
        <span>{title}</span>
      </Accordion.Header>
      <Accordion.Body>
        <Component isMobile={isMobile} userData={userData} />
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
));

export default React.memo(UserConsoleDataIndex);
