import React, { useEffect } from "react";
import Pages from "./Pages";
import PagesRegister from "./PagesRegister";
import PagesUserConsole from "./PagesUserConsole";
import PagesMekomotSherut from "./PagesMekomotSherut";
import axios from "axios";
import { toast } from "react-toastify";

const SiteConnector = (props) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const API_BASE_URL =
    environment === "dev"
      ? process.env.REACT_APP_API_BASE_URL_DEV
      : process.env.REACT_APP_API_BASE_URL;
  const removeUserSession = (message) => {
    console.log("message", message);
    localStorage.removeItem("userData");
    window.location.href = "/login";
    console.log("error", message);
  };
  const checkIfSessionIsActive = async () => {
    const userDataString = localStorage.getItem("userData");
    console.log("userDataString", userDataString);

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      console.log("parsed userData", userData);
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/test/checkAuthorization`,
          { sessionKey: userData.SessionKey }
        );
        // if status is 401, remove userData from localStorage
        if (response.status === 401) {
          removeUserSession("session expired");
        }
        console.log("response", response);
        return response.data;
      } catch (error) {
        // add toast error
        toast.error("שימו לב, הפריט שלך נסגר בגלל שהזמן שלך פג סיום");
        // remove userData from localStorage
        console.log("error", error);
        removeUserSession("no user data");
      }
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!currentUrl.includes("/sherutPlaces?iframe=1")) {
      console.log("Checking session...");
      checkIfSessionIsActive();
    }
  }, []);

  if (
    props.page === "register" ||
    props.page === "login" ||
    props.page === "reSendPass" ||
    props.page === "loginQueryUrl"
  ) {
    return <PagesRegister {...props} />;
  } else if (props.page === "userConsolePages") {
    return <PagesUserConsole {...props} />;
  } else if (props.page === "mekomotSherutPages") {
    return <PagesMekomotSherut {...props} />;
  } else {
    return <Pages {...props} />;
  }
};

export default SiteConnector;
