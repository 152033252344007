    import React, { Fragment, useEffect, useState } from 'react'
    import { useRef } from 'react';
    import moment from 'moment';
    import { map, size, some } from 'lodash';
    import { Form } from 'react-bootstrap'
    import { toast } from 'react-toastify';

    // Icons
    import infoRed from "../../img/icons/clockPage/infoRed.svg";
    import add from "../../img/icons/clockPage/add.svg";
    import question from "../../img/icons/clockPage/question.svg";
    import failed from "../../img/icons/clockPage/failed.svg";
    import loader from "../../img/preLoader.gif";

    // Components
    import OpenCLoseTextAreaReport from './OpenCLoseTextAreaReport';
    import { checkData4Save, getAbsenceList, sendChangesToApi } from './editClockFunctions';

    // Format date as YYYYMMDD
    function formatDateYYYYMMDD(year, month, day) {
        try {
            // Get current date for validation
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();

            // Handle 2-digit years and validation
            let numYear = parseInt(String(year), 10);
            if (numYear < 100) {
                numYear = 2000 + numYear;
            }
            
            // Extensive validation and error handling
            if (Math.abs(numYear - currentYear) > 1) {
                numYear = currentYear;
            }
            
            // Format with proper padding
            const formattedDate = `${numYear}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
            return formattedDate;
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    }

    // Add these utility functions after formatDateYYYYMMDD
    function validateTimeRange(timeF, timeT) {
        if (!timeF || !timeT) return true; // Allow empty times
        
        try {
            const [hoursF, minutesF] = timeF.split(':').map(Number);
            const [hoursT, minutesT] = timeT.split(':').map(Number);
            
            // Validate time components
            if (isNaN(hoursF) || isNaN(minutesF) || isNaN(hoursT) || isNaN(minutesT)) {
                console.error('Invalid time components:', { hoursF, minutesF, hoursT, minutesT });
                return false;
            }
            
            // Validate ranges
            if (hoursF < 0 || hoursF > 23 || minutesF < 0 || minutesF > 59 ||
                hoursT < 0 || hoursT > 23 || minutesT < 0 || minutesT > 59) {
                console.error('Time components out of range:', { hoursF, minutesF, hoursT, minutesT });
                return false;
            }
            
            // Convert to minutes for comparison
            const totalMinutesF = hoursF * 60 + minutesF;
            const totalMinutesT = hoursT * 60 + minutesT;
            
            // Check if end time is after start time
            if (totalMinutesT <= totalMinutesF) {
                return false;
            }
            
            return true;
        } catch (error) {
            console.error('Error validating time range:', error);
            return false;
        }
    }

    // Check if a given time is in the future
    function isFutureTime(timeStr, dateStr) {
        if (!timeStr || !dateStr) return false;
        try {
            const now = new Date();
            const [hours, minutes] = timeStr.split(':').map(Number);
            const [year, month, day] = dateStr.split('-').map(Number);
            
            // Create date in local time
            const checkDate = new Date(year, month - 1, day, hours, minutes);
            
            // Validate the date
            if (isNaN(checkDate.getTime())) {
                console.error('Invalid date or time in future check:', { dateStr, timeStr });
                return false;
            }
            
            // Compare with current time
            return checkDate > now;
        } catch (error) {
            console.error('Error checking future time:', error);
            return false;
        }
    }

    // Create UTC ISO string from time input
    function createUTCString(timeStr, dateStr, dateComponents) {
        if (!timeStr || !dateStr) return '';
        try {
            const year = dateComponents?.year || new Date().getFullYear();
            const month = parseInt(dateComponents?.month, 10) || (new Date().getMonth() + 1);
            const day = parseInt(dateComponents?.day, 10) || new Date().getDate();
            
            console.log('Processing date components in createUTCString:', { year, month, day, originalComponents: dateComponents });
            
            // Create date object and validate it
            const date = new Date(Date.UTC(year, month - 1, day));
            
            // Check if the date is valid and matches the input
            if (isNaN(date.getTime())) {
                console.error('Invalid date:', { year, month, day });
                toast.error('תאריך לא חוקי');
                return '';
            }
            
            // Format date string in YYYY-MM-DD format using the validated components
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            console.log('Formatted date:', formattedDate);
            
            // Check for future time
            if (isFutureTime(timeStr, formattedDate)) {
                toast.error('לא ניתן לדווח על שעה עתידית');
                return '';
            }
            
            // Normalize the time to the correct date
            const normalizedTime = normalizeTimeToDate(timeStr, formattedDate);
            if (!normalizedTime) {
                toast.error('שגיאה בעיבוד הזמן');
                return '';
            }
            
            return normalizedTime;
        } catch (error) {
            console.error('Error creating UTC string:', error, { timeStr, dateStr, dateComponents });
            toast.error('שגיאה ביצירת זמן');
            return '';
        }
    }

    function normalizeTimeToDate(timeStr, dateStr) {
        if (!timeStr || !dateStr) return '';
        try {
            const [hours, minutes] = timeStr.split(':').map(Number);
            
            // Parse the date components
            const [year, month, day] = dateStr.split('-').map(Number);
            
            // Create a date object for the specified date to check DST status
            const localDate = new Date(year, month - 1, day, hours, minutes);
            
            // Get timezone offset in minutes for the specific date (accounts for DST automatically)
            const timezoneOffsetMinutes = localDate.getTimezoneOffset();
            
            // Convert timezone offset from minutes to hours (note: getTimezoneOffset returns negative value for east of UTC)
            const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
            
            // Calculate UTC hours by subtracting the timezone offset (including DST adjustment)
            const utcHours = hours - timezoneOffsetHours;
            
            // Create the UTC date with adjusted hours
            const utcDate = new Date(Date.UTC(year, month - 1, day, utcHours, minutes));
            
            // Validate the date
            if (isNaN(utcDate.getTime())) {
                console.error('Invalid date or time components:', { dateStr, timeStr });
                return '';
            }
            
            // Log both local and UTC times for debugging
            console.log('Time conversion:', {
                input: { timeStr, dateStr },
                localTime: `${hours}:${minutes}`,
                utcTime: utcDate.toISOString(),
                timezoneInfo: {
                    offsetMinutes: timezoneOffsetMinutes,
                    offsetHours: timezoneOffsetHours,
                    isDST: timezoneOffsetHours === 3, // In Israel DST = UTC+3
                },
                components: {
                    year,
                    month,
                    day,
                    originalHours: hours,
                    utcHours,
                    minutes
                }
            });
            
            return utcDate.toISOString();
        } catch (error) {
            console.error('Error normalizing time:', error);
            return '';
        }
    }

    function checkTimeOverlap(times) {
        if (!times || times.length < 2) return true;
        
        try {
            // Filter out entries without both times
            const validTimes = times.filter(t => t.TimeF && t.TimeT);
            
            if (validTimes.length < 2) return true;
            
            // Sort times by TimeF
            const sortedTimes = [...validTimes].sort((a, b) => {
                const timeA = new Date(a.TimeF);
                const timeB = new Date(b.TimeF);
                
                if (isNaN(timeA.getTime()) || isNaN(timeB.getTime())) {
                    console.error('Invalid date in sorting:', { timeA, timeB });
                    return 0;
                }
                
                return timeA - timeB;
            });
            
            console.log('Checking time overlap for sorted times:', sortedTimes);
            
            // Check for overlaps
            for (let i = 0; i < sortedTimes.length - 1; i++) {
                const currentEnd = new Date(sortedTimes[i].TimeT);
                const nextStart = new Date(sortedTimes[i + 1].TimeF);
                
                if (isNaN(currentEnd.getTime()) || isNaN(nextStart.getTime())) {
                    console.error('Invalid date in overlap check:', { 
                        current: sortedTimes[i],
                        next: sortedTimes[i + 1]
                    });
                    continue;
                }
                
                if (currentEnd >= nextStart) {
                    console.error('Time overlap detected:', {
                        current: sortedTimes[i],
                        next: sortedTimes[i + 1]
                    });
                    toast.error('קיימת חפיפה בין זמני הדיווח');
                    return false;
                }
            }
            
            return true;
        } catch (error) {
            console.error('Error checking time overlap:', error);
            toast.error('שגיאה בבדיקת חפיפת זמנים');
            return false;
        }
    }

    const getYearMonthFromYM = (YM) => {
        if (!YM || typeof YM !== 'string') {
            console.error('Invalid YM value:', YM);
            return null;
        }

        // YM format should be YYYYMM
        if (YM.length !== 6) {
            console.error('YM string has incorrect length:', YM);
            return null;
        }

        const year = parseInt(YM.substring(0, 4), 10);
        const month = parseInt(YM.substring(4, 6), 10);

        // Basic validation
        if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
            console.error('Invalid year or month:', { year, month });
            return null;
        }

        return { year, month };
    };

    export default function EditMyDay(props) {
        const [currenPage, ] = useState('editHours')
        return (
            <div className='editMyDay'>
                {currenPage === 'editHours' && <EditHours {...props} />}
            </div>
        )
    }

    function EditHours(props) {
        const {item, monthData, closeMe, reloadData } = props
        const [showInfo, setShowInfo] = useState(false);
        const [loading, setLoading] = useState(false);
        const [absenceList, setAbsenceList] = useState(false);
        const [absence, setAbsence] = useState(item?.isAbsence ? item.isAbsence : '');
        const [showAbsence, setShowAbsence] = useState(item?.isAbsence ? true : false);
        let userJ = JSON.parse(localStorage.getItem("userData"));
        const [inOutDays, setInOutDays] = useState(item.items);

        // Log the input data for debugging
        console.log('EditHours Input Data:', {
            monthDataYear: monthData?.Year,
            itemMonth: item?.dayData?.Month,
            itemDOM: item?.dayData?.DOM,
            rawItem: item
        });

        useEffect(() => {
            if(!loading && !absenceList && showAbsence) {
                try {
                    // Get year and month from YM
                    const yearMonth = getYearMonthFromYM(item?.dayData?.YM);
                    if (!yearMonth) {
                        console.error('Failed to get year and month from YM:', item?.dayData?.YM);
                        toast.error('שגיאה בעיבוד התאריך');
                        return;
                    }

                    console.log('Got year and month from YM:', yearMonth);
                    
                    // Extract date components with proper validation
                    const dateComponents = {
                        year: yearMonth.year,
                        month: yearMonth.month,
                        day: parseInt(String(item?.dayData?.DOM), 10)
                    };

                    // Validate all components
                    if (!dateComponents.year || !dateComponents.month || !dateComponents.day) {
                        console.error('Invalid date components:', dateComponents);
                        toast.error('שגיאה בעיבוד התאריך');
                        return;
                    }

                    // Format date as YYYYMMDD
                    const date = `${dateComponents.year}${dateComponents.month.toString().padStart(2, '0')}${dateComponents.day.toString().padStart(2, '0')}`;
                    
                    const sendObj = {
                        idno: userJ.IDNO,
                        SessionKey: userJ.SessionKey,
                        Date: date,
                        source: 'construction',
                        workerId: userJ.IDNO // Using IDNO as workerId since it represents the user
                    };

                    console.log('Sending request with data:', {
                        formattedDate: date,
                        components: dateComponents,
                        requestObject: sendObj
                    });

                    getAbsenceList('/api/v2/volunteer/Available_reasons', sendObj, setLoading, (response) => {
                        if (response?.Result === 'Error') {
                            console.error('API Error:', response?.ErrorMessage);
                            toast.error(response?.ErrorMessage || 'שגיאה בשליפת נתוני העדרות');
                            return;
                        }
                        console.log('API Response:', response);
                        setAbsenceList(response);
                    });
                } catch (error) {
                    console.error('Error in absence list fetch:', error);
                    toast.error('שגיאה בשליפת נתוני העדרות');
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showAbsence]);

        const addDay = () => { 
            try {
                // Generate a unique timestamp-based ID
                const timestamp = Date.now();
                const randomSuffix = Math.floor(Math.random() * 1000);
                
                // Use a safe default if monthData.ReportID is not available
                const baseId = (monthData?.ReportID && monthData.ReportID !== 'undefined') 
                    ? monthData.ReportID 
                    : `report_${timestamp}`;
                    
                const newIndex = (inOutDays?.length || 0) + 1;
                const newId = `${baseId}_${newIndex}_${randomSuffix}`;
                
                console.log('Generated new day ID:', {
                    baseId,
                    newIndex,
                    newId,
                    existingDays: inOutDays?.length,
                    timestamp,
                    randomSuffix,
                    monthDataReportID: monthData?.ReportID
                });

                const newItem = {
                    id: newId,
                    api: {
                        LateReportMtnF: false,
                        LateReportMtnT: false,
                        LateReportRakF: false,
                        LateReportRakT: false,
                        MoreInfo: "",
                        TimeF: "",
                        TimeT: "",
                        Typ: ""
                    }
                };

                // Validate the new item before adding
                if (!newItem.id || typeof newItem.id !== 'string') {
                    throw new Error('Invalid ID generated');
                }

                toast.success('אפשרויות נוספות לדיווח בסוף הרשימה');
                setInOutDays(prevDays => [...(prevDays || []), newItem]);
            } catch (error) {
                console.error('Error adding new day:', error);
                toast.error('שגיאה בהוספת דיווח חדש');
            }
        }

        const cancelChangesHandler = () => { 
            toast.warning('השינוים לא נשמרו');
            closeMe('bottom', false);
        }

        const saveChanges = () => { 
            const checkSave = checkData4Save(allChanges);
            if(checkSave) {
                const sendObj = {
                    IDNumber: userJ.IDNO,
                    SessionKey: userJ.SessionKey,
                };
                sendChangesToApi('/api/v2/volunteer/maakavupdate', sendObj, setLoading,allChanges,closeMe,reloadData);
            }
        }

        const [allChanges, setAllChanges ] = useState(deafaultValues(monthData,item));
        const [areChanges, setAreChanges] = useState(false);

        useEffect(() => {
            const oldObj = allChanges.Dates[0];
            oldObj.Reson = absence;
            setAllChanges({...allChanges, Dates : [oldObj] } )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [absence])
        
        const changeAbsence = (value) => {
            setAreChanges(true);
            setAbsence(value);
        }

        const changeMyDay = (changes) => { 
            setAreChanges(true);
            
            setAllChanges(prevAllChanges => {
                // Make a deep copy of the state
                const newState = {
                    ...prevAllChanges,
                    Dates: [...prevAllChanges.Dates]
                };
                
                // Get current Times array or initialize if empty
                const currentTimes = newState.Dates[0].Times || [];
                
                // Find if the item already exists
                const itemIndex = currentTimes.findIndex(item => item.id === changes.id);
                
                // Create new Times array
                let newTimes;
                if (itemIndex >= 0) {
                    // Update existing item
                    newTimes = currentTimes.map(item => 
                        item.id === changes.id ? {...changes} : item
                    );
                } else {
                    // Add new item
                    newTimes = [...currentTimes, changes];
                }
                
                // Update Times array in state
                newState.Dates[0] = {
                    ...newState.Dates[0],
                    Times: newTimes
                };
                
                console.log('Updated state:', newState);
                return newState;
            });
        }

        const cancelAbs = () => { 
            setAreChanges(true);
            setAbsence('');
            setShowAbsence(false);
        }

        const [noEditQuota, setNoEditQuota] = useState(false);

        useEffect(() => {
        if (
                monthData?.LateReportUsed !== "null" && 
                (monthData?.LateReportUsed >= monthData?.LateReportQuota) && 
                !userJ?.AttendanceReportOnline  ) {
                setNoEditQuota(true);
                toast.error('מכסת הדיווחים באיחור נוצלה במלואה. נא לפנות לרכזת להמשך טיפול');
            } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return(
            <Fragment>
                <img  src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

                <div className='headerOpenDown'>
                    <h2>דיווח ידני</h2>
                    <p>{item.title}</p>
                </div>

                { !userJ?.AttendanceReportOnline && monthData?.LateReportUsed !== "null" &&
                <div className={`reportEditCount ${noEditQuota ? 'animate__animated animate__flash animate__delay-2s' : ''}`}>
                    <img onClick={ ()=>setShowInfo(!showInfo) } src={ infoRed } alt='infoRed' />
                    <strong style={ noEditQuota ? {color: 'red'} : {} }>
                        {monthData?.LateReportQuota - monthData?.LateReportUsed < 0 ? 
                            `המכסה נגמרה - דווחו ${monthData?.LateReportUsed} דיווחים מתוך ${monthData?.LateReportQuota} האפשריים` :
                            `דווחו ${monthData?.LateReportUsed} דיווחים, נשארו עוד ${monthData?.LateReportQuota - monthData?.LateReportUsed} מתוך ${monthData?.LateReportQuota} דיווחים באיחור`
                        }
                    </strong>
                </div> }

                <div className='buttonsEditReportDay'>
                    <button onClick={ addDay }>
                        <img src={add} alt='add' />
                        <span>הוספת דיווח</span>
                    </button>

                    <button onClick={()=> setShowAbsence(!showAbsence)}>
                        <img src={question} alt='question' />
                        <span>סיבת העדרות</span>
                    </button>
                </div>

                <div className='daysitemsCont'>
                    {showInfo ? <ShowInfo monthData = {monthData} setShowInfo = {setShowInfo} /> :
                    <Fragment>
                        {(showAbsence && absenceList) && <div className='isAbsence animate__animated animate__fadeIn'>
                            <h3>סיבת העדרות: </h3>
                            <div className='selectCont'>
                                <Form.Control 
                                    as="select"
                                    className={"chooseReportSelect"}
                                    onChange={(e) => changeAbsence(e.target.value) } 
                                    value={absence}
                                >
                                    <option value="">סיבת העדרות</option>
                                    {map(absenceList, item => {
                                        return(<option value={item} key= {item} >{item}</option>)
                                    })}
                                </Form.Control>
                            </div>

                            { absence && <button className='cancelAbs' onClick={cancelAbs} >
                                <img src={failed} alt='ביטול סיבת העדרות' />
                                <span>לחצו לביטול סיבת העדרות</span>
                            </button> }
                        </div> }

                        {map(inOutDays, dayItem => {
                            return(<DayItem 
                                key={ dayItem.id } 
                                dayItem={ dayItem } 
                                monthData={monthData}
                                item={item}
                                changeMyDay={(changes) => changeMyDay(changes)} 
                            />)
                        })}
                    </Fragment> }
                </div>
                
                {!noEditQuota ? <div className='saveChangesBtns'>
                    {areChanges && <button className='saveBtn' onClick={ saveChanges }>שמירת שינויים</button>}
                    <button className='cancelBtn' onClick={ cancelChangesHandler }>
                        { !areChanges ? <span>סגירת חלון</span> : <span>ביטול שינויים</span> }
                    </button>
                </div> : <div className='saveChangesBtns'>
                    <button className='cancelBtn' onClick={ cancelChangesHandler }>סגירת חלון</button>
                </div> }
            </Fragment>        
        )
    }

    function DayItem(props) {
        const {dayItem, changeMyDay, monthData, item} = props;
        const refIn = useRef(null);
        const refOut = useRef(null);

        // Get year and month from YM
        const getYearMonthFromYM = (ym) => {
            if (!ym) return null;
            const yearStr = ym.substring(0, 4);
            const monthStr = ym.substring(4, 6);
            const year = parseInt(yearStr, 10);
            const month = parseInt(monthStr, 10);
            return isNaN(year) || isNaN(month) ? null : { year, month };
        };

        // Extract time from UTC string (HH:mm format)
        const convertUTCToDisplay = (utcTimeStr) => {
            if (!utcTimeStr) return '';
            try {
                // Parse the UTC date string
                const date = new Date(utcTimeStr);
                
                if (isNaN(date.getTime())) {
                    console.error('Invalid UTC time string:', utcTimeStr);
                    return '';
                }
                
                // Use toLocaleTimeString to get hours and minutes in local timezone
                // This automatically handles DST
                const localTimeStr = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
                
                console.log('Converting UTC to local display:', {
                    utc: utcTimeStr,
                    local: localTimeStr,
                    rawDate: date.toString()
                });
                
                return localTimeStr;
            } catch (error) {
                console.error('Error extracting time:', error);
                return '';
            }
        };

        // Initialize with API values in local format for display
        const [dayIn, setDayIn] = useState(() => {
            if (dayItem?.api?.TimeF) {
                return convertUTCToDisplay(dayItem.api.TimeF);
            }
            return '';
        });
        
        const [dayOut, setDayOut] = useState(() => {
            if (dayItem?.api?.TimeT) {
                return convertUTCToDisplay(dayItem.api.TimeT);
            }
            return '';
        });

        const [selectedReportType, setSelectedReportType] = useState(dayItem?.api?.Typ || false);
        const [textAreaReport, setTextAreaReport] = useState(dayItem?.api?.MoreInfo || false);

        //send changes
        useEffect(() => {
            // Skip if no times are set
            if (!dayIn && !dayOut) {
                console.log('No time inputs to process, skipping update');
                return;
            }
            
            // Get year and month from YM
            const yearMonth = getYearMonthFromYM(item?.dayData?.YM);
            if (!yearMonth) {
                console.error('Failed to get year and month from YM:', item?.dayData?.YM);
                toast.error('שגיאה בעיבוד התאריך');
                return;
            }

            console.log('Got year and month from YM:', yearMonth);
            
            // Extract date components with proper validation
            const dateComponents = {
                year: yearMonth.year,
                month: yearMonth.month,
                day: parseInt(String(item?.dayData?.DOM), 10)
            };

            // Validate all components
            if (!dateComponents.year || !dateComponents.month || !dateComponents.day) {
                console.error('Invalid date components:', dateComponents);
                toast.error('שגיאה בעיבוד התאריך');
                return;
            }

            console.log('Date components for processing:', dateComponents);
            
            // Format date for time normalization (YYYY-MM-DD)
            const date = `${dateComponents.year}-${dateComponents.month.toString().padStart(2, '0')}-${dateComponents.day.toString().padStart(2, '0')}`;
            
            console.log('Processing times with date:', {
                normalizedDate: date,
                dayIn,
                dayOut,
                dateComponents
            });
            
            const timeF = dayIn ? createUTCString(dayIn, date, dateComponents) : '';
            const timeT = dayOut ? createUTCString(dayOut, date, dateComponents) : '';
            
            if ((dayIn && !timeF) || (dayOut && !timeT)) {
                console.error('Failed to create UTC strings:', { dayIn, dayOut, timeF, timeT });
                return;
            }
            
            const newItemObj = {
                id: dayItem.id,
                TimeF: timeF,
                TimeT: timeT,
                Typ: selectedReportType || '',
                MoreInfo: textAreaReport || '',
                LateReportMtnF: dayItem.api.LateReportMtnF || false,
                LateReportRakF: dayItem.api.LateReportRakF || false,
                LateReportMtnT: dayItem.api.LateReportMtnT || false,
                LateReportRakT: dayItem.api.LateReportRakT || false,
            };
            
            console.log('Final item object:', newItemObj);
            
            changeMyDay(newItemObj);
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dayIn, dayOut, selectedReportType, textAreaReport]);
        
        const setInputFocus = (inOut) => { 
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const currentLocalTime = `${hours}:${minutes}`;

            console.log('Setting current time:', {
                localTime: currentLocalTime,
                fullLocal: now.toLocaleString()
            });

            if(inOut === 'in') {
                setDayIn(currentLocalTime);
                setTimeout(() => { refIn.current.focus(); }, 100);
            } else if(inOut === 'out') {
                setDayOut(currentLocalTime);
                setTimeout(() => { refOut.current.focus(); }, 100);
            }
        }

        return(
            <div className='editHoursItem' >
                <OpenCLoseTextAreaReport 
                    isEdit={true} dayItem={dayItem}
                    selectedReportType={selectedReportType} setSelectedReportType={setSelectedReportType}
                    textAreaReport={textAreaReport} setTextAreaReport={setTextAreaReport}
                />

                <ul className='inOutItems'>
                    <li className='in'>
                        <span className={dayIn ? '' : 'error'} >שעת כניסה</span>
                        
                        { dayIn ? <input className='changeHour' type='time' value={dayIn} ref={refIn} onChange={(e) => setDayIn(e.target.value)} /> : 
                            <p onClick={()=>  setInputFocus('in')} className='error'>--:--</p>
                        }
                    </li>
                    <li className='separate'>-</li>
                    <li className='out'>
                        <span className={dayOut ? '' : 'error'} >שעת יציאה</span> 

                        { dayOut ? <input className='changeHour' type='time' ref={refOut} value={dayOut} onChange={(e) => setDayOut(e.target.value)} /> : 
                            <p onClick={()=>  setInputFocus('out')} className='error'>--:--</p>
                        }
                    </li>
                </ul>
            </div>
        );
    }

    function ShowInfo(props) {
        const { monthData, setShowInfo } = props
        return(
            <div className='ShowInfo animate__animated animate__fadeIn'>
                <div className='contInfo'>
                    <h2 style={{textAlign: 'center'}}>דיווחים באיחור</h2>
                    <p>הרשות לשירות לאומי-אזרחי מחייבת כל מתנדב/ת לדווח נוכחות בזמן אמת.</p>
                    <p> דיווח שעה שחורגת ב-30 דקות ומעלה ממועד הכניסה/יציאה. בפועל, מוגדר כדיווח באיחור ולא יתקבל.</p>
                    <p>בנוסף, לא יתקבל דיווח על שעה עתידית.</p>
                    <p>סיבת היעדרות ניתן לדווח לכל אורך היום עצמו, מלבד חופש. מחלה, אותן ניתן לדווח בכל החודש.</p>
                    <p>{monthData?.LateReportQuota} פעמים, בכל חודש האגודה להתנדבות מאפשרת לדווח באיחור.</p>
                    <p>כל שעת כניסה/יציאה או סיבת היעדרות, נספרת כדיווח אחד.</p>
                    <p> במידה וניצלת מלוא המכסה, הדיווח באיחור לא יתקבל ויש לפנות לכז/ת להמשך טיפול.</p>
                </div>
                <button onClick={ ()=>setShowInfo(false) }>
                    <span>אישור</span>
                </button>
            </div>
        )
    }

    function deafaultValues(monthData,item) {
        const returnObj = {
            ...monthData,
            Dates: [
                {
                    'Times' : [],
                    ...item.dayData
                },
            ],
        }
        return returnObj
    }
