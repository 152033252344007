import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import loader from "../../../img/preLoader.gif"
import CustomFloatInput from '../../../Components/-Helpers-/forms/CustomFloatInput'
import ModalDefaul from '../../../Components/-Helpers-/ModalDefaul'
import { toast } from 'react-toastify'

export default function Emergency({ userData }) {
    const user = JSON.parse(localStorage.getItem('userData'));
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
    const [state, setState] = useState({
        idno: user.IDNO,
        SessionKey: user.SessionKey,
        relativename: '',
        relativephone: '', 
        relativerelation: '',
        loading: true,
        btnSendClass: 'success',
        responseEditRegister: false,
        checkInputs: false,
        checkInputsPage: false
    });

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IDNO: user.IDNO,
                    SessionKey: user.SessionKey
                })
            });

            const data = await response.json();
            console.log('Raw data from server:', data);
            
            if (data) {
                setState(prev => ({
                    ...prev,
                    relativename: data.relativename || '',
                    relativephone: data.relativephone || '',
                    relativerelation: data.relativerelation || '',
                    loading: false
                }));
            }
        } catch (error) {
            console.error('Error fetching emergency contact data:', error);
            toast.error('שגיאה בטעינת נתוני איש קשר לשעת חירום');
            setState(prev => ({ ...prev, loading: false }));
        }
    };

    const sendForm = (sendRules) => {
        setState(prev => ({
            ...prev,
            checkInputs: true,
            checkInputsPage: true
        }));

        if(sendRules) {
            setState(prev => ({ ...prev, loading: true }));

            const sendObj = {
                sessionKey: user.SessionKey,
                requestID: "string",
                idno: user.IDNO,
                deviceID: "string",
            };
            // if environment is dev, then don't send the category
            if (environment !== "dev") sendObj.category = "3";

            if (state.relativename) sendObj.relativename = state.relativename;
            if (state.relativephone) sendObj.relativephone = state.relativephone;
            if (state.relativerelation) sendObj.relativerelation = state.relativerelation;

            sendtoAdmin(sendObj);
        } else {
            toast.error('נא למלא שדות חובה');
            setState(prev => ({ ...prev, btnSendClass: 'danger' }));
        }
    }

    const sendtoAdmin = async (objectToSend) => {
        setState(prev => ({ ...prev, loading: true }));
        try {
            const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/set", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(objectToSend)
            });

            if (response.status === 200) {
                setState(prev => ({ 
                    ...prev,
                    responseEditRegister: { ok: "הפרטים עודכנו בהצלחה" }
                }));
                await fetchUserData();
            } else {
                const getData = await response.json();
                setState(prev => ({ 
                    ...prev,
                    responseEditRegister: { error: getData.ErrorMessage || 'שגיאה בעדכון הנתונים' },
                    loading: false 
                }));
            }
        } catch (error) {
            console.error('Error updating emergency contact:', error);
            setState(prev => ({ 
                ...prev, 
                responseEditRegister: { error: 'שגיאה בעדכון איש קשר לשעת חירום' },
                loading: false 
            }));
        }
    }
    
    const updateValue = (newValue) => {
        setState(prev => ({ ...prev, ...newValue }));
    }

    const sendRules = 
        state.relativename &&
        state.relativephone &&
        state.relativerelation;

    return (
        <div>
            <div className="firstRegister animate__animated animate__fadeIn">
                <img 
                    src={loader} 
                    alt="loader" 
                    className={!state.loading ? "loader" : "loader active animate__animated animate__fadeIn"} 
                />

                <div className="inputs">
                    <div className="rows2 clear line">
                        <CustomFloatInput 
                            name='relativename' 
                            updateValue={updateValue} 
                            value={state.relativename} 
                            placeholder="שם איש קשר לשעת חירום" 
                            cssClass='' 
                            validationRules={{ required: true }} 
                            typeInput='text' 
                            checkInputs={state.checkInputs} 
                            checked={() => setState(prev => ({ ...prev, checkInputs: false }))} 
                        />
                        <CustomFloatInput 
                            name='relativephone' 
                            updateValue={updateValue} 
                            value={state.relativephone} 
                            placeholder="טלפון נייד" 
                            cssClass='' 
                            validationRules={{ required: true, phone: true }} 
                            typeInput='text' 
                            checkInputs={state.checkInputs} 
                            checked={() => setState(prev => ({ ...prev, checkInputs: false }))} 
                        />
                    </div>

                    <div className="clear line">
                        <CustomFloatInput 
                            name='relativerelation' 
                            updateValue={updateValue} 
                            value={state.relativerelation} 
                            placeholder="סוג קרבה" 
                            cssClass='' 
                            validationRules={{ required: true }} 
                            typeInput='text' 
                            checkInputs={state.checkInputs} 
                            checked={() => setState(prev => ({ ...prev, checkInputs: false }))} 
                        />
                    </div>

                    <div className="text-center">
                        <br/>
                        <Button 
                            className="sendBlueBtn" 
                            onClick={() => sendForm(sendRules)} 
                            size="lg" 
                            variant={sendRules ? 'success animate__animated animate__bounceIn' : state.btnSendClass}
                        >
                            שמירת שינויים
                        </Button>
                    </div>
                </div>

                {state.responseEditRegister && state.responseEditRegister.error && 
                    <ModalDefaul 
                        variant="error" 
                        params={{title: 'שגיאה', text: state.responseEditRegister.error}} 
                        callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))}
                    />
                }

                {state.responseEditRegister && state.responseEditRegister.ok && 
                    <ModalDefaul 
                        params={{title: 'תודה רבה', text: state.responseEditRegister.ok}} 
                        callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))}
                    />
                }
            </div>
        </div>
    )
}
