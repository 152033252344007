import React from 'react';
import { RestUrls } from "../../../Components/-Helpers-/config";
import ShowDataInRow from "./ShowDataInRow";
import ShowMoreData from "./ShowMoreData";
import ModalDeleteSayeret from "./ModalDeleteSayeret";
import { Button } from "react-bootstrap";
import lev from "../../../img/sherut-leumi/svg/search/lev.svg";
import lev2 from "../../../img/sherut-leumi/svg/search/lev2.svg";
import loader from "../../../img/preLoader.gif";
import ModalDefaul from "../../../Components/-Helpers-/ModalDefaul";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
const environment = process.env.REACT_APP_ENVIRONMENT;
const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
const mobileStyles = `
  .mobileButtonsWrapper {
    margin-top: 12px;
    margin-bottom: 15px;
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .mobileButtonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .actionButton {
    flex: 1;
  }

  .actionButton .sendBlueBtn {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
  }

  .actionButton .sendBlueBtn.actived {
    background: #dc3545;
    border-color: #dc3545;
  }

  .actionButton .sendBlueBtn.actived:hover {
    background: #bb2d3b;
    border-color: #b02a37;
  }

  .actionButton .sendBlueBtn img {
    margin-left: 5px;
  }

  .mobileShowMoreBtn {
    flex: 1;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 6px 12px;
    border-radius: 4px;
    color: #212529;
    font-size: 14px;
    transition: all 0.2s ease;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .mobileShowMoreBtn.active {
    background: #e9ecef;
    border-color: #dee2e6;
  }

  .mobileShowMoreBtn:hover {
    background: #e9ecef;
    border-color: #dee2e6;
  }

  @media (max-width: 480px) {
    .mobileButtonsContainer {
      flex-direction: column;
      gap: 8px;
    }

    .actionButton,
    .mobileShowMoreBtn {
      width: 100%;
    }
  }
`;

const SearchResultRow = ({ item, activeSayeretId, changeMenuright, isMyPage, StartDateRegistrationSayerot }) => {
  const user = React.useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem("userData")) || {};
    } catch (err) {
      console.error("Error parsing userData from localStorage:", err);
      return {};
    }
  }, []);
  
  const sayeretId = React.useMemo(() => {
    return item?.ID || item?.SayertId;
  }, [item]);
  
  const [state, setState] = React.useState({
    showMoreData: activeSayeretId === sayeretId,
    isActive: isMyPage ? true : Boolean(user?.Sayarot?.includes(sayeretId)),
    deleteSayeret: false,
    errorModal: false,
    loading: false,
    apiVerified: true,
    remainingSpots: item?.LimMtn,
    randomImage: `/randomPic/${Math.floor(Math.random() * 20) + 1}_converted.png`,
    currentTime: new Date()
  });

  // Add timer to update current time
  React.useEffect(() => {
    // Only set up timer if we have a registration start date
    if (!StartDateRegistrationSayerot) return;

    const timer = setInterval(() => {
      setState(prev => ({ ...prev, currentTime: new Date() }));
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, [StartDateRegistrationSayerot]);

  const isRegistrationOpen = React.useMemo(() => {
    if (!item?.RegStart) return true;
    
    const year = parseInt(item.RegStart.substring(0, 4));
    const month = parseInt(item.RegStart.substring(4, 6)) - 1; // JS months are 0-based
    const day = parseInt(item.RegStart.substring(6, 8));
    
    const regStartDate = new Date(year, month, day);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return today >= regStartDate;
  }, [item?.RegStart]);

  const formatRegStartDate = React.useMemo(() => {
    if (!item?.RegStart) return '';
    
    const year = item.RegStart.substring(0, 4);
    const month = item.RegStart.substring(4, 6);
    const day = item.RegStart.substring(6, 8);
    
    return `${day}/${month}/${year}`;
  }, [item?.RegStart]);

  const isRegistrationTimeReached = React.useMemo(() => {
    console.log('Checking registration time with:', {
      StartDateRegistrationSayerot,
      type: typeof StartDateRegistrationSayerot,
      componentId: item?.ID || item?.SayertId,
      currentTime: state.currentTime
    });
    if (!StartDateRegistrationSayerot) {
      console.log('StartDateRegistrationSayerot is empty, defaulting to true');
      return true;
    }
    try {
      const registrationDateTime = new Date(StartDateRegistrationSayerot);
      
      // Check if the date is valid
      if (isNaN(registrationDateTime.getTime())) {
        console.error('Invalid registration date format:', StartDateRegistrationSayerot);
        return true;
      }
      // Convert both dates to Israel timezone for comparison
      const israelTimeOptions = { timeZone: 'Asia/Jerusalem' };
      const registrationDateTimeIL = new Date(registrationDateTime.toLocaleString('en-US', israelTimeOptions));
      const nowIL = new Date(state.currentTime.toLocaleString('en-US', israelTimeOptions));
      
      console.log('Registration Time Check:', {
        componentId: item?.ID || item?.SayertId,
        registrationDateTime: registrationDateTimeIL.toLocaleString('he-IL'),
        currentTime: nowIL.toLocaleString('he-IL'),
        StartDateRegistrationSayerot,
        isTimeReached: nowIL >= registrationDateTimeIL,
        rawRegistrationTime: registrationDateTimeIL.getTime(),
        rawCurrentTime: nowIL.getTime(),
        timeDifference: (registrationDateTimeIL.getTime() - nowIL.getTime()) / 1000 / 60, // difference in minutes
      });
      
      return nowIL >= registrationDateTimeIL;
    } catch (error) {
      console.error('Error processing registration date:', error);
      return true;
    }
  }, [StartDateRegistrationSayerot, item, state.currentTime]);

  const formatRegistrationTime = React.useMemo(() => {
    if (!StartDateRegistrationSayerot) {
      console.log('StartDateRegistrationSayerot is empty for formatting');
      return '';
    }
    
    try {
      const registrationDateTime = new Date(StartDateRegistrationSayerot);
      
      // Check if the date is valid
      if (isNaN(registrationDateTime.getTime())) {
        console.error('Invalid registration date format for formatting:', StartDateRegistrationSayerot);
        return '';
      }
      
      console.log('Formatting Registration Time:', {
        input: StartDateRegistrationSayerot,
        parsed: registrationDateTime.toLocaleString('he-IL'),
        formatted: registrationDateTime.toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' })
      });
      
      return registrationDateTime.toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error('Error formatting registration time:', error);
      return '';
    }
  }, [StartDateRegistrationSayerot]);

  const handleSend = () => {
    if (state.loading || !state.apiVerified) return;

    if (!sayeretId) {
      setState((prev) => ({
        ...prev,
        errorModal: { error: "מזהה סיירת חסר, אנא רענן את העמוד ונסה שוב" },
      }));
      return;
    }

    if (isMyPage || state.isActive) {
      setState((prev) => ({ ...prev, deleteSayeret: true }));
    } else {
      handleChangeUserSayarot();
    }
  };

  const handleRemoveFromSayeret = async () => {
    if (!user?.SessionKey || !user?.IDNO || !sayeretId) {
      setState((prev) => ({
        ...prev,
        errorModal: { error: "חסרים פרטי משתמש, אנא התחבר מחדש" },
      }));
      return;
    }

    const payload = {
      sessionKey: user.SessionKey,
      idno: user.IDNO,
      syrID: sayeretId,
    };

    try {
      setState((prev) => ({ ...prev, loading: true }));
      const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/syr/remove", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error("שגיאה בהסרת הרישום לסיירת");

      const updatedSayarot = (user.Sayarot || []).filter((id) => id !== sayeretId);
      localStorage.setItem("userData", JSON.stringify({ ...user, Sayarot: updatedSayarot }));

      // Update remaining spots after removal
      const currentSpots = parseInt(state.remainingSpots);
      if (!isNaN(currentSpots) && currentSpots !== 901 && currentSpots !== 903) {
        setState((prev) => ({ 
          ...prev, 
          loading: false, 
          isActive: false, 
          deleteSayeret: false,
          remainingSpots: (currentSpots + 1).toString()
        }));
      } else {
        setState((prev) => ({ 
          ...prev, 
          loading: false, 
          isActive: false, 
          deleteSayeret: false
        }));
      }

      changeMenuright({ changeSayeret: Date.now(), count: updatedSayarot.length, id: sayeretId, isActive: false });
    } catch (err) {
      setState((prev) => ({
        ...prev,
        loading: false,
        errorModal: { error: err.message || "אירעה שגיאה בהסרת הרישום" },
      }));
    }
  };

  const handleChangeUserSayarot = async () => {
    if (!user?.SessionKey || !user?.IDNO || !sayeretId) {
      setState((prev) => ({
        ...prev,
        errorModal: { error: "חסרים פרטי משתמש, אנא התחבר מחדש" },
      }));
      return;
    }

    const payload = {
      sessionKey: user.SessionKey,
      idno: user.IDNO,
      syrID: sayeretId,
    };

    try {
      setState((prev) => ({ ...prev, loading: true }));
      const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/syr/add", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (!response.ok || result.Result === "Error") {
        // Check if the error is due to registration limit being reached
        if (result.ErrorMessage && result.ErrorMessage.includes("registration limit for this sayert has been reached")) {
          setState(prev => ({
            ...prev,
            loading: false,
            remainingSpots: "0",
            errorModal: { error: "נגמרו המקומות לסיירת המבוקשת" }
          }));
          return;
        }
        throw new Error(result.ErrorMessage || "שגיאה ברישום לסיירת");
      }

      const updatedSayarot = [...(user.Sayarot || [])];
      if (!updatedSayarot.includes(sayeretId)) {
        updatedSayarot.push(sayeretId);
      }
      
      localStorage.setItem("userData", JSON.stringify({ ...user, Sayarot: updatedSayarot }));

      // Update remaining spots after registration
      const currentSpots = parseInt(state.remainingSpots);
      if (!isNaN(currentSpots) && currentSpots !== 901 && currentSpots !== 903) {
        setState((prev) => ({ 
          ...prev, 
          loading: false, 
          isActive: true,
          remainingSpots: (currentSpots - 1).toString()
        }));
      } else {
        setState((prev) => ({ 
          ...prev, 
          loading: false, 
          isActive: true
        }));
      }

      changeMenuright({ changeSayeret: Date.now(), count: updatedSayarot.length, id: sayeretId, isActive: true });
    } catch (err) {
      setState((prev) => ({
        ...prev,
        loading: false,
        errorModal: { error: err.message || "אירעה שגיאה ברישום לסיירת" },
      }));
    }
  };

  const renderButton = () => {
    // Check if this is a phone coordination sayeret
    if (item?.LimMtn === "902") {
      return (
        <Button
          className="sendBlueBtn disabled"
          disabled={true}
          size="sm"
        >
          <img src={lev} alt="" />
          <span>תיאום טלפוני</span>
        </Button>
      );
    }

    // Check if there are no remaining spots and user is not already registered
    const remainingSpots = parseInt(state.remainingSpots);
    if (!state.isActive && remainingSpots < 1) {
      return (
        <Button
          className="sendBlueBtn disabled"
          disabled={true}
          size="sm"
        >
          <img src={lev} alt="" />
          <span>אזלו המקומות</span>
        </Button>
      );
    }

    if (!isRegistrationOpen) {
      return (
        <Button
          className="sendBlueBtn disabled"
          disabled={true}
          size="sm"
        >
          <img src={lev} alt="" />
          <span>ההרשמה תפתח ב-{formatRegStartDate}</span>
        </Button>
      );
    }

    if (!isRegistrationTimeReached) {
      return (
        <Button
          className="sendBlueBtn disabled"
          disabled={true}
          size="sm"
        >
          <img src={lev} alt="" />
          <span>ההרשמה תפתח בשעה {formatRegistrationTime}</span>
        </Button>
      );
    }

    return (
      <Button
        className={state.isActive ? "sendBlueBtn actived" : "sendBlueBtn"}
        onClick={handleSend}
        disabled={state.loading || !state.apiVerified}
        size="sm"
      >
        <img src={state.isActive ? lev2 : lev} alt="" />
        <span>{state.isActive ? "הסר הרשמה" : "הרשמה"}</span>
      </Button>
    );
  };

  return (
    <div className="searchRow GreyPanel">
      <style>{mobileStyles}</style>
      {state.loading && (
        <img
          src={loader}
          alt="loader"
          className="loader active animate__animated animate__fadeIn"
        />
      )}
      <div className="tableSearchRow">
        <div className="coltableSearch picCol">
          <figure style={{ backgroundImage: `url('${state.randomImage}')` }} />
        </div>

        <div className="coltableSearch dataCol">
          <ShowDataInRow item={{...item, LimMtn: state.remainingSpots}} />
          {isMobile && (
            <div className="mobileButtonsWrapper">
              <div className="mobileButtonsContainer">
                <div className="actionButton">
                  {renderButton()}
                </div>
                <button 
                  className={`mobileShowMoreBtn ${state.showMoreData ? 'active' : ''}`}
                  onClick={() => setState((prev) => ({ ...prev, showMoreData: !prev.showMoreData }))}
                >
                  {state.showMoreData ? "סגירה" : "לפרטים נוספים"}
                </button>
              </div>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className="coltableSearch BtnsCol">
            <div className="buttonsCont">
              {renderButton()}
              <button className="showMoreData" onClick={() => setState((prev) => ({ ...prev, showMoreData: !prev.showMoreData }))}>
                {state.showMoreData ? "סגירה" : "לפרטים נוספים"}
              </button>
            </div>
          </div>
        )}
      </div>

      {state.showMoreData && <ShowMoreData item={{...item, LimMtn: state.remainingSpots}} />}

      {state.deleteSayeret && (
        <ModalDeleteSayeret
          item={item}
          modalShow={(value) => setState((prev) => ({ ...prev, deleteSayeret: value }))}
          callBack={handleRemoveFromSayeret}
        />
      )}

      {state.errorModal && (
        <ModalDefaul
          variant="error"
          params={{
            title: "שגיאה",
            text: state.errorModal.error,
          }}
          callBack={() => setState((prev) => ({ ...prev, errorModal: false }))}
        />
      )}
    </div>
  );
};

export default SearchResultRow;
