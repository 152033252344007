import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import loader from "../../../img/preLoader.gif"
import CustomFloatInput from '../../../Components/-Helpers-/forms/CustomFloatInput'
import ModalDefaul from '../../../Components/-Helpers-/ModalDefaul'
import { toast } from 'react-toastify'
    
export default function Address({ userData }) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
    const user = JSON.parse(localStorage.getItem('userData'));
    const [state, setState] = useState({
        idno: user.IDNO,
        SessionKey: user.SessionKey,
        city: '',
        street: '',
        appartment: '',
        zip: '',
        mwnumber: '',
        loading: true,
        btnSendClass: 'success',
        responseEditRegister: false,
        checkInputs: false,
        checkInputsPage: false,
        isFormLocked: false
    });

    useEffect(() => {
        fetchUserData();
        checkIdDocumentStatus();
    }, []);

    const checkIdDocumentStatus = async () => {
        try {
            const response = await fetch(API_BASE_URL + "/api/v2/volunteer/forms/list", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IDNumber: user.IDNO,
                    SessionKey: user.SessionKey
                })
            });

            const data = await response.json();
            console.log('Form status response:', data.Forms);
            
            if (data?.Forms) {
                const idCardDocument = data.Forms.find(form => form.FormID === '1');
                console.log('ID Card document:', idCardDocument);
                
                if (idCardDocument && idCardDocument.Status === "Exist") {
                    console.log('Setting form to locked state - ID document is approved');
                    setState(prev => ({ 
                        ...prev, 
                        isFormLocked: true
                    }));
                    toast.info("תעודת הזהות כבר אושרה, לא ניתן לערוך את פרטי הכתובת");
                }
            }
        } catch (error) {
            console.error('Error checking ID document status:', error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/get", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IDNO: user.IDNO,
                    SessionKey: user.SessionKey
                })
            });

            const data = await response.json();
            console.log('Raw address data from server:', data);
            
            if (data) {
                setState(prev => ({
                    ...prev,
                    city: data.city || '',
                    street: data.street || '',
                    appartment: data.appartment || '',
                    zip: data.zip || '',
                    mwnumber: data.mwnumber || '',
                    loading: false
                }));
            }
        } catch (error) {
            console.error('Error fetching address data:', error);
            toast.error('שגיאה בטעינת נתוני הכתובת');
            setState(prev => ({ ...prev, loading: false }));
        }   
    };

    const sendForm = (sendRules) => {
        setState(prev => ({
            ...prev,
            checkInputs: true,
            checkInputsPage: true
        }));

        if(sendRules) {
            setState(prev => ({ ...prev, loading: true }));

            const sendObj = {
                sessionKey: user.SessionKey,
                requestID: "string",
                idno: user.IDNO,
                deviceID: "string",
            };
            // if environment is dev, then don't send the category
            if (environment !== "dev") sendObj.category = "3";

            if (state.city) sendObj.city = state.city;
            if (state.street) sendObj.street = state.street;
            if (state.appartment) sendObj.appartment = state.appartment;
            if (state.zip) sendObj.zip = state.zip;
            if (state.mwnumber) sendObj.mwnumber = state.mwnumber;

            sendtoAdmin(sendObj);
        } else {
            toast.error('נא למלא שדות חובה');
            setState(prev => ({ ...prev, btnSendClass: 'danger' }));
        }
    }

    const sendtoAdmin = async (objectToSend) => {
        setState(prev => ({ ...prev, loading: true }));
        try {
            const response = await fetch(API_BASE_URL + "/api/v2/Volunteer/info/set", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(objectToSend)
            });

            if (response.status === 200) {
                setState(prev => ({ 
                    ...prev,
                    responseEditRegister: { ok: "הכתובת עודכנה בהצלחה" },
                    loading: false
                }));
                await fetchUserData();
            } else {
                const getData = await response.json();
                setState(prev => ({ 
                    ...prev, 
                    responseEditRegister: { error: getData.ErrorMessage || 'שגיאה בעדכון הכתובת' },
                    loading: false 
                }));
            }
        } catch (error) {
            console.error('Error updating address:', error);
            setState(prev => ({ 
                ...prev, 
                responseEditRegister: { error: 'שגיאה בעדכון הכתובת' },
                loading: false 
            }));
        }
    }
    
    const updateValue = (newValue) => {
        const fieldName = Object.keys(newValue)[0];
        console.log('Attempting to update field:', fieldName, 'Form locked:', state.isFormLocked);
        
        // If form is locked, prevent the update
        if (state.isFormLocked) {
            console.log('Update prevented - form is locked');
            return;
        }
        
        console.log('Update allowed');
        setState(prev => ({ ...prev, ...newValue }));
    }

    const sendRules = 
        state.city &&
        state.street &&
        state.appartment;

    return (
        <div>
            <div className="firstRegister animate__animated animate__fadeIn">
                <img 
                    src={loader} 
                    alt="loader" 
                    className={!state.loading ? "loader" : "loader active animate__animated animate__fadeIn"} 
                />

                {state.isFormLocked ? (
                    <>
                        <div className="notification-message" style={{ 
                            backgroundColor: '#fff3cd', 
                            padding: '15px 20px', 
                            borderRadius: '8px', 
                            margin: '15px 0',
                            border: '1px solid #ffeeba',
                            textAlign: 'center',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                        }}>
                            <span style={{ fontSize: '24px' }}>🔒</span>
                            <p style={{ 
                                margin: 0, 
                                color: '#856404',
                                fontWeight: 'bold'
                            }}>
                                תעודת הזהות כבר אושרה, לא ניתן לערוך את פרטי הכתובת
                            </p>
                        </div>

                        <div className="info-card">
                            <div className="info-section">
                                <div className="info-row">
                                    <div className="info-label">עיר / ישוב</div>
                                    <div className="info-value">{state.city}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-label">רחוב</div>
                                    <div className="info-value">{state.street}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-label">בית</div>
                                    <div className="info-value">{state.mwnumber}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-label">דירה</div>
                                    <div className="info-value">{state.appartment}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-label">מיקוד</div>
                                    <div className="info-value">{state.zip}</div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="inputs">
                        <div className="line clear">
                            <label style={{fontSize: '13px',color:'grey'}} >עיר / ישוב</label>
                            <CustomFloatInput 
                                disabled={state.isFormLocked} 
                                name='city' 
                                dbParams={{ function: environment === "dev" ? 'getCities4SelectDev' : 'getCities4Select', controller: 'app', valueField: 'id' }} 
                                updateValue={updateValue} 
                                value={state.city} 
                                placeholder={state.city || "עיר / ישוב"} 
                                cssClass={state.isFormLocked ? 'disabled' : ''} 
                                validationRules={{ required: true }} 
                                typeInput='searchDB' 
                                checkInputs={state.checkInputs} 
                                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                                readOnly={state.isFormLocked}
                            />
                        </div>
                        <br/>
                        <div className="line clear">
                            <CustomFloatInput 
                                disabled={state.isFormLocked} 
                                name='street' 
                                updateValue={updateValue} 
                                value={state.street} 
                                placeholder="רחוב" 
                                cssClass={state.isFormLocked ? 'disabled' : ''} 
                                validationRules={{ required: true }} 
                                typeInput='text' 
                                checkInputs={state.checkInputs} 
                                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                                readOnly={state.isFormLocked}
                            />
                        </div>

                        <div className="rows3 inputs3 clear line">
                            <CustomFloatInput 
                                disabled={state.isFormLocked} 
                                name='mwnumber' 
                                updateValue={updateValue} 
                                value={state.mwnumber} 
                                placeholder="בית" 
                                cssClass={state.isFormLocked ? 'disabled' : ''} 
                                validationRules={{ required: true }} 
                                typeInput='text' 
                                checkInputs={state.checkInputs} 
                                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                                readOnly={state.isFormLocked}
                            />
                            <CustomFloatInput 
                                disabled={state.isFormLocked} 
                                name='appartment' 
                                updateValue={updateValue} 
                                value={state.appartment} 
                                placeholder="דירה" 
                                cssClass={state.isFormLocked ? 'disabled' : ''} 
                                validationRules={{ required: true }} 
                                typeInput='text' 
                                checkInputs={state.checkInputs} 
                                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                                readOnly={state.isFormLocked}
                            />
                            <CustomFloatInput 
                                disabled={state.isFormLocked} 
                                name='zip' 
                                updateValue={updateValue} 
                                value={state.zip} 
                                placeholder="מיקוד" 
                                cssClass={state.isFormLocked ? 'disabled' : ''} 
                                validationRules={{ required: false }} 
                                typeInput='text' 
                                checkInputs={state.checkInputs} 
                                checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
                                readOnly={state.isFormLocked}
                            />
                        </div>

                        <div className="text-center" >
                            <br/><br/>
                            <Button 
                                className="sendBlueBtn" 
                                onClick={() => sendForm(sendRules)} 
                                size="lg" 
                                variant={sendRules ? 'success animate__animated animate__bounceIn' : state.btnSendClass}
                            >
                                שמירת שינויים
                            </Button>
                        </div>
                    </div>
                )}

                {state.responseEditRegister && state.responseEditRegister.error && 
                    <ModalDefaul 
                        variant="error" 
                        params={{title: 'שגיאה', text: state.responseEditRegister.error}} 
                        callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))}  
                    />
                }

                {state.responseEditRegister && state.responseEditRegister.ok && 
                    <ModalDefaul 
                        params={{title: 'תודה רבה', text: state.responseEditRegister.ok}} 
                        callBack={() => setState(prev => ({ ...prev, responseEditRegister: false }))}  
                    />
                }
            </div>

            <style>
                {`
                    .info-card {
                        background: white;
                        border-radius: 12px;
                        padding: 24px;
                        box-shadow: 0 2px 8px rgba(0,0,0,0.05);
                        margin-top: 20px;
                    }
                    .info-section {
                        padding-bottom: 20px;
                    }
                    .info-row {
                        display: flex;
                        margin-bottom: 16px;
                        align-items: center;
                    }
                    .info-label {
                        color: #6c757d;
                        width: 120px;
                        font-size: 0.9em;
                    }
                    .info-value {
                        color: #212529;
                        font-weight: 500;
                        flex: 1;
                    }
                    .disabled input {
                        background-color: transparent !important;
                        color: #495057 !important;
                        cursor: default !important;
                        border: none !important;
                        padding-right: 0 !important;
                        font-weight: 500 !important;
                    }
                    .disabled label {
                        color: #6c757d !important;
                        font-size: 0.9em !important;
                    }
                    .disabled input:hover,
                    .disabled input:focus {
                        background-color: transparent !important;
                        border: none !important;
                        box-shadow: none !important;
                    }
                `}
            </style>
        </div>
    )
}
