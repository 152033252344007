import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll'; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom';

const ModalDefaul = (props) => {
    const [modalShow, setModalShow] = useState(true);

    const handleModalShow = (value) => {
        setModalShow(value);
        if (props.callBack) {
            props.callBack();
        }
    };

    const { text, title } = props.params;

    return (
        <Modal
            className={`animate__animated animate__fadeInDown animate__faster modalSite alertModal ${props.variant}`}
            animation={false}
            onHide={() => handleModalShow(false)}
            size="lg" // גדול!!
            show={modalShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body>
                <Button className="closeBtn" onClick={() => handleModalShow(false)} variant="secondary">X</Button>
                <div className="Message">
                    <h2>{title}</h2>
                    <p>{text}</p>
                    {text === 'קיים משתמש עם אותה ת.ז במערכת' && (
                        <div className="singIn errorIsUser" style={{ textAlign: 'center' }}>
                            <NavLink
                                className="jumpPage"
                                role="menuitem"
                                onClick={() => scroll.scrollTo(0)}
                                to={'/login'}
                            >
                                <span>לכניסה למערכת <strong>לחצו כאן</strong></span>
                            </NavLink>
                            <br />
                            <NavLink
                                className="jumpPage"
                                role="menuitem"
                                onClick={() => scroll.scrollTo(0)}
                                to={'/reSendPassNew'}
                            >
                                <span>שחזור סיסמה <strong>לחצו כאן</strong></span>
                            </NavLink>
                            <br />
                            <br />
                        </div>
                    )}
                </div>
                <div className="closeBtnCont">
                    <Button className="closeBtnBig" onClick={() => handleModalShow(false)}>סגירה</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalDefaul;
