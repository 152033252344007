/**
 * DocsTromSherut - קומפוננט המציג מסמכים לטרום שירות
 * 
 * מציג את המסמכים הנדרשים לפני תחילת השירות
 * מבצע סינון של המסמכים שמתאימים לשלב זה לפי רשימת ה-IDs
 * 
 * Props:
 * - infoUser: מידע על המשתמש (כולל סטטוס השירות)
 */
import React from 'react';
import { filesTexts, objectsMyforms } from './fileFunctions';
import FileItem from './FileItem';
import { Grid } from '@material-ui/core';
import ExternalLink from './ExternalLink';
import ReadAndSign from './ReadAndSign';

export default function DocsTromSherut({ forms, refreshForms }) {
  const objectForm = objectsMyforms(forms);
  const userJ = JSON.parse(localStorage.getItem("userData"));

  // Debug logging to inspect forms
  console.log("DocsTromSherut: forms received", forms);
  console.log("DocsTromSherut: objectForm created", objectForm);
  console.log("DocsTromSherut: Does FormID13 exist in objectForm?", !!objectForm['FormID13']);

  // פונקציה לרענון רשימת הטפסים
  const handleFormsRefresh = () => {
    console.log("DocsTromSherut: handleFormsRefresh called");
    if (refreshForms && typeof refreshForms === 'function') {
      console.log("DocsTromSherut: Calling parent refreshForms");
      refreshForms();
    } else {
      console.warn("DocsTromSherut: No refreshForms function provided by parent");
    }
  };

  // Helper function to check if a form ID exists in the API response
  const hasFormInAPI = (formId, formsArray) => {
    if (!formsArray || !Array.isArray(formsArray)) return false;
    const formIdNum = formId.replace('FormID', '');
    return formsArray.some(form => 
      form.FormID === formIdNum || form.FormID === parseInt(formIdNum)
    );
  };

  // Define the pre-service document types with their configurations
  const preServiceDocTypes = [
    { id: 'FormID1', key: 'tz', component: FileItem, required: true },
    { id: 'FormID2', key: 'takanon', component: ReadAndSign, required: true },
    { id: 'FormID3', key: 'dira', component: ReadAndSign, required: false },
    { id: 'FormID4', key: 'facePicture', component: FileItem, required: true },
    { id: 'FormID5', key: 'medicalCertificate', component: FileItem, required: true },
    { id: 'FormID6', key: 'militar', component: FileItem, required: false },
    { id: 'FormID11', key: 'bank', component: FileItem, required: true },
    { id: 'FormID12', key: 'generalCharge', component: ReadAndSign, required: true },
    { id: 'FormID13', key: 'gius', component: ReadAndSign, required: false },
    { id: 'FormID15', key: 'question', component: ExternalLink, required: true },
    { id: 'FormID99', key: 'klali', component: FileItem, required: false },
    { id: 'FormID16', key: 'formId16', component: FileItem, required: false, onlyIfInAPI: true },
    { id: 'FormID17', key: 'formId17', component: FileItem, required: false, onlyIfInAPI: true },
  ];

  // Array to hold form items that will be displayed
  const formItems = [];

  // Special handling for FormID13 - Check if it exists in API response
  const form13InAPI = hasFormInAPI('FormID13', forms);
  console.log("DocsTromSherut: FormID13 exists in API?", form13InAPI);
  
  if (form13InAPI) {
    // Find the form in the API response
    const form13Data = forms.find(form => form.FormID === '13' || form.FormID === 13);
    
    if (form13Data) {
      console.log("DocsTromSherut: Found FormID13 in API data:", form13Data);
      
      // Add it directly to formItems as a special case
      formItems.push({
        id: 'FormID13',
        component: ReadAndSign,
        props: {
          formData: form13Data,
          texts: filesTexts['gius'],
          userJ: userJ,
          allowReupload: true,
          title: "הצהרת פטור מגיוס",
          refreshForms: handleFormsRefresh
        }
      });
    }
  }

  // Process each pre-service document type (skip FormID13 since we handled it separately)
  preServiceDocTypes.forEach(docType => {
    // Skip FormID13 as we've handled it specially above
    if (docType.id === 'FormID13') {
      console.log("DocsTromSherut: Skipping normal processing for FormID13");
      return;
    }
    
    // Debug logging for form processing
    console.log(`DocsTromSherut: Processing ${docType.id}, key ${docType.key}, onlyIfInAPI: ${docType.onlyIfInAPI}`);
    console.log(`DocsTromSherut: hasFormInAPI result: ${hasFormInAPI(docType.id, forms)}`);
    console.log(`DocsTromSherut: objectForm has this form: ${!!objectForm[docType.id]}`);
    
    // Skip forms marked as onlyIfInAPI if they're not in the API response
    if (docType.onlyIfInAPI && !hasFormInAPI(docType.id, forms) && !objectForm[docType.id]) {
      console.log(`DocsTromSherut: Skipping ${docType.id} due to onlyIfInAPI check`);
      return;
    }

    // Only add forms that exist in objectForm
    if (objectForm[docType.id]) {
      console.log(`DocsTromSherut: Adding ${docType.id} to formItems`);
      formItems.push({
        id: docType.id,
        component: docType.component,
        props: {
          formData: objectForm[docType.id],
          texts: filesTexts[docType.key],
          userJ: userJ,
          allowReupload: true,
          countDaysFirst: docType.key === 'ishurMahala',
          title: objectForm[docType.id].FormName,
          refreshForms: handleFormsRefresh // מעביר את פונקציית הרענון
        }
      });
    } else {
      console.log(`DocsTromSherut: ${docType.id} not found in objectForm`);
    }
  });

  return (
    <div className='docsCont'>
      <Grid 
        className='lineForm' 
        container 
        spacing={2} 
        alignItems="flex-start" 
        direction="row" 
        justifyContent="center"
      >
        {formItems.map(item => {
          console.log(item,'itemssss');
          const Component = item.component;
          
          return (
            <Grid item md={3} key={item.id}>
              <Component {...item.props} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
