/**
 * IshurimDocs - קומפוננט המציג אישורים קיימים במערכת
 * 
 * מאפשר צפייה במסמכים ואישורים שונים (אישור תקופת שירות, דוח תקבולים וכו')
 * אוסף את האישורים מה-API ומציג אותם באמצעות OpenPDFfiles
 * 
 * Props:
 * - infoUser: מידע על המשתמש
 */
import React, { useState } from 'react'

import { filesTexts } from './fileFunctions'
import { getFromApiSherutLeumi } from './fileFunctions';

import { Grid } from '@material-ui/core';

import OpenPDFfiles from './OpenPDFfiles';
import FileItem from './FileItem';
//import ReadAndSign from './ReadAndSign';

//console.log(filesTexts);

export default function IshurimDocs(props) {

  const {infoUser} = props
  console.log('props > ', props);

    const serviceStatus = infoUser?.SeviceStatus;

//haim 23.06.24
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);
    const [formsData, setFormsData] = useState(false);
    const [formsLoading, setFormsLoading] = useState(false);

  let userJ = JSON.parse(localStorage.getItem("userData"));
    
  if(!loading && !responseData) {
        const sendObj = {
            IDNumber: userJ.IDNO,
            SessionKey: userJ.SessionKey,
        }

        getFromApiSherutLeumi('/api/v2/volunteer/documents/list', sendObj, setLoading, setResponseData);
  }

  // Fetch available forms from API
  if(!formsLoading && !formsData) {
        const sendObj = {
            IDNumber: userJ.IDNO,
            SessionKey: userJ.SessionKey,
        }

        getFromApiSherutLeumi('/api/v2/volunteer/forms/list', sendObj, setFormsLoading, setFormsData);
  }
    
  let data = [];
  let forms = [];

  if(loading){
    return <Grid item md={12} >
    <p style={{textAlign: 'center', margin: '0 0 20px 0'}}>בטעינה ...</p>
  </Grid>
    
  }

  if(responseData){
    data = responseData.data['DocumentsList'];
    console.log("documents data", data);
  }

  if(formsData){
    forms = formsData.data?.Forms || [];
    console.log("forms data", forms);
  }
  
  // Function to map form types to filesTexts keys
  const getFileTextKey = (formName) => {
    switch(formName) {
      case 'אישור מחלה':
        return 'ishurMahala';
      case 'אישור עבודה':
        return 'ishurAvoda';
      case 'אישור לימודים':
        return 'ishurLimudim';
      case 'אחר':
        return 'klali';
      default:
        return null;
    }
  };

  // Get uploadable forms (type Scan)
  const uploadableForms = forms.filter(form => form.FormType === 'Scan');

  return (
    <div className='docsCont'>
        
        <Grid className='lineForm' container spacing={2}  alignItems="top" direction="row" justifyContent="center" >
            
            {data.length===0 && <Grid item md={12} >
              <p style={{textAlign: 'center', margin: '0 0 20px 0'}}>אין אישורים להצגה</p>
            </Grid> }

            {data.length!==0 && data.map((item, index) => (
                <OpenPDFfiles key={index} data={item} />
            ))}

            {/* מסיר את כל החלק של "העלאת מסמכים נוספים" מאזור האישורים */}
        </Grid>
    </div>
  )
}
/* 

פירוט קודים של מסמכים  הזמינים לרכזת "להורדה" דרך האפליקציה (קריאת שרת getDocument הסטטוס של המתנדבת נמשך מקריאת info)
 
1. ID 101  > טופס 101 (יוצג רק למתנדבים/ות בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear)

2. ID 102 >אישור תקופת שירות (יוצג רק למתנדבים/ות בסטטוס SeviceStatus: "AfterService וגם isEligibleForCertificate=True)

3. ID 103 > אישור הצבה (יוצג רק למתנדבים/ות בסטטוס SeviceStatus: "preService)
4. ID 104 > דוח תקבולים (דמי כיס) (יוצג רק לרק למתנדבים/ות בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear או AfterSrvice)
5. ID 118 > טופס בקשת אישור עבודה (יוצג רק למתנדבים בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear או SeviceStatus: "preService)
6. ID 119 > טופס בקשת אישור לימודים (יוצג רק למתנדבים בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear או SeviceStatus: "preService)
 */