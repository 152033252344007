/**
 * FileItem - קומפוננט להצגת מסמך בודד במערכת
 * 
 * מציג את מצב המסמך (Missing/Pending/Exist) ומאפשר העלאה של קובץ חדש
 * כאשר הסטטוס הוא Exist, כפתור ההעלאה מושבת
 * 
 * Props:
 * - formData: נתוני הטופס (FormID, Status, FormType, FormName, Msg)
 * - texts: טקסטים להצגה
 * - noHistoryForm: האם להציג כטופס ללא היסטוריה
 * - allowReupload: האם לאפשר העלאה מחדש
 * - countDaysFirst: האם נדרש לבחור תאריכים (למשל באישור מחלה)
 */
import React, { useState, useRef } from 'react';
import fileBgQuad from '../../../img/sherut-leumi/svg/files/fileBgQuad.png';
import v from '../../../img/sherut-leumi/svg/files/v.svg';
import x from '../../../img/sherut-leumi/svg/files/x.svg';
import UploadFile from './UploadFile';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function FileItem({
    formData,
    texts,
    noHistoryForm,
    allowReupload,
    title,
    countDaysFirst,
    userJ,
    ...props
}) {
    const [showUpload, setShowUpload] = useState(false);
    const displayTitle = title || texts?.title;
    const backgroundQuad = `url('${fileBgQuad}')`;
    const processIdRef = useRef(Date.now());
    
    // Debug logging
    console.log('FileItem rendering', {
        formID: formData?.FormID,
        hasTexts: !!texts,
        hasUserJ: !!userJ
    });

    const handleReuploadClick = () => {
        // Update process ID when manually triggering upload
        processIdRef.current = Date.now();
        setShowUpload(true);
    };

    const renderUploadButton = () => {
        // רק ת"ז חסומה להעלאה במצב Exist
        if (formData?.Status === "Exist" && formData?.FormID === 1) {
            return null;
        }

        return (
            <Button
                onClick={handleReuploadClick}
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
            >
                העלאת קובץ
            </Button>
        );
    };

    // Make sure we don't render the upload component if critical props are missing
    if (!formData || !formData.FormID) {
        console.error("FileItem missing critical formData", {formData});
        return (
            <div className='fileItem'>
                <div className='header'>
                    <h3>{displayTitle || "מסמך"}</h3>
                    <p>{texts?.filesType || ""}</p>
                </div>
                <div className='blueQuad' style={{ backgroundImage: backgroundQuad }}>
                    <p style={{color: 'red'}}>שגיאה: מידע הטופס חסר, אנא רענן את הדף</p>
                </div>
            </div>
        );
    }

    return (
        <div className='fileItem'>
            <div className='header'>
                <h3>{displayTitle}</h3>
                <p>{texts?.filesType}</p>
            </div>

            {/* Special case for sick leave forms - show status but still allow day selection */}
            {countDaysFirst && formData.Status === "Exist" && !showUpload ? (
                <div className='blueQuad docExist' style={{ backgroundImage: backgroundQuad }}>
                    <img src={v} alt='המסמך קיים במערכת' />
                    <p>{'המסמך אושר, ניתן להעלות מסמך חדש במידת הצורך'}</p>
                    {allowReupload && renderUploadButton()}
                </div>
            ) : countDaysFirst && showUpload ? (
                <UploadFile 
                    {...props} 
                    formData={formData}
                    texts={texts}
                    userJ={userJ}
                    countDaysFirst={countDaysFirst} 
                    customTitle={displayTitle} 
                    onCancel={() => setShowUpload(false)}
                    processId={processIdRef.current}
                    key={`upload-${processIdRef.current}`}
                    isReupload={true}
                    refreshForms={props.refreshForms}
                />
            ) : noHistoryForm ? (
                <UploadFile 
                    {...props} 
                    formData={formData}
                    texts={texts}
                    userJ={userJ}
                    customTitle={displayTitle}
                    processId={processIdRef.current}
                    key={`upload-${processIdRef.current}`}
                    isReupload={false}
                    refreshForms={props.refreshForms}
                />
            ) : showUpload ? (
                <UploadFile 
                    {...props} 
                    formData={formData}
                    texts={texts}
                    userJ={userJ}
                    customTitle={displayTitle} 
                    onCancel={() => setShowUpload(false)}
                    processId={processIdRef.current}
                    key={`upload-${processIdRef.current}`}
                    isReupload={true}
                    refreshForms={props.refreshForms}
                />
            ) : formData.Status === "Pending" ? (
                <div className='blueQuad docPending' style={{ backgroundImage: backgroundQuad, display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={v} alt='המסמך הועלה וממתין לאישור' />
                    <p>
                        המסמך הועלה וממתין לאישור 
                        {allowReupload && renderUploadButton()}
                    </p>
                </div>
            ) : formData.Status === "Missing" ? (
                <div className="blueQuad" style={{ backgroundImage: backgroundQuad }}>
                    <div className="docNoExist">
                        <img src={x} alt="המסמך לא קיים" />
                        <p>{'המסמך לא קיים'}</p>
                        {renderUploadButton()}
                    </div>
                </div>
            ) : (
                <div className='blueQuad docExist' style={{ backgroundImage: backgroundQuad }}>
                    <img src={v} alt='המסמך קיים במערכת' />
                    <p>{'המסמך קיים במערכת'}</p>
                    {allowReupload && formData.Status !== "Exist" && renderUploadButton()}
                </div>
            )}
        </div>
    );
}
