import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { map } from "lodash";
import { Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";

import { dateFromString } from "../clockInOut/editClockFunctions";
import { entryTraining, getTraning, iWillBeThere } from "./traningFunctions";
import loader from "../../img/preLoader.gif";

const TraningPage = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const userJ = useMemo(() => JSON.parse(localStorage.getItem("userData")), []);

  const loadTranings = useCallback(() => {
    const sendObj = { idno: userJ.IDNO, SessionKey: userJ.SessionKey };
    getTraning("/api/v2/volunteer/training/dates", sendObj, setLoading, setItems);
  }, [userJ.IDNO, userJ.SessionKey]);

  useEffect(() => {
    loadTranings();
  }, [loadTranings]);

  return (
    <div className="traningPage">
      <header className="clear">
        <h1>רשימת הכשרות</h1>
      </header>
      {loading && <img src={loader} alt="loader" className="loader active animate__fadeIn" />}
      <ul className="list">
        {map(items, (item) => (
          <Item key={item.TrainingID}  item={item} setLoading={setLoading} reloadItems={loadTranings} />
        ))}
      </ul>
    </div>
  );
};

const Item = ({ item, setLoading, reloadItems }) => {
  const [arrive, setArrive] = useState("");
  const userJ = useMemo(() => JSON.parse(localStorage.getItem("userData")), []);

  const today = moment();
  const startDate = moment(item.StartDate, "YYYYMMDD");
  const endDate = moment(item.EndDate, "YYYYMMDD");
  const isToday = today.isSame(startDate, "day");
  const isFuture = today.isBefore(startDate, "day");

  useEffect(() => {
    const dbValue = item?.TrainigDays?.[0]?.rsvp;
    setArrive(dbValue === "TRUE" ? "אגיע" : "");
  }, [item?.TrainigDays]);

  const sendValue = (value) => {
    const sendObj = {
      idno: userJ.IDNO,
      requestID: "string",
      sessionKey: userJ.SessionKey,
      deviceID: "string",
      trainingID: item?.TrainingID,
      rsvp: value === "אגיע" ? "TRUE" : "FALSE",
    };
    setArrive(value);
    iWillBeThere("/api/v2/Volunteer/training/rsvp", sendObj, setLoading, reloadItems);
  };

  return (
    <li className="trainingItem">
      <div className="text">
        <h3>{item.Title}</h3>
        {isFuture && (
          <div>
            <Button
              variant={arrive === "אגיע" ? "primary" : "outline-primary"}
              onClick={() => sendValue("אגיע")}
              disabled={arrive === "אגיע"}
            >
              אגיע
            </Button>
          </div>
        )}
        {isToday && (
          <Day item={item} setLoading={setLoading} reloadItems={reloadItems} />
        )}
      </div>
      <p className="date text-center button p-4">{dateFromString(item.StartDate)}</p>
    </li>
  );
};

const Day = ({ item, setLoading, reloadItems }) => {
  const [isArrived, setIsArrived] = useState(null);
  const userJ = useMemo(() => JSON.parse(localStorage.getItem("userData")), []);
  
  useEffect(() => {
    console.log('Raw item:', item);
    console.log('TrainigDays:', item?.TrainigDays);
    console.log('isArrived value:', item?.TrainigDays?.[0]?.isArrived);
    
    const arrivedStatus = item?.TrainigDays?.[0]?.isArrived ?? false;
    console.log('Setting arrived status to:', arrivedStatus);
    
    setIsArrived(arrivedStatus);
  }, [item?.TrainigDays]);

  useEffect(() => {
    console.log('Current isArrived state:', isArrived);
  }, [isArrived]);

  const handleArrival = () => {
    const sendObj = {
      sessionKey: userJ.SessionKey,
      requestID: "string", 
      idno: userJ.IDNO,
      deviceID: "string",
      trainingID: item.TrainingID,
      isArrived: (!isArrived).toString()
    };
    entryTraining("/api/v2/Volunteer/training/entry", sendObj, setLoading, setIsArrived, reloadItems);
  };

  return (
    <div className="switch p-4">
      <Button
        variant={isArrived ? "primary" : "outline-primary"}
        onClick={handleArrival}
        disabled={isArrived}
      >
        הגעתי
      </Button>
    </div>
  );
};

export default TraningPage;
