/**
 * FilesPage - דף ראשי לניהול מסמכים וטפסים
 * 
 * מארגן את הצגת המסמכים לפי שלב השירות:
 * - DocsTromSherut: מסמכים לטרום שירות
 * - ServiceDocs: מסמכים למהלך השירות
 * - IshurimDocs: אישורים לצפייה
 * 
 * מטפל ברענון הנתונים מהשרת
 */
import React, { useState, useEffect } from 'react';
import { Accordion } from "react-bootstrap";

import { RestUrls } from "../../../Components/-Helpers-/config";
import loader from "../../../img/preLoader.gif";
import DocsTromSherut from './DocsTromSherut';
import { getFromApiSherutLeumi } from './fileFunctions';
import "./files.scss";
import IshurimDocs from './IshurimDocs';
import ServiceDocs from './ServiceDocs';

// Create a variable to hold the refresh function that can be called from anywhere
export let refreshFilesList = null;

export default function FilesPage(props) {
  const { siteInfo } = props;
  
  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";
  const isMobile = siteInfo.isMobile;
  
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(false);
  const [infoUser, setInfoUser] = useState(false);
  const [showIshurim, setShowIshurim] = useState(false);
  
  const userJ = JSON.parse(localStorage.getItem("userData"));

  // Function to manually refresh the forms list
  const refreshFormsList = () => {
    console.log("Refreshing forms list...");
    const sendObj = {
      IDNumber: userJ.IDNO,
      SessionKey: userJ.SessionKey,
    };
    
    // Refresh the main forms list for DocsTromSherut and ServiceDocs
    getFromApiSherutLeumi('/api/v2/volunteer/forms/list', sendObj, setLoading, setResponseData);
    
    // Also refresh the documents list for IshurimDocs if it's being displayed
    console.log("Also refreshing documents list for IshurimDocs...");
    getFromApiSherutLeumi('/api/v2/volunteer/documents/list', sendObj, setLoading, (data) => {
      console.log("Documents list refreshed:", data);
      
      // Force a re-render by updating the state with a new timestamp
      if (showIshurim) {
        setShowIshurim(false);
        setTimeout(() => {
          setShowIshurim(true);
        }, 100);
      }
    });
  };

  // Use useEffect to make the initial API calls only once when the component mounts
  useEffect(() => {
    refreshFormsList(); // Always refresh on mount
    
    if (!infoUser) {
      const sendObj = {
        IDNumber: userJ.IDNO,
        SessionKey: userJ.SessionKey,
      };
      
      // Get user info
      getFromApiSherutLeumi('/api/v2/volunteer/info', sendObj, setLoading, setInfoUser);
    }
    
    // Store the refresh function in the global variable
    refreshFilesList = refreshFormsList;
  }, []);

  const serviceStatus = infoUser?.data?.SeviceStatus;
  const isPreService = serviceStatus === 'Searching' || serviceStatus === 'Candidate' || serviceStatus === 'PreService';
  const showServiceDocs = serviceStatus && !isPreService;
  const showIshurimSection = serviceStatus && serviceStatus !== 'Searching' && serviceStatus !== 'Candidate';

  return (
    <div className="DataSection filesPage">
      <header style={{ backgroundImage: isMobile ? `url(${m_topPic})` : `url(${topPic})`, paddingTop: '100px' }}>
      </header>
      
      <div className='header-content' style={{paddingTop: '100px', textAlign: 'center'}}>
        <h1>המסמכים שלי</h1>
        <p>העלאת מסמכים, טפסים ובקשות</p>
      </div>
      
      <img
        src={loader}
        alt="loader"
        className={loading ? "loader active animate__animated animate__fadeIn" : "loader"}
      />

      {infoUser?.data && (
        <div className="AccordionCont">
          <Accordion className="accordions acc1" defaultActiveKey="0">
            <Accordion.Item eventKey="01">
              <Accordion.Header>
                <span>טרום שירות</span>
              </Accordion.Header>
              <Accordion.Body>
                {responseData?.data?.Forms && <DocsTromSherut forms={responseData.data.Forms} refreshForms={refreshFormsList} />}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {showServiceDocs && (
            <Accordion className="accordions acc1" defaultActiveKey="0">
              <Accordion.Item eventKey="02">
                <Accordion.Header>
                  <span>מהלך שירות</span>
                </Accordion.Header>
                <Accordion.Body>
                  {responseData?.data?.Forms && <ServiceDocs forms={responseData.data.Forms} refreshForms={refreshFormsList} />}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {showServiceDocs && (
            <Accordion className="accordions acc1" defaultActiveKey="0">
              <Accordion.Item eventKey="03">
                <Accordion.Header onClick={() => setShowIshurim(true)}>
                  <span>אישורים</span>
                </Accordion.Header>
                <Accordion.Body>
                  {showIshurim && <IshurimDocs infoUser={infoUser.data} refreshForms={refreshFormsList} />}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      )}
    </div>
  );
}
