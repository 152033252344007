/**
 * ServiceDocs - קומפוננט המציג את כל המסמכים הקשורים למהלך השירות
 * 
 * מקבל רשימת טפסים מה-API ומארגן אותם לפי סוגים
 * מטפל בהצגת המסמכים בהתאם לסוגם (FileItem, SickLeaveForm, ReadAndSign, ExternalLink)
 * מאפשר רענון של רשימת הטפסים
 * 
 * Props:
 * - forms: רשימת הטפסים מה-API
 * - refreshForms: פונקציה לרענון הרשימה
 */
import React from 'react';
import { filesTexts, objectsMyforms } from './fileFunctions';
import FileItem from './FileItem';
import { Grid } from '@material-ui/core';
import ExternalLink from './ExternalLink';
import ReadAndSign from './ReadAndSign';
import SickLeaveForm from './SickLeaveForm';

export default function ServiceDocs({ forms, refreshForms }) {
  const objectForm = objectsMyforms(forms);
  const userJ = JSON.parse(localStorage.getItem("userData"));
  
  // פונקציה לרענון רשימת הטפסים
  const handleFormsRefresh = () => {
    console.log("ServiceDocs: handleFormsRefresh called");
    if (refreshForms && typeof refreshForms === 'function') {
      console.log("ServiceDocs: Calling parent refreshForms");
      refreshForms();
    } else {
      console.warn("ServiceDocs: No refreshForms function provided by parent");
    }
  };
  
  // Helper function to check if a form ID exists in the API response
  const hasFormInAPI = (formId, formsArray) => {
    if (!formsArray || !Array.isArray(formsArray)) return false;
    return formsArray.some(form => form.FormID === formId || form.FormID === parseInt(formId));
  };
  
  // Map to track existing forms by ID and status
  const existingForms = {};
  console.log(objectForm, "objectForm");
  // Process forms that already exist in the system
  Object.keys(objectForm).forEach(key => {
    const formId = key.replace('FormID', '');
    existingForms[formId] = objectForm[key];
  });
  
  // Create a map of form names from the API response to track available forms
  const availableForms = new Map();
  if (forms && Array.isArray(forms)) {
    forms.forEach(form => {
      availableForms.set(form.FormID, {
        name: form.FormName,
        type: form.FormType,
        status: form.Status
      });
    });
  }
  
  // Define the allowed document types
  const allowedDocTypes = [
    { name: 'אישורי מחלה', key: 'ishurMahala', formId: '10', onlyIfInAPI: true },
    { name: 'אישור עבודה', key: 'ishurAvoda', formId: '20', onlyIfInAPI: true },
    { name: 'אישור לימודים', key: 'ishurLimudim', formId: '40', onlyIfInAPI: true },
    { name: 'מסמך כללי', key: 'klali', formId: '99' },
    { name: 'דיווח תקלה בדירה', key: 'diraProblem', formId: '50', onlyIfInAPI: true },
    // These forms will only be shown if they exist in the forms array or objectForm
    { name: 'אישור אפוטרופוס', key: 'custodian', formId: '14', onlyIfInAPI: true },
    { name: 'ועדה רפואית', key: 'formId16', formId: '16', onlyIfInAPI: true },
    { name: 'סיכום רפואי לועדה רפואית', key: 'formId17', formId: '17', onlyIfInAPI: true }
  ];

  // IDs of forms handled by DocsTromSherut (pre-service)
  const preServiceFormIds = ['1', '2', '3', '4', '5', '6', '11', '12', '15', '99'];

  // Function to map form names to filesTexts keys
  const getFileTextKey = (formName) => {
    switch(formName) {
      case 'אישור מחלה':
        return 'ishurMahala';
      case 'אישור עבודה':
        return 'ishurAvoda';
      case 'אישור לימודים':
        return 'ishurLimudim';
      case 'אישור אפוטרופוס':
        return 'custodian';
      case 'ועדה רפואית':
        return 'formId16';
      case 'סיכום רפואי לועדה רפואית':
        return 'formId17';
      case 'צילום ת"ז + ספח':
        return 'tz';
      case 'אישור ניהול חשבון בנק':
        return 'bank';
      case 'אישור רפואי':
        return 'medicalCertificate';
      case 'תמונת פנים':
        return 'facePicture'; 
      case 'הרשאת חיוב כללית':
        return 'generalCharge';
      case 'תקנון':
        return 'takanon';
      case 'שאלון הכוון':
        return 'question';
      case 'דיווח תקלה בדירה':
        return 'diraProblem';
      case 'אחר':
        return 'klali';
      default:
        // For forms we don't have specific mapping for
        return null;
    }
  };
  
  // Array to hold form items that will be displayed
  const formItems = [];
  
  // Track text types already added to prevent duplicates
  const addedTextTypes = new Set();
  
  // Process the forms from the API response that match the allowed types
  if (forms && Array.isArray(forms)) {
    forms.forEach(form => {
      const formId = form.FormID.toString();
      const textKey = getFileTextKey(form.FormName);
      
      // Skip forms that are handled in pre-service
      if (preServiceFormIds.includes(formId)) {
        return;
      }
      
      // Only process forms that are in our allowed list
      if (!textKey || !allowedDocTypes.some(type => type.key === textKey)) {
        return;
      }
      
      // Skip if we already added this text type
      if (addedTextTypes.has(textKey) || !filesTexts[textKey]) {
        return;
      }
      
      // Get the appropriate component based on FormType
      const getComponent = () => {
        if (form.FormType === "Link") return ExternalLink;
        if (form.FormType === "ReadAndSign") return ReadAndSign;
        // Use SickLeaveForm for sick leave forms (formId 10)
        if (form.FormID === "10" || form.FormID === 10) return SickLeaveForm;
        return FileItem;
      };
      
      // Check if this is a sick leave form
      const isIshurMahala = textKey === 'ishurMahala';
      
      // For existing forms in the system
      if (existingForms[formId]) {
        // For forms with Pending status, prepare appropriate upload UI
        const formData = {...existingForms[formId]};
        
        // If the form is in Pending status, prepare for re-upload
        if (formData.Status === "Pending") {
          formData.Msg = filesTexts[textKey]?.pendingText || 'המסמך ממתין לאישור, ניתן להעלות מסמך חדש';
        }
        
        // Removed the section that forces sick leave forms to Pending status
        // Only set appropriate message based on current status
        if (isIshurMahala) {
          if (formData.Status === "Pending") {
            formData.Msg = filesTexts[textKey]?.pendingText || 'המסמך ממתין לאישור, ניתן להעלות מסמך חדש';
          } else if (formData.Status === "Missing") {
            formData.Msg = filesTexts[textKey]?.startText || 'המסמך לא קיים';
          } else if (formData.Status === "Exist") {
            formData.Msg = filesTexts[textKey]?.existText || 'המסמך אושר, ניתן להעלות מסמך חדש במידת הצורך';
          }
        }
        
        formItems.push({
          id: formId,
          component: getComponent(),
          props: {
            texts: filesTexts[textKey],
            formData: formData,
            userJ: userJ,
            allowReupload: true,
            countDaysFirst: isIshurMahala,
            noHistoryForm: isIshurMahala,
            title: form.FormName, // Use the actual form name from API
            refreshForms: handleFormsRefresh
          }
        });
      } else {
        // For forms not yet uploaded
        formItems.push({
          id: formId,
          component: getComponent(),
          props: {
            texts: filesTexts[textKey],
            formData: { 
              FormID: formId,
              Status: "Missing",
              FormType: form.FormType,
              FormName: form.FormName,
              Msg: form.Msg || filesTexts[textKey]?.startText || ''
            },
            userJ: userJ,
            noHistoryForm: true,
            allowReupload: true,
            countDaysFirst: isIshurMahala,
            title: form.FormName, // Use the actual form name from API
            refreshForms: handleFormsRefresh
          }
        });
      }
      
      addedTextTypes.add(textKey);
    });
  }
  
  // Add any allowed document types that weren't found in the API response
  allowedDocTypes.forEach(docType => {
    if (!addedTextTypes.has(docType.key)) {
      const defaultFormId = docType.formId;
      
      // Skip pre-service forms, except for forms marked as alwaysShow
      if (preServiceFormIds.includes(defaultFormId) && !docType.alwaysShow) {
        return;
      }
      
      // Skip forms marked as onlyIfInAPI if they're not in the API response, unless marked as alwaysShow
      if (docType.onlyIfInAPI && !docType.alwaysShow && !hasFormInAPI(docType.formId, forms) && !existingForms[defaultFormId]) {
        return;
      }
                            
      const getComponent = () => {
        if (docType.key === 'diraProblem' || docType.key === 'question') return ExternalLink;
        if (docType.key === 'generalCharge' || docType.key === 'takanon') return ReadAndSign;
        // Use SickLeaveForm for sick leave forms (formId 10)
        if (docType.formId === "10") return SickLeaveForm;
        return FileItem;
      };
      
      // Determine form type based on component
      const getFormType = () => {
        if (getComponent() === ExternalLink) return "Link";
        if (getComponent() === ReadAndSign) return "ReadAndSign";
        return "Scan";
      };
      
      // Prepare formData with appropriate handling for Pending status
      let formData;
      const isIshurMahala = docType.key === 'ishurMahala';
      
      // Handle special case for sick leave forms (FormID 10)
      const isSickLeaveForm = defaultFormId === '10';
      
      if (existingForms[defaultFormId]) {
        formData = {...existingForms[defaultFormId]};
        // Handle messages based on status for sick leave forms
        if (isIshurMahala) {
          if (formData.Status === "Pending") {
            formData.Msg = filesTexts[docType.key]?.pendingText || 'המסמך ממתין לאישור, ניתן להעלות מסמך חדש';
          } else if (formData.Status === "Missing") {
            formData.Msg = filesTexts[docType.key]?.startText || 'המסמך לא קיים';
          } else if (formData.Status === "Exist") {
            formData.Msg = filesTexts[docType.key]?.existText || 'המסמך אושר, ניתן להעלות מסמך חדש במידת הצורך';
          }
        } else if (formData.Status === "Pending") {
          // For other forms with Pending status, prepare appropriate upload UI
          formData.Msg = filesTexts[docType.key]?.pendingText || 'המסמך ממתין לאישור, ניתן להעלות מסמך חדש';
        }
      } else {
        // For new forms (not found in API)
        formData = { 
          FormID: defaultFormId, 
          Status: "Missing", // Changed from conditional Pending/Missing
          FormType: getFormType(),
          FormName: docType.name,
          Msg: filesTexts[docType.key]?.startText || 'ניתן להעלות מסמך חדש'
        };
      }
      
      formItems.push({
        id: defaultFormId,
        component: getComponent(),
        props: {
          texts: filesTexts[docType.key],
          formData: formData,
          userJ: userJ,
          noHistoryForm: !existingForms[defaultFormId] || isIshurMahala,
          allowReupload: true,
          countDaysFirst: isIshurMahala,
          title: docType.name,
          refreshForms: handleFormsRefresh
        }
      });
    }
  });
  
  return (
    <div className='docsCont'>
      <Grid 
        className='lineForm' 
        container 
        spacing={2} 
        alignItems="top" 
        direction="row" 
        justifyContent="center"
      >
        {formItems.map(item => {
          const Component = item.component;
          
          return (
            <Grid item md={3} key={`form-${item.id}`}>
              <Component {...item.props} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}