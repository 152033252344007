/* eslint-disable no-loop-func */
/* eslint-disable no-useless-concat */
import axios from "axios";
import { toast } from "react-toastify";
import { RestUrls } from "../../Components/-Helpers-/config";
import { checkTextAreaReportContent, sendErrorLog } from "./clockFunctions";

// Constants
const environment = process.env.REACT_APP_ENVIRONMENT;
const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;

// Helper functions
const getUserTimezoneOffset = () => {
  try {
    const israelTZ = 'Asia/Jerusalem';
    const date = new Date();
    
    // Simple calculation for Israel timezone
    const now = new Date();
    const jan = new Date(now.getFullYear(), 0, 1);
    const jul = new Date(now.getFullYear(), 6, 1);
    const isDST = now.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    
    return isDST ? 180 : 120; // 180 minutes for DST, 120 for standard time
  } catch (error) {
    console.error('Timezone detection error:', error);
    // Default to Israel timezone offset
    return 120; // Default to +2 hours
  }
};

// Create separate configs for different API types
const createApiConfig = (isStatus = false) => ({
  headers: { 
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'User-Agent': navigator.userAgent
  },
  timeout: isStatus ? 30000 : 45000, // 30 seconds for status, 45 for others
  validateStatus: status => status < 500, // Allow non-500 responses to be handled
  retry: isStatus ? 3 : 2,
  retryDelay: (retryCount) => retryCount * (isStatus ? 2000 : 3000)
});

function humanClock(hoursMinutes) {
  if (hoursMinutes) {
    const hours = hoursMinutes.substring(0, 2);
    const minutes = hoursMinutes.substring(2, 4);
    return hours + ":" + minutes;
  } else {
    return "00:00";
  }
}

function prettyDay(monthDay, monthNumber, currentYear) {
  const startDate = `${currentYear}-${monthNumber}-${monthDay}`;
  const monthYear = new Date(
    startDate.toString().replace(/-/g, "/")
  ).toLocaleDateString("he", {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (monthYear === "Invalid Date") {
    toast.error("שגיאה");
    return "---";
  }
  return monthYear;
}

function prettyDay2(monthDay, monthNumber, currentYear) {
  const startDate = `${currentYear}-${monthNumber}-${monthDay}`;
  const monthYear = new Date(
    startDate.toString().replace(/-/g, "/")
  ).toLocaleDateString("he", {
    weekday: "long",
    day: "2-digit",
  });

  if (monthYear === "Invalid Date") {
    toast.error("שגיאה");
    return "---";
  }
  return monthYear;
}

export function dateFromString(yearMonthDay) {
  console.log("yearMonthDay", yearMonthDay);
  if (yearMonthDay) {
    // Check if input is a date string
    if (yearMonthDay.includes('/')) {
      try {
        const date = new Date(yearMonthDay);
        if (!isNaN(date.getTime())) {
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          return `${day}.${month}.${year}`;
        }
      } catch (e) {
        console.error("Date parsing error:", e);
      }
    }
    
    // Original logic for YYYYMMDD format
    const prettyDate =
      yearMonthDay.substring(6, 8) +
      "." +
      yearMonthDay.substring(4, 6) +
      "." +
      yearMonthDay.substring(0, 4);
    return prettyDate;
  } else {
    return false;
  }
}

export function getTypeRecord(id) {
  switch (id) {
    case "1":
      return "נוכחות";
    case "2":
      return "נוכחות בהכשרה";
    case "3":
      return "מחוץ למקום השירות";
    case "4":
      return "נוכחות ביום שבתון";
    default:
      return "לא נבחר";
  }
}

export function getTypeRecordsList() {
  const data = [
    { id: "1", name: "נוכחות" },
    { id: "2", name: "נוכחות בהכשרה" },
    { id: "3", name: "מחוץ למקום השירות" },
  ];
  return data;
}

export async function getMonthData(url, sendObj, setLoading, setResponseData, setDaysMonthData) {
  setLoading(true);
  const requestWithTimezone = {
    ...sendObj,
    TimezoneOffset: getUserTimezoneOffset()
  };

  let retryCount = 0;
  const maxRetries = 3;

  while (retryCount < maxRetries) {
    try {
      const config = {
        ...createApiConfig(false),
        // Increase timeout for WebView
        timeout: /wv/.test(navigator.userAgent) ? 45000 : 30000,
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      };

      console.log('getMonthData Request:', {
        url: `${API_BASE_URL}${url}`,
        payload: requestWithTimezone,
        config,
        retryAttempt: retryCount + 1
      });
      
      const response = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, config);
      
      console.log('getMonthData Response:', {
        status: response.status,
        data: response.data,
        headers: response.headers
      });

      if (!response?.data?.Result || response?.data?.Result !== "Success") {
        retryCount++;
        console.log(`Invalid response result, retry attempt ${retryCount}/${maxRetries}`);
        
        if (retryCount >= maxRetries) {
          setResponseData(false);
          throw new Error("ECF2 שגיאה בקריאת סטטוס");
        }
        
        // Wait before retrying (backoff strategy)
        await new Promise(resolve => setTimeout(resolve, 2000 * retryCount));
        continue; // Skip to next retry attempt
      }

      if (!response?.data?.Shib[0]) {
        setResponseData(false);
        throw new Error("לא נמצאו נתונים");
      }

      populateMonthData(response.data.Shib[0], setResponseData, setDaysMonthData);
      break; // Exit retry loop on success

    } catch (error) {
      retryCount++;

      // Special handling for timeout in WebView
      if (error.code === 'ECONNABORTED' && /wv/.test(navigator.userAgent)) {
        console.log(`WebView timeout retry ${retryCount}/${maxRetries}`);
        if (retryCount < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, 2000 * retryCount));
          continue;
        }
      }

      const errorDetails = {
        originalError: error,
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
        requestData: requestWithTimezone,
        networkInfo: {
          online: navigator.onLine,
          connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
          downlink: navigator.connection ? navigator.connection.downlink : 'unknown',
          rtt: navigator.connection ? navigator.connection.rtt : 'unknown'
        },
        retryAttempt: retryCount
      };

      // If this was the last retry, handle the error
      if (retryCount === maxRetries) {
        console.error('getMonthData Error Details:', errorDetails);
        setResponseData({});
        
        let errorMessage = error.message;
        if (!navigator.onLine) {
          errorMessage = 'אין חיבור לאינטרנט, נא לבדוק את החיבור ולנסות שוב';
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = 'השרת לא מגיב כרגע, אנא נסה שוב מאוחר יותר';
        } else if (error.response?.status === 401) {
          errorMessage = 'נדרשת התחברות מחדש';
        } else if (error.response?.status === 404) {
          errorMessage = 'השירות לא זמין כרגע, אנא נסה שוב מאוחר יותר';
        }

        await sendErrorLog(error, "getMonthData Failed", {
          url: `${API_BASE_URL}${url}`,
          method: "POST",
          payload: requestWithTimezone,
          errorDetails,
          errorMessage
        });

        toast.error(errorMessage, {
          toastId: "error",
        });
        break;
      }
    }
  }

  setLoading(false);
}

export function getAbsenceList(url, sendObj, setLoading, setResponseData) {
  setLoading(true);
  const json = JSON.stringify(sendObj);

  axios
    .post(API_BASE_URL + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      setLoading(false);
      if (!res?.data?.Items) {
        setResponseData(false);
        return toast.error("שגיאה");
      }
      setResponseData(res.data.Items);
    })
    .catch((error) => {
      setLoading(false);
      sendErrorLog(error, "getAbsenceList Failed", {
        url: API_BASE_URL + url,
        method: "POST",
        payload: sendObj,
      });
      toast.error("שגיאה", {
        toastId: "error",
      });
    });
}

function populateMonthData(response, setResponseData, setDaysMonthData) {
  let isOpen = true;

  if (
    response.ReportConfirmationDate &&
    response.ReportConfirmationDateByEmployee &&
    response.ReportConfirmationDateByManager
  ) {
    isOpen = false;
  }

  let items = [];

  if (response?.Dates && response.Dates.length > 0) {
    items = populateDayItems(+response.ReportID, response.Dates);
  }

  const monthData = {
    ReportID: +response.ReportID,
    isOpen: isOpen,
    Month: response.Month,
    Year: response.Year,
    LateReportQuota: +response.LateReportQuota,
    LateReportUsed: response.LateReportUsed,
    SumOfWorkingDays: +response.SumOfWorkingDays,
    SumOfWorkingHours: +response.SumOfWorkingHours,
    AmountOfUsedVacationDays: +response.AmountOfUsedVacationDays,
    ReportConfirmationDate: response.ReportConfirmationDate,
    ReportConfirmationDateByEmployee: response.ReportConfirmationDateByEmployee,
    ReportConfirmationDateByManager: response.ReportConfirmationDateByManager,
    ConfirmedSickDays: +response.ConfirmedSickDays,
    UnconfirmedSickDays: +response.UnconfirmedSickDays,
  };

  setResponseData(monthData);
  setDaysMonthData(items);
}

function populateDayItems(ReportID, Dates) {
  let items = [];

  Dates.forEach((item) => {
    let entryDays = [];

    if (item?.Times && item.Times.length > 0) {
      entryDays = populateEntryDays(ReportID, item.Times);
    }

    const isOk = checkIsOk(item?.Times);

    const newItem = {
      dayData: {
        Reson: item?.Reson,
        ResonLateMtn: item?.ResonLateMtn,
        ResonLateRak: item?.ResonLateRak,
        YM: item?.YM,
        DOM: item?.DOM,
      },
      id: ReportID + "_" + item.DOM,
      title: prettyDay(
        item?.DOM,
        item.YM.substring(4, 6),
        item.YM.substring(0, 4)
      ),
      title2: prettyDay2(
        item?.DOM,
        item.YM.substring(4, 6),
        item.YM.substring(0, 4)
      ),
      reportsCount: item?.Times ? item.Times.length : 0,
      isOk: isOk,
      isAbsence: item?.Reson ? item.Reson : false,
      items: entryDays,
    };

    items.push(newItem);
  });

  return items.reverse();
}

function populateEntryDays(ReportID, entryDays) {
  let count = 1;
  let entryDaysNew = [];

  entryDays.forEach((item) => {
    console.log("timeF - timeT - before", item.TimeF, item.TimeT);
    const timeF = item?.TimeF
      ? new Date(item.TimeF)
          .toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })
          .replace(":", "")
      : false;
    const timeT = item?.TimeT
      ? new Date(item.TimeT)
          .toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })
          .replace(":", "")
      : false;
    console.log("timeF - timeT - after", timeF, timeT);
    const newItem = {
      id: ReportID + "_" + count,
      in: timeF ? humanClock(timeF) : false,
      out: timeT ? humanClock(timeT) : false,
      api: item,
    };

    entryDaysNew.push(newItem);
    count++;
  });

  return entryDaysNew;
}

function checkIsOk(entryDays) {
  return entryDays ? entryDays.every((item) => item.TimeF && item.TimeT) : true;
}

export function checkData4Save(allChanges) {
  let okFlag = true;

  if (
    allChanges?.Dates[0]?.Reson === "חופשה" &&
    allChanges?.Dates[0]?.Times &&
    allChanges?.Dates[0]?.Times.length > 0
  ) {
    toast.error("לא ניתן לבחור חופשה אם קיימים דיווחים");
    return false;
  }

  let sortedTimes = allChanges?.Dates[0]?.Times.sort(
    (a, b) => new Date(a.TimeF) - new Date(b.TimeF)
  );

  // Get current date and time for future validation
  const now = new Date();

  for (let i = 0; i < sortedTimes.length; i++) {
    let item = sortedTimes[i];

    if ((item.Typ === "2" || item.Typ === "3") && !item.MoreInfo) {
      okFlag = false;
      toast.error("יש להזין פירוט דיווח");
      return okFlag;
    } else if (!checkTextAreaReportContent(item.Typ, item.MoreInfo)) {
      okFlag = false;
      return false;
    } else if (!item.Typ) {
      okFlag = false;
      toast.error("יש לבחור סוג דיווח");
      return okFlag;
    } else if (!item.TimeF && !item.TimeT) {
      okFlag = false;
      toast.error("יש להזין שעות כניסה / יציאה");
      return okFlag;
    } else if (!item.TimeF && item.TimeT) {
      // New check for exit without entry
      okFlag = false;
      toast.error("נא להכניס דיווח כניסה");
      return okFlag;
    } else if (item.TimeF && !item.TimeT) {
      // New check for entry without exit for past dates
      const timeF = new Date(item.TimeF);
      const reportDate = new Date(timeF);
      reportDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // If the entry date is in the past, require an exit time
      if (reportDate < today) {
        okFlag = false;
        toast.error("נא להכניס דיווח יציאה");
        return okFlag;
      }
    } else if (item.TimeF && item.TimeT) {
      const timeF = new Date(item.TimeF);
      const timeT = new Date(item.TimeT);

      // Check if times are valid
      if (isNaN(timeF.getTime()) || isNaN(timeT.getTime())) {
        okFlag = false;
        toast.error("שעות לא תקינות");
        return okFlag;
      }

      // Check if end time is after start time
      if (timeT <= timeF) {
        okFlag = false;
        toast.error("שעת היציאה חייבת להיות מאוחרת משעת הכניסה");
        return okFlag;
      }

      // Check if reporting for current day and exit time is in the future
      if (isSameDay(timeT, now) && timeT > now) {
        okFlag = false;
        toast.error("לא ניתן לדווח על שעת יציאה עתידית");
        return okFlag;
      }
    }

    // Check for overlapping times
    if (i < sortedTimes.length - 1) {
      let nextItem = sortedTimes[i + 1];
      if (new Date(item.TimeT) > new Date(nextItem.TimeF)) {
        okFlag = false;
        toast.error("קיימת חפיפה בין זמני הדיווח");
        return okFlag;
      }
    }
  }

  return okFlag;
}

// Helper function to check if two dates are on the same day
function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function sendChangesToApi(
  url,
  sendObj,
  setLoading,
  allChanges,
  closeMe,
  reloadData
) {
  console.log("url => ", url);

  const sendData = makeMyUpdateObj(sendObj, allChanges);
  console.log("sendData", sendData);

  if (!sendData) {
    toast.error("שגיאה בהכנת הנתונים לשליחה - אנא בדוק את כל השדות ונסה שוב");
    return false;
  }

  setLoading(true);

  const json = JSON.stringify(sendData);

  console.log("json", json);
  axios
    .post(API_BASE_URL + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      console.log("response of ", res);
      setLoading(false);
      console.log("response update", res?.data);

      // Check for business errors first
      if (res?.data?.IsBusinessError !== undefined) {
        if (res?.data?.IsBusinessError === true) {
          // Business error - show the specific error message
          return toast.error(res.data.ErrorMessage || "שגיאת משתמש - אנא בדוק את הפרטים שהזנת");
        } else if (res?.data?.ErrorMessage) {
          // System error - show more detailed message
          return toast.error(`שגיאת מערכת: ${res.data.ErrorMessage}`);
        }
      }

      if (res?.data?.Result === "Error") {
        return toast.error(res?.data?.ErrorMessage || "שגיאה בעיבוד הבקשה - אנא נסה שוב");
      }

      if (
        res?.data?.ErrorMessage &&
        res?.data?.ErrorMessage?.includes("OverQuota")
      ) {
        toast.error(
          "מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול"
        );
        closeMe("bottom", false);
        reloadData();
      } else if (res?.data?.Reason && res?.data?.Reason.includes("OverQuota")) {
        console.log("yes");
        toast.error(
          "מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול"
        );
        closeMe("bottom", false);
        reloadData();
      } else if (
        res?.data?.Reason &&
        res?.data?.Reason.includes("ימי ההיעדרות מסוג מחלה")
      ) {
        toast.error(
          "ניצלת בעבר את מכסת ימי ההיעדרות מסוג מחלה סיבת ההיעדרות עודכנה לחופש"
        );
        closeMe("bottom", false);
        reloadData();
      } else if (
        res?.data?.ErrorMessage &&
        res?.data?.ErrorMessage.includes("OutOfOrder")
      ) {
        return toast.error("שגיאה בסדר השעות: קיימות שעות חופפות בדיווח");
      } else if (
        res?.data?.ErrorMessage &&
        res?.data?.ErrorMessage.includes("ToMuchInOuts")
      ) {
        return toast.error("שגיאה: יותר מדי דיווחים ידניים לתאריך זה");
      } else if (
        res?.data?.ErrorMessage &&
        res?.data?.ErrorMessage.includes("Early")
      ) {
        return toast.error("שגיאה: הדיווח מוקדם מהשעה המוגדרת במערכת");
      } else if (res?.data?.Result === "failed") {
        if (res?.data?.ErrorMessage) {
          return toast.error(`שגיאה: ${res.data.ErrorMessage}`);
        } else {
          return toast.error("שגיאה בשמירת הנתונים - אנא בדוק את כל הפרטים ונסה שוב");
        }
      } else {
        toast.success("השינויים נשמרו בהצלחה");
        closeMe("bottom", false);
        reloadData();
      }
    })
    .catch((error) => {
      setLoading(false);
      sendErrorLog(error, "sendChangesToApi Failed", {
        url: API_BASE_URL + url,
        method: "POST",
        payload: sendData,
      });
      
      // Check for business errors in the error response
      if (error.response?.data?.IsBusinessError === true && error.response?.data?.ErrorMessage) {
        toast.error(error.response.data.ErrorMessage, {
          toastId: "error",
        });
      } else {
        // Provide more specific error message based on error type
        let errorMessage = "שגיאת מערכת - נא לנסות שוב מאוחר יותר";
        
        if (!navigator.onLine) {
          errorMessage = "אין חיבור לאינטרנט, נא לבדוק את החיבור ולנסות שוב";
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = "השרת לא מגיב כרגע, אנא נסה שוב מאוחר יותר";
        } else if (error.response) {
          if (error.response.status === 401) {
            errorMessage = "פג תוקף החיבור למערכת, נדרשת התחברות מחדש";
          } else if (error.response.status === 404) {
            errorMessage = "השירות לא נמצא, אנא נסה שוב מאוחר יותר";
          } else if (error.response.status >= 500) {
            errorMessage = "שגיאה בשרת המערכת, אנא נסה שוב מאוחר יותר";
          }
        }
        
        toast.error(errorMessage, {
          toastId: "error",
        });
      }
    });
}

function makeMyUpdateObj(sendObj, allChanges) {
  console.log("Starting makeMyUpdateObj with:", { sendObj, allChanges });

  // Validate basic date requirements
  if (!allChanges?.Dates?.[0]?.YM || !allChanges?.Dates?.[0]?.DOM) {
    console.error("Missing date components:", allChanges?.Dates?.[0]);
    toast.error("שגיאה בנתוני תאריך");
    return false;
  }

  // Extract date from sendObj.Date if available, otherwise use YM + DOM
  let date;
  if (sendObj.Date) {
    // Use the date from sendObj
    date = sendObj.Date;
    console.log("Using date from sendObj:", date);
  } else {
    // Fallback to YM + DOM
    date = allChanges.Dates[0].YM + allChanges.Dates[0].DOM;
    console.log("Using date from YM + DOM:", date);
  }

  // Validate the date format
  if (!/^\d{8}$/.test(date)) {
    console.error("Invalid date format:", date);
    toast.error("שגיאה בפורמט התאריך");
    return false;
  }

  const [year, month, day] = [
    date.substring(0, 4),
    date.substring(4, 6),
    date.substring(6, 8),
  ];

  const baseDate = `${year}-${month}-${day}`;
  console.log("Processing date:", { date, baseDate, originalDate: sendObj.Date });

  let Times = [];

  // Validate and process times array
  if (Array.isArray(allChanges.Dates[0].Times)) {
    console.log("Initial times array:", allChanges.Dates[0].Times);

    // Process all times using reduce
    Times = allChanges.Dates[0].Times.reduce((acc, item) => {
      if (!item || !item.id) {
        console.log("Skipping invalid item:", item);
        return acc;
      }

      try {
        // Parse and validate input times
        const timeF = new Date(item.TimeF);
        const timeT = new Date(item.TimeT);

        if (isNaN(timeF.getTime()) || isNaN(timeT.getTime())) {
          console.log("Invalid time values:", { timeF, timeT });
          return acc;
        }

        // Create normalized times on the base date
        const normalizedTimeF = new Date(
          `${baseDate}T${timeF.getHours().toString().padStart(2, "0")}:${timeF
            .getMinutes()
            .toString()
            .padStart(2, "0")}:00`
        );
        const normalizedTimeT = new Date(
          `${baseDate}T${timeT.getHours().toString().padStart(2, "0")}:${timeT
            .getMinutes()
            .toString()
            .padStart(2, "0")}:00`
        );

        // Validate time range
        if (normalizedTimeT <= normalizedTimeF) {
          console.log("Invalid time range:", {
            normalizedTimeF,
            normalizedTimeT,
          });
          return acc;
        }

        // Check for overlap with existing entries
        const hasOverlap = acc.some((existingTime) => {
          const existingStart = new Date(existingTime.TimeF);
          const existingEnd = new Date(existingTime.TimeT);
          return (
            normalizedTimeF < existingEnd && normalizedTimeT > existingStart
          );
        });

        if (hasOverlap) {
          console.log("Overlapping time entry detected:", {
            normalizedTimeF,
            normalizedTimeT,
          });
          return acc;
        }

        // Create the time entry
        const timeEntry = {
          TimeF: normalizedTimeF.toISOString(),
          TimeT: normalizedTimeT.toISOString(),
          Typ: item.Typ || "1",
          MoreInfo: item.MoreInfo || "",
        };

        console.log("Adding valid time entry:", timeEntry);
        return [...acc, timeEntry];
      } catch (error) {
        console.error("Error processing time entry:", error);
        return acc;
      }
    }, []);

    // Sort final times array chronologically
    Times.sort((a, b) => new Date(a.TimeF) - new Date(b.TimeF));

    console.log("Final processed times:", {
      originalCount: allChanges.Dates[0].Times.length,
      processedCount: Times.length,
      times: Times,
    });
  }

  const updateObj = {
    SessionKey: sendObj.SessionKey,
    idno: sendObj.IDNumber,
    Date: date,
    Times: Times,
    Reason: allChanges?.Dates[0]?.Reson || "",
  };

  // Validate the final object
  if (Times.length === 0 && !updateObj.Reason) {
    console.error("No valid times or reason provided");
    toast.error("נדרש להזין זמנים או סיבת היעדרות");
    return false;
  }

  console.log("Final update object:", updateObj);
  return updateObj;
}

// Helper function to validate time entries before sending to API
function validateTimeEntries(times) {
  if (!Array.isArray(times) || times.length === 0) return false;

  return times.every((time) => {
    if (!time.TimeF || !time.TimeT) return false;

    const startTime = new Date(time.TimeF);
    const endTime = new Date(time.TimeT);

    // Basic validation checks
    if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) return false;
    if (endTime <= startTime) return false;

    return true;
  });
}

// Function to format times correctly for the API
function formatTimeForAPI(timeStr, baseDate) {
  try {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const dateTime = new Date(baseDate);
    dateTime.setHours(hours, minutes, 0, 0);
    return dateTime.toISOString();
  } catch (error) {
    console.error("Error formatting time:", error);
    return null;
  }
}

export function getSummaryData(url, sendObj, setLoading, setResponseData) {
  setLoading(true);

  const json = JSON.stringify(sendObj);

  axios
    .post(
      API_BASE_URL + "/api/v2/Volunteer/summary",
      json,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((res) => {
      setLoading(false);
      if (!res?.data?.Result || res?.data?.Result !== "Success") {
        setResponseData(false);
        return toast.error("שגיאה");
      }

      if (res.data?.Years) {
        res.data.Years = res.data.Years.reverse();
      }

      setResponseData(res.data);
    })
    .catch((error) => {
      setLoading(false);
      setResponseData({});
      sendErrorLog(error, "getSummaryData Failed", {
        url: API_BASE_URL + "/api/v2/Volunteer/summary",
        method: "POST",
        payload: sendObj,
        });
      toast.error("שגיאה", {
        toastId: "error",
      });
    });
}

export function confirmMonth(url, sendObj, setLoading, loadMonthData) {
  setLoading(true);

  const json = JSON.stringify(sendObj);

  axios
    .post(API_BASE_URL + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      setLoading(false);
      console.log("responseConfirm", res);

      // Check for business errors first
      if (res?.data?.IsBusinessError !== undefined) {
        if (res?.data?.IsBusinessError === true) {
          // Business error - show the specific error message
          return toast.error(res.data.ErrorMessage || "שגיאת משתמש");
        } else if (res?.data?.ErrorMessage) {
          // System error - show generic message
          return toast.error("שגיאת מערכת");
        }
      }

      if (!res?.data?.Result || res?.data?.Result !== "Success") {
        if (res?.data?.ErrorMessage === "Incomplete report.") {
          return toast.error("שגיאה, יש למלא את החוסרים");
        } else {
          return toast.error("שגיאה");
        }
      } else {
        toast.success("החודש אושר בהצלחה");
        loadMonthData();
      }
    })
    .catch((error) => {
      setLoading(false);
      sendErrorLog(error, "confirmMonth Failed", {
        url: API_BASE_URL + url,
        method: "POST",
        payload: sendObj,
      }); 

      // Check for business errors in the error response
      if (error.response?.data?.IsBusinessError === true && error.response?.data?.ErrorMessage) {
        toast.error(error.response.data.ErrorMessage, {
          toastId: "error",
        });
      } else {
        toast.error("שגיאת מערכת", {
          toastId: "error",
        });
      }
    });
}
