import React, { useState, useEffect, useRef } from "react";

/**
 * UploadFile - קומפוננט להעלאת קבצים למערכת
 * 
 * מאפשר העלאת קבצים מסוגים שונים (תמונות ו-PDF), עם אפשרות לצפייה מקדימה
 * במקרים מסוימים (כמו אישור מחלה) דורש בחירת תאריכים לפני העלאת הקובץ
 * מונע העלאת קבצים כאשר סטטוס המסמך הוא "Exist"
 * 
 * Props:
 * - formData: נתוני הטופס
 * - countDaysFirst: האם לדרוש בחירת תאריכים תחילה
 * - allowReupload: האם לאפשר העלאה מחדש
 * - refreshForms: פונקציה לרענון רשימת הטפסים אחרי העלאה/מחיקה
 */

import axios from "axios";
import { useDropzone } from "react-dropzone"; /* https://react-dropzone.js.org/ */
//import { toast } from 'react-toastify'

import { RestUrls } from "../../../Components/-Helpers-/config";

//import NoPic from './../../../img/sherut-leumi/pic.png';
import NoPic from "./../../../img/sherut-leumi/svg/files/pdfDOC.png?v=1";

import { Row, Col, ProgressBar, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { checkDatesAPI, sendFileFormToClientApi } from "./fileFunctions";

import loader from "../../../img/preLoader.gif";

import fileBgQuad from "../../../img/sherut-leumi/svg/files/fileBgQuad.png";
import x from "../../../img/sherut-leumi/svg/files/x.svg";
import infoModal from "../../../img/sherut-leumi/svg/files/infoModal.svg";
import trash from "../../../img/sherut-leumi/svg/files/trash.svg";
import success from "../../../img/sherut-leumi/svg/files/success.svg";

import face from "../../../img/sherut-leumi/svg/files/formsPic/face.jpg";
import tz from "../../../img/sherut-leumi/svg/files/formsPic/tz.jpg";
import sign from "../../../img/sherut-leumi/svg/files/formsPic/sign.jpg";

import { Grid } from "@material-ui/core";

export default function UploadFile({
    formData,
    texts,
    userJ,
    allowReupload,
    closeSection,
    noHistoryForm,
    countDaysFirst,
    datesInput,
    datesOk,
    onCancel,
    onUploadSuccess,
    customTitle,
    processId,
    isReupload = false, // Add prop to indicate if this is a re-upload
    refreshForms, // חשוב! להעביר פונקציה שמרעננת את רשימת הטפסים אחרי העלאה/מחיקה
    ...props
}) {
    // Generate a local process ID if none was provided
    const localProcessId = processId || Date.now();
    
    console.log('UploadFile rendered with processId:', localProcessId, { 
        formData, 
        countDaysFirst, 
        datesInput, 
        datesOk,
        userDataValid: userJ && userJ.SessionKey && userJ.IDNO ? 'Valid' : 'Invalid',
        isReupload
    });
    
    const { userJ: userJFromProps, formData: formDataFromProps, texts: textsFromProps, customTitle: customTitleFromProps, onCancel: onCancelFromProps, countDaysFirst: countDaysFirstFromProps } = props;
    const fileInputRef = useRef(null);
    const displayTitle = customTitle || texts?.title;
    const didMountRef = useRef(false); // For tracking if component mounted
    const filePickerOpened = useRef(false); // Track if file picker has been opened

    const [picUrl, setPicUrl] = useState(false);
    const [isPicture, setIsPicture] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);

    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showThanksModal, setShowThanksModal] = useState(false);

    const [finalSendFile, setFinalSendFile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileError, setFileError] = useState(null);
    const [uploadBtnEnabled, setUploadBtnEnabled] = useState(true);

    // Only for internal use when not provided via props
    const [internalDatesInput, setInternalDatesInput] = useState({
        StartDate: datesInput?.StartDate || "",
        EndDate: datesInput?.EndDate || "",
    });
    const [internalDatesOk, setInternalDatesOk] = useState(datesOk || false);

    // Use either the props or the internal state
    const effectiveDatesInput = datesInput || internalDatesInput;
    const effectiveDatesOk = datesOk || internalDatesOk;
    
    const [shouldOpenDatesModal, setShouldOpenDatesModal] = useState(false);

    const backgroundQuad = "url('" + fileBgQuad + "')";

    // CSS for pulse animation
    const pulseAnimation = `
      @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.1); }
        100% { transform: scale(1); }
      }
    `;

    // Log current state of dates and files
    useEffect(() => {
        if (localProcessId) {
            console.log('UploadFile state updated with processId:', localProcessId, {
                picUrl: picUrl ? 'Image loaded' : 'No image',
                isPicture,
                uploadProgress,
                showFirstModal,
                showThanksModal,
                finalSendFile: finalSendFile ? 'File ready' : 'No file',
                loading,
                shouldOpenDatesModal,
                effectiveDatesOk: effectiveDatesOk ? 'Dates validated' : 'Dates not validated',
                effectiveDatesInput
            });
        }
    }, [localProcessId, picUrl, isPicture, uploadProgress, showFirstModal, showThanksModal, finalSendFile, loading, shouldOpenDatesModal, effectiveDatesOk, effectiveDatesInput]);

    const clearData = () => {
        console.log('clearData called with processId:', localProcessId);
        setPicUrl(false);
        setIsPicture(false);
        setLoading(false);
        setFinalSendFile(false);
        setInternalDatesOk(false);
        setInternalDatesInput({ StartDate: "", EndDate: "" });
        setShouldOpenDatesModal(false);
        setShowThanksModal(false);
        setUploadProgress(null);
        setFileError(null);
        setUploadBtnEnabled(true);
        
        // רענון הרשימה כשמנקים את הנתונים
        if (refreshForms) {
            console.log("Refreshing forms list after clearing data");
            refreshForms();
        }
        
        // If onCancel is provided, call it to return to the status view
        if (onCancel) {
            console.log('Calling onCancel from clearData');
            onCancel();
        }
    };

    const onDrop = (acceptedFiles) => {
        console.log('onDrop called with processId:', localProcessId, {
            acceptedFiles: acceptedFiles ? acceptedFiles.length : 0,
            countDaysFirst
        });
        
        if (!acceptedFiles || acceptedFiles.length === 0) {
            return;
        }
        
        let file = acceptedFiles[0];
        let filename = (file?.name).toLowerCase();
        console.log('File dropped:', filename);

        // בדיקה חדשה: אם כבר יש לנו תאריכים (התאריכים נבחרו מראש),
        // שלח את הקובץ ישירות במקום לפתוח שוב את מודל התאריכים
        if (countDaysFirst && effectiveDatesInput?.StartDate && effectiveDatesOk?.ok) {
            console.log('Dates already selected, proceeding with file upload');
            // שמור את הקובץ
            setFinalSendFile(file);
            // בדוק שם קובץ תקין
            if (
                !filename.includes(".pdf") &&
                !(
                    filename.includes(".jpg") ||
                    filename.includes(".jpeg") ||
                    filename.includes(".png")
                )
            ) {
                toast.error("יש להעלות קבצים: " + texts?.filesType);
                clearData();
                return true;
            }
            
            // קבע אם זה תמונה או לא
            if (
                !filename.includes(".pdf") &&
                (filename.includes(".jpg") ||
                    filename.includes(".jpeg") ||
                    filename.includes(".png"))
            ) {
                setIsPicture(true);
            } else {
                setIsPicture(false);
            }
            
            // שמור את ה-URL של הקובץ לתצוגה מקדימה
            setPicUrl(URL.createObjectURL(file));
            
            // שלח את הקובץ ישירות עם הפרמטר file במקום להסתמך על finalSendFile
            uploadImage(file);
            sendMyFile(file);
            return;
        }
        
        // מקרה רגיל - אם אין תאריכים עדיין, נפתח את מודל התאריכים
        if (countDaysFirst) {
            console.log('countDaysFirst flow - setting shouldOpenDatesModal');
            setShowFirstModal(true);
            setShouldOpenDatesModal(true);
            // We'll save the file for later when the dates have been entered
            setFinalSendFile(file);
            return;
        }

        setPicUrl(URL.createObjectURL(file));
        setShowFirstModal(false);

        if (
            !filename.includes(".pdf") &&
            !(
                filename.includes(".jpg") ||
                filename.includes(".jpeg") ||
                filename.includes(".png")
            )
        ) {
            toast.error("יש להעלות קבצים: " + texts?.filesType);
            clearData();
            return true;
        }

        if (
            !filename.includes(".pdf") &&
            (filename.includes(".jpg") ||
                filename.includes(".jpeg") ||
                filename.includes(".png"))
        ) {
            setIsPicture(true);
        } else {
            setIsPicture(false);
        }

        uploadImage(file);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noKeyboard: true,
        multiple: false,
        onDrop,
        noClick: false,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
            'application/pdf': ['.pdf']
        }
    });

    // Use effect to open file picker after date selection
    useEffect(() => {
        // Only run if we're supposed to select dates first, dates are selected, and no file is picked yet
        // Don't open if we're showing thanks modal, loading, or after a successful upload
        // Also don't open if file picker has already been opened
        if (countDaysFirst && datesOk?.ok && !picUrl && !showThanksModal && !loading && !finalSendFile && !filePickerOpened.current) {
            console.log('Preparing to open file picker after date selection - processId:', localProcessId);
            // Add a small delay to allow the component to fully render
            const timer = setTimeout(() => {
                // Final check before opening
                if (!showThanksModal && !loading && !finalSendFile && !filePickerOpened.current) {
                    console.log('File picker dialog opening after date selection - processId:', localProcessId);
                    // Try using our custom function that has fallbacks
                    filePickerOpened.current = true;
                    openFilePicker();
                } else {
                    console.log('Skipping file picker open - states changed - processId:', localProcessId);
                }
            }, 800);
            
            return () => clearTimeout(timer);
        }
    }, [countDaysFirst, datesOk?.ok, picUrl, showThanksModal]);

    // Use effect to open file picker or modal on first mount
    useEffect(() => {
        // רק לוגים ואתחול, בלי פתיחה אוטומטית של המודל
        if (!didMountRef.current) {
            console.log('Component first mount - processId:', localProcessId, 'formData:', formData);
            didMountRef.current = true;
            
            // לא עושים שום דבר אוטומטי - המשתמש צריך ללחוץ על כפתור העלאת קובץ בעצמו
            // אין יותר פתיחה אוטומטית של מודלים
        }
    }, []);

    // Reset filePickerOpened when component unmounts or when thanksModal status changes
    useEffect(() => {
        if (showThanksModal) {
            // Reset the file picker flag when the thanks modal is shown
            // This allows a new file picker to be opened if the user closes the modal
            // and tries again without a full component unmount
            filePickerOpened.current = false;
        }
        
        return () => {
            console.log('UploadFile cleanup with processId:', localProcessId);
            filePickerOpened.current = false;
        };
    }, [showThanksModal, localProcessId]);

    // Consolidated file picker function with fallbacks
    const openFilePicker = () => {
        // גם אפשר ללחוץ על הכפתור וגם פותח אוטומטית
        console.log('Attempting to open file picker dialog - processId:', localProcessId);
        
        // Check for conditions that would prevent opening
        if (showThanksModal || loading || finalSendFile || picUrl) {
            console.log('Skipping file picker open due to state - processId:', localProcessId, {
                showThanksModal, loading, finalSendFile, picUrl
            });
            return;
        }
        
        try {
            // Method 1: Use direct file input reference
            if (fileInputRef.current) {
                console.log('Using direct file input click - processId:', localProcessId);
                fileInputRef.current.click();
            } 
            // Method 2: Use dropzone open method
            else if (open) {
                console.log('Using dropzone open method - processId:', localProcessId);
                open();
            }
            // Method 3: Create a temporary input element as fallback
            else {
                console.log('Using fallback temporary input - processId:', localProcessId);
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/jpeg,image/png,application/pdf';
                input.onchange = (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        onDrop([e.target.files[0]]);
                    }
                };
                input.click();
            }
        } catch (err) {
            console.error('Error opening file picker:', err);
        }
    };

    useEffect(() => {
        console.log('UploadFile mounted with processId:', localProcessId);
        return () => {
            console.log('UploadFile unmounted with processId:', localProcessId);
        };
    }, [localProcessId]);

    const uploadImage = (file) => {
        setFinalSendFile(file);
    };
    
    const sendMyFile = (directFile = null) => {
        console.log('sendMyFile called with processId:', localProcessId, {
            hasDirectFile: !!directFile,
            hasFinalSendFile: !!finalSendFile
        });
        
        // Use the file upload handler with either the direct file or the one from state
        fileUploadHandler(directFile || finalSendFile);
    };

    // For uploading files via API
    const fileUploadHandler = (file) => {
        console.log('fileUploadHandler called with processId:', localProcessId, {
            countDaysFirst,
            hasDatesInput: effectiveDatesInput?.StartDate,
            hasFinalSendFile: !!finalSendFile
        });
        
        // Prevent multiple uploads
        if (loading) {
            console.log('Upload already in progress, ignoring duplicate request');
            return;
        }
        
        // Validate required form data
        if (!formData || !formData.FormID) {
            console.error('Missing required form data', {formData});
            toast.error('שגיאה: מידע הטופס חסר, אנא רענן את הדף ונסה שוב');
            return;
        }
        
        // Validate user session data
        if (!userJ || !userJ.SessionKey || !userJ.IDNO) {
            console.error('Missing user session data', {userJ: userJ ? 'Exists but incomplete' : 'Missing'});
            toast.error('שגיאה: מידע המשתמש חסר, אנא התחבר מחדש ונסה שוב');
            return;
        }
        
        // Disable the upload button to prevent multiple clicks
        setUploadBtnEnabled(false);
        
        // Create the standard upload data object for all uploads
        const sendData = {
            file: finalSendFile || file,
            SessionKey: userJ.SessionKey,
            IDNumber: userJ.IDNO,
            formId: formData.FormID,
        };
        
        // Add date information if available
        if (countDaysFirst && effectiveDatesOk?.dates) {
            sendData.dates = effectiveDatesOk.dates;
        }
        
        // Always use the sendFileFormToClientApi function which handles the API correctly
        sendFileFormToClientApi(
            sendData, 
            setLoading, 
            clearData, 
            setShowThanksModal,
            (data) => {
                // If we have a success callback from parent, call it
                if (onUploadSuccess) {
                    console.log("Upload succeeded, calling parent onUploadSuccess");
                    // Reset the file picker flag before calling onUploadSuccess
                    filePickerOpened.current = false;
                    onUploadSuccess(data);
                }
                
                // רענון הרשימה לאחר העלאת קובץ בהצלחה
                // זה קריטי במיוחד עבור אישור מחלה שצריך לראות סטטוס עדכני
                if (refreshForms) {
                    console.log("Refreshing forms list after successful upload");
                    setTimeout(() => {
                        refreshForms();
                    }, 1000); // מתן זמן לשרת לעדכן את הנתונים
                }
            }
        );
    };

    // שיפור של openMyModal כדי שתטפל בכל המקרים 
    const openMyModal = () => {
        console.log('openMyModal called - formData:', formData?.FormID, 'Msg:', !!formData?.Msg, 'countDaysFirst:', countDaysFirst);
        
        // תמיד פתח מודל אם יש הודעה או צורך בתאריכים
        if (formData?.Msg || countDaysFirst) {
            console.log('Showing modal - has message or needs dates');
            setShowFirstModal(true);
            
            // אם צריך תאריכים, הגדר זאת
            if (countDaysFirst) {
                setShouldOpenDatesModal(true);
            }
            
            return;
        }
        
        // אין הודעה ואין צורך בתאריכים - פתח ישירות בוחר קבצים
        console.log('No message and no date needed - opening file picker directly');
        openFilePicker();
    };

    const checkDates = () => {
        console.log('checkDates in UploadFile called with processId:', localProcessId, {
            effectiveDatesInput
        });
        checkDatesAPI(
            effectiveDatesInput, 
            (result) => {
                console.log('checkDatesAPI result in UploadFile:', result);
                setInternalDatesOk(result);
            }, 
            (loadingState) => {
                console.log('Loading state in UploadFile changed to:', loadingState);
                setLoading(loadingState);
            }
        );
    };

    // עידכון ופישוט של handleOpenFilePicker
    const handleOpenFilePicker = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        
        // אם צריך להציג מודל קודם ועוד לא מוצג - צריך לעצור ולהציג מודל
        if (!showFirstModal && (formData?.Msg || countDaysFirst)) {
            console.log('Need to show modal first before file picker');
            openMyModal(); // openMyModal יטפל בכל המקרים
            return;
        }
        
        // לא לפתוח אם כבר העלינו קובץ או בתהליך טעינה
        if (finalSendFile || loading || showThanksModal || picUrl) {
            console.log('Not opening file picker because: ', { 
                hasFinalFile: !!finalSendFile, 
                loading, 
                showThanksModal,
                hasPicUrl: !!picUrl 
            });
            return;
        }
        
        try {
            console.log('Opening file picker dialog');
            // Method 1: Use direct file input reference
            if (fileInputRef.current) {
                console.log('Using direct file input click');
                fileInputRef.current.click();
            } 
            // Method 2: Use dropzone open method
            else if (open) {
                console.log('Using dropzone open method');
                open();
            }
            // Method 3: Create a temporary input element as fallback
            else {
                console.log('Using fallback temporary input');
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/jpeg,image/png,application/pdf';
                input.onchange = (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        onDrop([e.target.files[0]]);
                    }
                };
                input.click();
            }
        } catch (err) {
            console.error('Error opening file picker:', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('UploadFile handleSubmit called with processId:', localProcessId);
        sendMyFile();
    }
    
    const handleCancel = () => {
        console.log('UploadFile cancel called with processId:', localProcessId);
        if (onCancel) {
            onCancel();
        }
    }

    // Handle closing the thanks modal
    const handleCloseModal = () => {
        console.log('Closing thanks modal and clearing data - processId:', localProcessId);
        // First hide the modal
        setShowThanksModal(false);
        
        // Reset the file picker flag
        filePickerOpened.current = false;
        
        // רענון הרשימה בכל מקרה של סגירת מודל תודה
        if (refreshForms) {
            console.log("Refreshing forms list after closing thanks modal");
            refreshForms();
        }
        
        // Call onUploadSuccess callback if provided
        if (onUploadSuccess) {
            console.log('Calling onUploadSuccess callback - processId:', localProcessId);
            // Add a short delay to ensure the modal is hidden first
            setTimeout(() => {
                onUploadSuccess();
            }, 100);
        } else {
            // Then clear all data and return to status view
            clearData();
        }
    };

    // Check if upload should be disabled based on document status
    const isUploadDisabled = (formId, status) => {
        // רק ת"ז חסומה להעלאה במצב Exist
        return formId === 1 && status === "Exist";
    };

    const handleUpload = (formId, status) => {
        if (isUploadDisabled(formId, status)) {
            return;
        }
        
        if (formId === 10) { // אישור מחלה
            // תמיד פותח את הדיאלוג של אישור מחלה
            setShowFirstModal(true);
            setShouldOpenDatesModal(true);
        } else {
            // לוגיקה רגילה להעלאת קבצים
            openFilePicker();
        }
    };

    return (
        <>
            {/* Add animation styles */}
            <style>{pulseAnimation}</style>
            
            <Row className="fileCont">
                <img
                    src={loader}
                    alt="loader"
                    className={
                        !loading
                            ? "loader"
                            : "loader active animate__animated animate__fadeIn"
                    }
                />

                <Col md="12">
                    {!picUrl ? (
                        <div
                            className="blueQuad"
                            style={{ backgroundImage: backgroundQuad }}
                        >
                            <div className="docNoExist">
                                <img src={x} alt="לא הועלה קובץ" />
                                <p>{isReupload ? "העלאת קובץ מעודכן" : "המסמך לא קיים"}</p>
                                <div {...getRootProps({
                                    onClick: (e) => e.stopPropagation() // Prevent extra clicks
                                })}>
                                    <input
                                        {...getInputProps()}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />
                                    <button
                                        className="blueBtn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (!isUploadDisabled(formData?.FormID, formData?.Status)) {
                                                openMyModal(); // פונקציה אחת שמטפלת בכל המקרים
                                            }
                                        }}
                                        disabled={isUploadDisabled(formData?.FormID, formData?.Status)}
                                        style={isUploadDisabled(formData?.FormID, formData?.Status) ? { 
                                            opacity: 0.6, 
                                            cursor: 'not-allowed' 
                                        } : {}}
                                    >
                                        {isReupload ? "העלאת קובץ חדש" : "העלאת קובץ"}
                                    </button>
                                    {isUploadDisabled(formData?.FormID, formData?.Status) && (
                                        <p style={{
                                            color: '#666',
                                            fontSize: '12px',
                                            marginTop: '5px',
                                            textAlign: 'center'
                                        }}>
                                            המסמך כבר אושר, לא ניתן להעלות קובץ חדש
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="picturePreview">
                            <img
                                className={`noDrag ${!picUrl && "noPic"}`}
                                src={isPicture ? picUrl : NoPic}
                                alt={displayTitle}
                            />

                            <Grid
                                className="btnCont"
                                container
                                spacing={0}
                                alignItems="center"
                                direction="row"
                                justifyContent="center"
                            >
                                <Grid item md={3}>
                                    <img src={trash} alt="delete" onClick={clearData} />
                                </Grid>
                                <Grid item md={8}>
                                    <button
                                        className="greenBtn animate__animated animate__bounceIn"
                                        onClick={sendMyFile}
                                        style={{
                                            fontWeight: countDaysFirst ? 'bold' : undefined
                                        }}
                                    >
                                        {countDaysFirst ? "שלח מסמך לאישור" : "שליחת קובץ"}
                                    </button>
                                    {countDaysFirst && (
                                        <p style={{ 
                                            textAlign: 'center', 
                                            marginTop: '5px', 
                                            color: '#6c757d',
                                            fontSize: '12px' 
                                        }}>
                                            השלב האחרון - שליחה סופית
                                        </p>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Col>
            </Row>

            {uploadProgress && <ProgressBar now={uploadProgress} />}

            {!countDaysFirst && (
                <Modal
                    className={
                        "animate__animated animate__fadeInDown animate__faster modalSite filesModal"
                    }
                    animation={false}
                    onHide={() => setShowFirstModal(false)}
                    show={showFirstModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                    keyboard={true}
                    style={{ zIndex: 9999 }}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body>
                        <Button
                            className="closeBtn"
                            onClick={() => setShowFirstModal(false)}
                            variant="secondary"
                        >
                            X
                        </Button>

                        <div className="Message">
                            <img src={infoModal} alt={displayTitle} />
                            <h2>{displayTitle}</h2>
                            {formData?.Msg && (
                                <div className="formMsg" style={{ 
                                    backgroundColor: '#f8f9fa', 
                                    border: '1px solid #dee2e6',
                                    borderRadius: '4px', 
                                    padding: '10px', 
                                    margin: '10px 0',
                                    fontSize: '14px',
                                    textAlign: 'right',
                                    lineHeight: '1.5'
                                }}>
                                    <p>{formData.Msg}</p>
                                </div>
                            )}
                        </div>

                        <figure>
                            {formData?.FormID === "1" && (
                                <img src={tz} alt="" style={{ margin: "0 0 -20px 0" }} />
                            )}
                            {formData?.FormID === "4" && (
                                <img src={face} alt="" style={{ margin: "0" }} />
                            )}
                            {(formData?.FormID === "6" || formData?.FormID === "14") && (
                                <img src={sign} alt="" style={{ margin: "0" }} />
                            )}
                        </figure>

                        <Grid
                            className="btnCont"
                            container
                            spacing={2}
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                        >
                            <Grid item md={6}>
                                <div className="user-file" style={{ position: 'relative' }}>
                                    <input
                                        type="file"
                                        accept="image/jpeg,image/png,application/pdf"
                                        id="file-input-direct"
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                console.log('File selected from direct button input');
                                                setShowFirstModal(false);
                                                onDrop([e.target.files[0]]);
                                            }
                                        }}
                                        style={{ 
                                            opacity: 0,
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            cursor: 'pointer',
                                            left: 0,
                                            border: 'none',
                                            top: 0,
                                            zIndex: 5
                                        }}
                                    />
                                    <button
                                        className="blueBtn"
                                        style={{ 
                                            width: '100%', 
                                            border: 'none',
                                            position: 'relative',
                                            zIndex: 1
                                        }}
                                        type="button"
                                    >
                                        הבנתי ובחירת קובץ
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
            )}

            {/* Date counting modal for documents that need date range */}
            {countDaysFirst && (
                <Modal
                    className={
                        "animate__animated animate__fadeInDown animate__faster modalSite filesModal"
                    }
                    animation={false}
                    onHide={() => setShowFirstModal(false)}
                    show={showFirstModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                    keyboard={true}
                    style={{ zIndex: 9999 }}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body>
                        <Button
                            className="closeBtn"
                            onClick={() => setShowFirstModal(false)}
                            variant="secondary"
                        >
                            X
                        </Button>

                        <div className="Message">
                            <img src={infoModal} alt={displayTitle} />
                            <h2>{displayTitle}</h2>
                            {formData?.Msg && (
                                <div className="formMsg" style={{ 
                                    backgroundColor: '#f8f9fa', 
                                    border: '1px solid #dee2e6',
                                    borderRadius: '4px', 
                                    padding: '10px', 
                                    margin: '10px 0',
                                    fontSize: '14px',
                                    textAlign: 'right',
                                    lineHeight: '1.5'
                                }}>
                                    <p>{formData.Msg}</p>
                                </div>
                            )}
                        </div>

                        <figure>
                            {formData?.FormID === "1" && (
                                <img src={tz} alt="" style={{ margin: "0 0 -20px 0" }} />
                            )}
                            {formData?.FormID === "4" && (
                                <img src={face} alt="" style={{ margin: "0" }} />
                            )}
                            {(formData?.FormID === "6" || formData?.FormID === "14") && (
                                <img src={sign} alt="" style={{ margin: "0" }} />
                            )}
                        </figure>

                        <Grid
                            className="btnCont"
                            container
                            spacing={2}
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                        >
                            <Grid item md={6}>
                                <div className="user-file" style={{ position: 'relative' }}>
                                    <input
                                        type="file"
                                        accept="image/jpeg,image/png,application/pdf"
                                        id="file-input-days-direct"
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                console.log('File selected from direct button input (days)');
                                                setShowFirstModal(false);
                                                onDrop([e.target.files[0]]);
                                            }
                                        }}
                                        style={{ 
                                            opacity: 0,
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            cursor: 'pointer',
                                            left: 0,
                                            top: 0,
                                            zIndex: 5
                                        }}
                                    />
                                    <button
                                        className="blueBtn"
                                        style={{ 
                                            width: '100%', 
                                            border: 'none',
                                            position: 'relative',
                                            zIndex: 1
                                        }}
                                        type="button"
                                    >
                                        הבנתי ובחירת קובץ
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
            )}

            {/* showThanksModal, setShowThanksModal */}
            <Modal
                className={
                    "animate__animated animate__fadeInDown animate__faster modalSite filesModal thanksFile"
                }
                animation={false}
                onHide={handleCloseModal}
                show={showThanksModal}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={true}
                style={{ zIndex: 9999 }}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <Button
                        className="closeBtn"
                        onClick={handleCloseModal}
                        variant="secondary"
                    >
                        X
                    </Button>

                    <div className="Message">
                        <img src={success} alt={displayTitle} />
                        <h2>{texts?.thankText || 'המסמך נשלח בהצלחה'}</h2>
                        <p>{texts?.thankpText || 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'}</p>
                    </div>

                    <Grid
                        className="btnCont"
                        container
                        spacing={2}
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item md={6}>
                            <div
                                className="blueBtn"
                                onClick={handleCloseModal}
                            >
                                סגירה
                            </div>
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        </>
    );
}
