import React, { useState, useEffect } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import ShowFilters from "./ShowFilters";
import { getAllUrlParams } from "../../../Components/-Helpers-/UrlParameters";
const environment = process.env.REACT_APP_ENVIRONMENT;
const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
// Add cache helper functions
const CACHE_KEY = 'sayarotSearchParamsCache';
const CACHE_EXPIRY = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

const getFromCache = () => {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    const { data, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp > CACHE_EXPIRY) {
      console.log("unauthorized, removing userData 6");

      localStorage.removeItem(CACHE_KEY);
      return null;
    }
    return data;
  } catch (error) {
    console.error('Error reading from cache:', error);
    console.log("unauthorized, removing userData 7");
    
    localStorage.removeItem(CACHE_KEY);
    return null;
  }
};

const setToCache = (data) => {
  try {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  } catch (error) {
    console.error('Error writing to cache:', error);
  }
};

const SearchUp = ({ currentState, updateSearch }) => {
  const history = useHistory();
  const location = useLocation();

  // Initialize state from URL parameters
  const urlParams = getAllUrlParams(window.location.href);
  const [state, setState] = useState({
    searchDataResp: null,
    loading: false,
    freeSearch: urlParams.freetext || "",
    rakaz: urlParams.rakazid || "",
    city: urlParams.cityid || "",
    sayeretId: currentState.sayeretId || "",
    updateFilters: false
  });

  // Update URL with current search parameters
  const updateURL = (searchParams) => {
    const params = new URLSearchParams();
    if (searchParams.freeSearch) params.set('freetext', searchParams.freeSearch);
    if (searchParams.rakaz) params.set('rakazid', searchParams.rakaz);
    if (searchParams.city) params.set('cityid', searchParams.city);
    
    const newURL = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    history.push(newURL);
  };

  const handleChange = (event) => {
    const newState = { ...state, [event.target.name]: event.target.value };
    setState(newState);
  };

  useEffect(() => {
    console.log("currentState:", currentState);
    
    if (!state.searchDataResp || currentState.sayeretId) {
      sendtoAdmin(
        "getSayarotSearchParams",
        "sayarot",
        {},
        "searchDataResp",
        "sayarot"
      );
    }

    if (currentState.sayeretId) {
      setState(prev => ({
        ...prev,
        sayeretId: currentState.sayeretId,
        updateFilters: Date.now()
      }));
    }
  }, [currentState.sayeretId]);

  const sendtoAdmin = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    console.log("OBJECTTOSEND", objectToSend);
    setState(prev => ({ ...prev, loading: true }));

    try {
      // Check cache first for filters data
      const cachedData = getFromCache();
      if (cachedData) {
        console.log('Using cached filters data');
        setState(prev => ({ 
          ...prev, 
          [setStateName]: cachedData, 
          loading: false,
          ...(url === "getSayarotSearchParams" && cachedData?.rakazot && currentState.rakaz ? {
            rakaz: currentState.rakaz,
            sayeretId: currentState.sayeretId,
            updateFilters: Date.now()
          } : {})
        }));
        return;
      }

      // If no cache, fetch from API
      const response = await fetch(API_BASE_URL + "/api/v2/Data/getAllSayarot", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      });

      const getData = await response.json();
      
      // Transform the response to match the expected format for filters
      const transformedData = {
        rakazot: getData.Sayarot?.reduce((acc, sayara) => {
          if (sayara.Rak && !acc.find(r => r.Rak_Key === sayara.Rak.Key)) {
            acc.push({
              Rak_Key: sayara.Rak.Key,
              Rak_Value: sayara.Rak.Value
            });
          }
          return acc;
        }, []).sort((a, b) => a.Rak_Value.localeCompare(b.Rak_Value, 'he')) || [],
        cities: getData.Sayarot?.reduce((acc, sayara) => {
          if (sayara.City && !acc.find(c => c.City_Key === sayara.City.Key)) {
            acc.push({
              City_Key: sayara.City.Key,
              City_Value: sayara.City.Value
            });
          }
          return acc;
        }, []).sort((a, b) => a.City_Value.localeCompare(b.City_Value, 'he')) || []
      };

      // Cache the transformed filters data
      setToCache(transformedData);
      
      setState(prev => ({ 
        ...prev, 
        [setStateName]: transformedData, 
        loading: false,
        ...(url === "getSayarotSearchParams" && transformedData?.rakazot && currentState.rakaz ? {
          rakaz: currentState.rakaz,
          sayeretId: currentState.sayeretId,
          updateFilters: Date.now()
        } : {})
      }));
    } catch (error) {
      console.error("Error in sendtoAdmin:", error);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const sendForm = () => {
    const searchState = {
      freeSearch: state.freeSearch || "",
      rakaz: state.rakaz || "",
      city: state.city || "",
      sayeretId: state.sayeretId || ""
    };
    
    // Update URL with search parameters
    updateURL(searchState);
    
    // First update the local state
    setState(prev => ({ ...prev, updateFilters: Date.now() }));
    
    // Update parent component to trigger search with pagination
    updateSearch(searchState);
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    setState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const searchFilters = async (param) => {
    setState(prev => ({ 
      ...prev, 
      [param]: "",
      loading: true,
      updateFilters: Date.now()
    }));

    // Call API to reset results
    await sendtoAdmin(
      "getSayarotSearchParams",
      "sayarot",
      {}, 
      "searchDataResp",
      "sayarot"
    );
    
    sendForm();
  };

  const getRandomImage = () => {
    const randomNum = Math.floor(Math.random() * 20) + 1; // Random number between 1-20
    return `/randomPic/${randomNum}_converted.png`;
  };

  const resetSearch = async () => {
    const emptyState = {
      freeSearch: "",
      rakaz: "",
      city: "",
      sayeretId: ""
    };

    setState(prev => ({
      ...prev,
      ...emptyState,
      loading: true,
      updateFilters: Date.now()
    }));

    // Clear URL parameters by pushing just the pathname
    history.push(window.location.pathname);

    await sendtoAdmin(
      "getSayarotSearchParams",
      "sayarot",
      {}, 
      "searchDataResp",
      "sayarot"
    );

    updateSearch(emptyState);
  };

  const copySearchURL = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL).then(() => {
      // Show temporary success message
      const btn = document.getElementById('copyUrlBtn');
      if (btn) {
        const originalText = btn.innerText;
        btn.innerText = '✓ הועתק!';
        setTimeout(() => {
          btn.innerText = originalText;
        }, 2000);
      }
    }).catch(err => {
      console.error('Failed to copy URL:', err);
    });
  };

  const hasActiveFilters = state.freeSearch || state.city || state.rakaz || state.sayeretId;

  return (
    <div className="SearchAndResults">
      <div className="SearchUpCont GreyPanel">
        {state.searchDataResp && (
          <div>
            <div className="line">
              <Form.Group className="inputsWithLabel searchIco">
                <Form.Label>חיפוש חופשי</Form.Label>
                <FormControl
                  placeholder="שם הסיירת, תיאור"
                  className="inputsWithLabel"
                  onChange={handleChange}
                  name="freeSearch"
                  value={state.freeSearch}
                  type="text"
                />
              </Form.Group>
            </div>

            <div className="line seccondLine">
              <Form.Group className="inputsWithLabel selectIco2 rakazIco iconAfter2">
                <Form.Label>לפי רכזת</Form.Label>
                <FormControl
                  as="select"
                  className="inputsWithLabel selectIcoBG"
                  onChange={handleChangeSelect}
                  name="rakaz"
                  value={state.rakaz}
                >
                  <option value="">בחרו רכז/ת</option>
                  <option disabled>----</option>
                  {state.searchDataResp.rakazot?.sort((a, b) => a.Rak_Value.localeCompare(b.Rak_Value, 'he')).map((item) => (
                    <option value={item.Rak_Key} key={item.Rak_Key}>
                      {item.Rak_Value}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>

              <Form.Group className="inputsWithLabel selectIco2 placeIco iconAfter2">
                <Form.Label>לפי ישוב</Form.Label>
                <FormControl
                  as="select"
                  className="inputsWithLabel selectIcoBG"
                  onChange={handleChangeSelect}
                  name="city"
                  value={state.city}
                >
                  <option value="">בחרו ישוב</option>
                  <option disabled>----</option>
                  {state.searchDataResp.cities?.sort((a, b) => a.City_Value.localeCompare(b.City_Value, 'he')).map((item) => (
                    <option value={item.City_Key} key={item.City_Key}>
                      {item.City_Value}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>

              <Button
                disabled={!hasActiveFilters}
                className="sendBlueBtn withDisabled"
                onClick={sendForm}
                size="lg"
                variant={hasActiveFilters ? "success animate__animated animate__bounceIn" : "disabled"}
              >
                חיפוש
              </Button>

              {hasActiveFilters && (
                <>
                  <Button
                    className="resetBtn my-2 mx-2"
                    onClick={resetSearch}
                    size="lg"
                    variant="outline-secondary"
                  >
                    איפוס חיפוש ♻️
                  </Button>
                  <Button
                    id="copyUrlBtn"
                    className="copyUrlBtn my-2"
                    onClick={copySearchURL}
                    size="lg"
                    variant="outline-info"
                  >
                    העתק חתך 📋
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
        {state.loading && (
          <div className="text-center mt-3">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">טוען...</span>
            </div>
          </div>
        )}
      </div>

      {hasActiveFilters && state.updateFilters && (
        <ShowFilters
          updateFilters={state.updateFilters}
          searchData={state.searchDataResp}
          currentState={state}
          callBack={searchFilters}
        />
      )}
    </div>
  );
};

export default SearchUp;
