import validator from "validator";
//npm install validator

//https://github.com/validatorjs/validator.js
// Update mobile validation to handle various formats: 05X-XXXXXXX, 05XXXXXXXX, +972XXXXXXXXX, etc.
const validateMobile = (value) => {
  // First, normalize the phone number by removing all non-digit characters
  const digits = value.replace(/\D/g, '');
  
  // Israeli mobile numbers can be in the following formats:
  // 05X-XXXXXXX (10 digits with prefix 05X)
  // +972-5X-XXXXXXX (12 digits with prefix +9725X)
  
  // Check if it's a valid Israeli mobile number (starts with 05 and has 10 digits)
  if (/^05\d{8}$/.test(digits)) {
    return true;
  }
  
  // Check if it's an international format starting with +972
  if (/^9725\d{8}$/.test(digits)) {
    return true;
  }
  
  // Check for other formats like: 05X-XXX-XXXX
  if (/^05\d{1}[- ]?\d{3}[- ]?\d{4}$/.test(value)) {
    return true;
  }
  
  return false;
};

export function ValidateData(data, rules) {
    let errors = {};
    Object.keys(data).forEach(field => {
        if (rules.hasOwnProperty(field)) {
            let fielderrors = [];
            let val = data[field];

            // Convert null/undefined values to empty string to avoid validator errors
            val = val === null || val === undefined ? '' : String(val);

            if (rules[field].true && !val) {
                fielderrors.push("חייב לסמן");
            } else {
                if (rules[field].required && validator.isEmpty(val)) {
                    fielderrors.push("שדה חובה");
                }
                if (!validator.isEmpty(val)) {
                    if (rules[field].minlength && !validator.isLength(val, { min: rules[field].minlength })) {
                        fielderrors.push(`נא להזין לפחות ${rules[field].minlength} תווים`);
                    }
                    if (rules[field].maxlength && !validator.isLength(val, { max: rules[field].maxlength })) {
                        fielderrors.push(`נא להזין עד ${rules[field].maxlength} תווים`);
                    }
                    if (rules[field].alpha && !validator.isAlpha(val, 'he', { ignore: ' ' }) && !validator.isAlpha(val, 'en-US', { ignore: ' ' })) {
                        fielderrors.push("נא להזין רק אותיות");
                    }
                    if (rules[field].tz && !checkTz(val)) {
                        fielderrors.push("מספר תעודת זהות לא תקין");
                    }
                    if (rules[field].numbers && !validator.isNumeric(val)) {
                        fielderrors.push("נא להזין רק מספרים");
                    }
                    if (rules[field].alphanumeric && !validator.isAlphanumeric(val)) {
                        fielderrors.push("Enter only letters and Numbers");
                    }
                    if (rules[field].email && !validator.isEmail(val)) {
                        fielderrors.push("נא להזין כתובת מייל חוקית");
                    }
                    if (rules[field].phone && !validateMobile(val)) {
                        fielderrors.push("נא להזין טלפון חוקי");
                    }
                    if (rules[field].equals && !validator.equals(val, data[rules[field].equals])) {
                        fielderrors.push("הערכים אינם תואמים");
                    }
                }
            }
            if (fielderrors.length > 0) {
                errors[field] = fielderrors;
            }
        }
    });
    return errors;
}

function checkTz(str) {
    const IDnum = String(str).padStart(9, '0');
    if (IDnum.length !== 9 || isNaN(IDnum)) return false;

    let mone = 0;
    for (let i = 0; i < 9; i++) {
        let incNum = Number(IDnum.charAt(i)) * ((i % 2) + 1);
        if (incNum > 9) incNum -= 9;
        mone += incNum;
    }
    return mone % 10 === 0;
}