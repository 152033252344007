/**
 * SickLeaveForm - קומפוננט ייעודי לטיפול באישורי מחלה
 * 
 * מאפשר העלאת אישורי מחלה עם בחירת תאריכי התחלה וסיום
 * מחשב אוטומטית את מספר ימי המחלה בהתאם לתאריכים שנבחרו
 * כאשר סטטוס המסמך הוא "Exist", מציג הודעה ולא מאפשר העלאה חדשה
 * 
 * Props:
 * - formData: נתוני הטופס
 * - userJ: נתוני המשתמש
 */
import React, { useState, useEffect, useRef } from 'react';
import fileBgQuad from '../../../img/sherut-leumi/svg/files/fileBgQuad.png';
import v from '../../../img/sherut-leumi/svg/files/v.svg';
import x from '../../../img/sherut-leumi/svg/files/x.svg';
import UploadFile from './UploadFile';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import { checkDatesAPI, sendFileFormToClientApi } from './fileFunctions';
import infoModal from "../../../img/sherut-leumi/svg/files/infoModal.svg";
import loader from "../../../img/preLoader.gif";
import { Button as MuiButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function SickLeaveForm({
    formData,
    texts,
    userJ,
    title,
    ...props
}) {
    console.log('SickLeaveForm rendered', {formData, title});
    const [showDateModal, setShowDateModal] = useState(false);
    const [showUploadForm, setShowUploadForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [datesInput, setDatesInput] = useState({
        StartDate: "",
        EndDate: "",
    });
    const [datesOk, setDatesOk] = useState(false);
    const processIdRef = useRef(Date.now());
    const operationInProgressRef = useRef(false);
    
    const displayTitle = title || texts?.title || 'אישורי מחלה';
    const backgroundQuad = `url('${fileBgQuad}')`;

    // Reset state when component mounts
    useEffect(() => {
        console.log('SickLeaveForm mounting with ID:', processIdRef.current);
        
        return () => {
            console.log('SickLeaveForm cleanup with ID:', processIdRef.current);
            // Cleanup function to prevent memory leaks
            setShowDateModal(false);
            setShowUploadForm(false);
            setDatesOk(false);
            operationInProgressRef.current = false;
        };
    }, [formData.FormID]);

    // Log state changes
    useEffect(() => {
        console.log('State changed:', { 
            processId: processIdRef.current,
            showDateModal, 
            showUploadForm, 
            loading, 
            datesOk: datesOk ? 'Data available' : 'No data',
            datesInput,
            operationInProgress: operationInProgressRef.current
        });
    }, [showDateModal, showUploadForm, loading, datesOk, datesInput]);

    const handleUploadNewFile = () => {
        console.log('handleUploadNewFile called', { processId: processIdRef.current });
        
        if (operationInProgressRef.current || loading || showDateModal || showUploadForm) {
            console.log('Operation already in progress, ignoring duplicate request');
            return;
        }
        
        operationInProgressRef.current = true;
        
        processIdRef.current = Date.now();
        console.log('New process ID:', processIdRef.current);
        
        setDatesInput({
            StartDate: "",
            EndDate: "",
        });
        setDatesOk(false);
        setShowUploadForm(false);
        
        setTimeout(() => {
            setShowDateModal(true);
            operationInProgressRef.current = false;
        }, 50);
    };

    const checkDates = () => {
        console.log('checkDates called', { 
            processId: processIdRef.current,
            loading, 
            datesInput,
            operationInProgress: operationInProgressRef.current
        });
        
        if (loading || operationInProgressRef.current) {
            console.log('Skipping checkDates due to loading state or operation in progress');
            return;
        }
        
        operationInProgressRef.current = true;
        
        console.log('Calling checkDatesAPI');
        checkDatesAPI(
            datesInput, 
            (result) => {
                console.log('checkDatesAPI result:', result);
                setDatesOk(result);
                operationInProgressRef.current = false;
            }, 
            (loadingState) => {
                console.log('Loading state changed to:', loadingState);
                setLoading(loadingState);
            }
        );
    };

    const handleDateSelected = () => {
        console.log('handleDateSelected called', { 
            processId: processIdRef.current,
            datesOk: datesOk ? 'OK' : 'Not OK',
            operationInProgress: operationInProgressRef.current
        });
        
        if (!datesOk?.ok || loading || operationInProgressRef.current) {
            console.log('Skipping handleDateSelected as dates are not OK or operation in progress');
            return;
        }
        
        operationInProgressRef.current = true;
        
        console.log('Closing date modal');
        setShowDateModal(false);
        
        console.log('Setting timeout for showing upload form');
        setTimeout(() => {
            console.log('Timeout completed, showing upload form now');
            setShowUploadForm(true);
            operationInProgressRef.current = false;
        }, 300);
    };

    const handleCancelUpload = () => {
        console.log('handleCancelUpload called', { processId: processIdRef.current });
        
        if (operationInProgressRef.current) {
            console.log('Skipping handleCancelUpload as operation is in progress');
            return;
        }
        
        operationInProgressRef.current = true;
        
        setShowUploadForm(false);
        setDatesOk(false);
        setDatesInput({
            StartDate: "",
            EndDate: "",
        });
        
        setTimeout(() => {
            operationInProgressRef.current = false;
        }, 100);
    };

    // For handling successful upload 
    const handleUploadSuccess = () => {
        console.log('handleUploadSuccess called', { processId: processIdRef.current });
        
        // Don't check operation in progress here since we're already in a success flow
        // that needs to complete regardless of other operations
        
        // Reset any pending operation status to ensure we don't block this callback
        operationInProgressRef.current = false;
        
        // Make sure we reset upload form visibility first before refreshing
        setShowUploadForm(false);
        
        // Then give time for thank you modal to be seen before fully resetting
        setTimeout(() => {
            // Reset all form state
            setDatesOk(false);
            setDatesInput({
                StartDate: "",
                EndDate: "",
            });
            
            // Prevent double updates
            if (!operationInProgressRef.current) {
                operationInProgressRef.current = true;
                // Refresh the form data if needed
                if (props.refreshForms) {
                    props.refreshForms();
                }
                setTimeout(() => {
                    operationInProgressRef.current = false;
                }, 100);
            }
        }, 500);
    };

    // Get appropriate message based on status
    const getMessage = () => {
        if (formData.Status === "Pending") {
            return formData.Msg || texts?.pendingText || 'המסמך ממתין לאישור,]';
        } else if (formData.Status === "Exist") {
            return formData.Msg || texts?.existText || 'המסמך אושר, ניתן להעלות מסמך חדש במידת הצורך';
        } else {
            // מצב Missing
            return formData.Msg || texts?.startText || 'המסמך לא קיים';
        }
    };

    // Show appropriate content based on status and view state
    const renderContent = () => {
        console.log('renderContent called', { 
            processId: processIdRef.current,
            showUploadForm, 
            datesOk: datesOk?.ok, 
            formDataStatus: formData.Status,
            operationInProgress: operationInProgressRef.current
        });
        
        // If showing upload form (after date selection)
        if (showUploadForm && datesOk?.ok) {
            console.log('Rendering UploadFile component');
            return (
                <UploadFile 
                    {...props}
                    userJ={userJ}
                    formData={formData}
                    texts={texts}
                    customTitle={displayTitle}
                    countDaysFirst={true}
                    datesInput={datesInput}
                    datesOk={datesOk}
                    onCancel={handleCancelUpload}
                    onUploadSuccess={handleUploadSuccess}
                    processId={processIdRef.current}
                    key={`upload-${processIdRef.current}`}
                    refreshForms={props.refreshForms}
                />
            );
        }

        // Show status (Missing, Pending, or Exist)
        if (formData.Status === "Missing") {
            console.log('Rendering Missing state');
            return (
                <div className="blueQuad" style={{ backgroundImage: backgroundQuad }}>
                    <div className="docNoExist">
                        <img src={x} alt="המסמך לא קיים" />
                        <p>{getMessage()}</p>
                        <button 
                            className="blueBtn" 
                            onClick={handleUploadNewFile}
                            disabled={operationInProgressRef.current || loading || showUploadForm}
                        >
                            העלאת קובץ
                        </button>
                    </div>
                </div>
            );
        } else if (formData.Status === "Pending") {
            console.log('Rendering Pending state');
            return (
                <div className='blueQuad docPending' style={{ backgroundImage: backgroundQuad, display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={v} alt='המסמך הועלה וממתין לאישור' />
                    <p>{getMessage()}</p>
                    <Button
                        onClick={handleUploadNewFile}
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        disabled={operationInProgressRef.current || loading || showUploadForm}
                        style={{ margin: '5px auto 0', display: 'block' }}
                    >
                        העלה קובץ חדש
                    </Button>
                </div>
            );
        } else { // Exist status
            console.log('Rendering Exist state');
            return (
                <div className='blueQuad docExist' style={{ backgroundImage: backgroundQuad }}>
                    <img src={v} alt='המסמך קיים במערכת' />
                    <p>{getMessage()}</p>
                    <Button
                        onClick={handleUploadNewFile}
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        disabled={operationInProgressRef.current || loading || showUploadForm}
                        style={{ margin: '5px auto 0', display: 'block' }}
                    >
                        העלה קובץ חדש
                    </Button>
                </div>
            );
        }
    };

    const calculateDays = () => {
        if (!datesInput.StartDate || !datesInput.EndDate) return 0;
        const start = new Date(datesInput.StartDate);
        const end = new Date(datesInput.EndDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates
        return diffDays;
    };

    const handleSubmit = async () => {
        if (datesInput.StartDate && datesInput.EndDate && formData.file) {
            const formData = new FormData();
            formData.append('file', formData.file);
            formData.append('startDate', datesInput.StartDate);
            formData.append('endDate', datesInput.EndDate);
            formData.append('daysCount', calculateDays());
            
            // תמיד מעביר לסטטוס Pending בהעלאה חדשה
            await sendFileFormToClientApi(formData);
            setDatesOk({ ok: true, dates: { SikDays: calculateDays() } });
        }
    };

    return (
        <div className='fileItem'>
            <div className='header'>
                <h3>{displayTitle}</h3>
                <p>{texts?.filesType || '(jpg,png,pdf)'}</p>
            </div>

            {renderContent()}

            {/* Date selection modal */}
            <Modal
                className="animate__animated animate__fadeInDown animate__faster modalSite filesModal ishurMahalaModal"
                animation={false}
                onHide={() => {
                    console.log('Modal onHide triggered', { loading, operationInProgress: operationInProgressRef.current });
                    if (!loading && !operationInProgressRef.current) setShowDateModal(false);
                }}
                show={showDateModal}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={!loading && !operationInProgressRef.current}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <Button
                        className="closeBtn"
                        onClick={() => {
                            console.log('Close button clicked', { loading, operationInProgress: operationInProgressRef.current });
                            if (!loading && !operationInProgressRef.current) setShowDateModal(false);
                        }}
                        variant="secondary"
                        disabled={loading || operationInProgressRef.current}
                    >
                        X
                    </Button>

                    <div className="Message">
                        <img src={infoModal} alt={displayTitle} />
                        <h2>{displayTitle}</h2>
                    </div>

                    {!datesOk?.ok && (
                        <div className="inputs">
                            <Row
                                className="btnCont"
                                spacing={2}
                            >
                                <Col md={6}>
                                    <h4>תאריך התחלה</h4>
                                    <Form.Control
                                        className="customFloatInput"
                                        type="date"
                                        placeholder={""}
                                        onChange={(e) => {
                                            console.log('Start date changed:', e.target.value);
                                            setDatesInput({
                                                ...datesInput,
                                                StartDate: e.target.value,
                                            });
                                        }}
                                        value={datesInput.StartDate}
                                        disabled={loading || operationInProgressRef.current}
                                    />
                                </Col>

                                <Col md={6}>
                                    <h4>תאריך סיום</h4>
                                    <Form.Control
                                        className="customFloatInput"
                                        type="date"
                                        placeholder={""}
                                        onChange={(e) => {
                                            console.log('End date changed:', e.target.value);
                                            setDatesInput({
                                                ...datesInput,
                                                EndDate: e.target.value,
                                            });
                                        }}
                                        value={datesInput.EndDate}
                                        disabled={loading || operationInProgressRef.current}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}

                    <img
                        src={loader}
                        alt="loader"
                        className={
                            !loading
                                ? "loader"
                                : "loader active animate__animated animate__fadeIn"
                        }
                    />

                    <Row
                        className="btnCont"
                        spacing={2}
                    >
                        {datesOk?.error && (
                            <Col md={12}>
                                <p className="errorDates">{datesOk.error}</p>
                            </Col>
                        )}

                        {datesOk?.ok && (
                            <Col md={12}>
                                {datesOk?.warning && (
                                    <p className="errorDates warningDates">{datesOk.warning}</p>
                                )}

                                <p className="errorDates okDates">
                                    מספר ימים באישור הוא: {datesOk.dates.SikDays}
                                    <span
                                        onClick={() => {
                                            console.log('Reset dates clicked');
                                            if (!loading && !operationInProgressRef.current) setDatesOk(false);
                                        }}
                                    >
                                        &laquo; לבחירה חוזרת
                                    </span>
                                </p>
                            </Col>
                        )}

                        <Col md={6} className="text-center">
                            {datesInput.StartDate && datesInput.EndDate && datesOk?.ok ? (
                                <div>
                                    <button
                                        onClick={handleDateSelected}
                                        className="blueBtn"
                                        disabled={loading || !datesOk?.ok || operationInProgressRef.current}
                                    >
                                        אישור והמשך &raquo;
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <button
                                        onClick={checkDates}
                                        disabled={loading || !datesInput.StartDate || !datesInput.EndDate || operationInProgressRef.current}
                                        className={`blueBtn ${
                                            loading || !datesInput.StartDate || !datesInput.EndDate || operationInProgressRef.current
                                                ? "disabled"
                                                : ""
                                        }`}
                                    >
                                        המשך &raquo;
                                    </button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
} 