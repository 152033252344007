import React, { useState, useEffect } from "react";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import { RestUrls } from "../../Components/-Helpers-/config";
import { animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import loader from "../../img/preLoader.gif";
import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";
import { getAllUrlParams } from "./../../Components/-Helpers-/UrlParameters";
import { toast } from "react-toastify";

const Login = ({ siteInfo }) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
  const [state, setState] = useState({
    IDNO: "",
    Password: "",
    loading: false,
    responseLogin: false,
    checkInputs: false,
    checkInputsPage: false,
    checkRadio1: false,
    checkRadio2: false,
    btnSendClass: "success"
  });

  const updateValue = (newValue) => {
    setState(prev => ({ ...prev, ...newValue }));
  };

  const sendForm = (sendRules) => {
    setState(prev => ({
      ...prev,
      checkInputs: true,
      checkInputsPage: true,
      checkRadio1: true,
      checkRadio2: true
    }));

    if (sendRules) {
      setState(prev => ({ ...prev, loading: true }));

      const sendObj = { ...state };
      delete sendObj.responseLogin;
      // use env variable
      fetch(`${API_BASE_URL}/api/v2/Volunteer/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendObj),
      })
        .then((response) => response.json())
        .then((getData) => {
          setState(prev => ({ ...prev, responseLogin: getData, loading: false }));
          
          console.log("API Response:", getData);
          console.log("IDNO from response:", getData.IDNO);

          if (getData.Result === "Success") {
            // Store user data exactly as needed in the specified format
            const userData = {
              IDNO: getData.IDNO || state.IDNO || state.idno || state.userId || "",
              Category: getData.Category,
              FirstName: getData.FirstName,
              LastName: getData.LastName,
              Sex: getData.Sex,
              InService: getData.InService,
              AttendanceReportOnline: getData.AttendanceReportOnline || false,
              SessionKey: getData.SessionKey,
              ImageUrl: getData.ImageUrl || null,
              Sayarot: getData.Sayarot || []
            };
            
            // Store the userData object
            localStorage.setItem("userData", JSON.stringify(userData));
            console.log(userData, "userData stored");
            
            // Log what's actually in localStorage after storing
            console.log("Stored in localStorage:", JSON.parse(localStorage.getItem("userData")));
            
            // Store session key separately for components that expect it
            localStorage.setItem("sessionKey", getData.SessionKey);
            
            if (getData.sayeretId || getData.Sayarot?.length > 0) {
              localStorage.setItem("sayeretid", getData.sayeretId || (getData.Sayarot?.length > 0 ? getData.Sayarot[0] : ""));
            }

            const { InService, Category, FirstName, LastName, IDNO, Sex } = getData;

            if (window.ReactNativeWebView !== undefined) {
              try {
                fetch(
                  "https://webhooks.wdev.co.il/api/aguda-logs",
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      InService,
                      Category,
                      FirstName,
                      LastName,
                      IDNO,
                      Sex,
                    }),
                  }
                )
                  .then(newLog => console.log(newLog, "new log"))
                  .catch(error => console.error("Error logging to webhook:", error));
              } catch (error) {
                console.error("Error logging to webhook:", error);
              }
            }

            if (InService === false && Category !== "2") {
              window.open("/userConsole/data", "_self");
            } else if (InService === false && Category === "2") {
              window.open("/userConsole/data", "_self");
            } else if (InService === true) {
              window.open("/userConsole/clockInOut", "_self");
            } else {
              window.open("/userConsole/data", "_self");
            }
          } else if (getData.IsBusinessError !== undefined) {
            if (getData.IsBusinessError === true) {
              // Business error - show the specific error message
              toast.error(getData.ErrorMessage || "שגיאת משתמש");
            } else if (getData.ErrorMessage) {
              // System error - show generic message
              toast.error("שגיאת מערכת");
            }
          }
        })
        .catch((error) => {
          console.error("Error in login:", error);
          setState(prev => ({ ...prev, loading: false }));
          
          // Check for business errors in the error response
          if (error.response?.data?.IsBusinessError === true && error.response?.data?.ErrorMessage) {
            toast.error(error.response.data.ErrorMessage);
          } else {
            toast.error("שגיאת מערכת");
          }
        });
    } else {
      setState(prev => ({ ...prev, btnSendClass: "danger" }));
    }
  };

  useEffect(() => {
    const urlQuery = getAllUrlParams(window.location.href);
    const rakazId = urlQuery.rakazid || false;
    const sayeretId = urlQuery.sayeretid || false;

    if (rakazId) {
      localStorage.setItem("rakazid", rakazId);
    } else if (sayeretId) {
      localStorage.setItem("sayeretid", sayeretId);
    }
  }, []);

  const sendRules = state.IDNO && state.Password;
  const isMobile = siteInfo?.isMobile || false;
  const m_picTop = `${RestUrls.pagesPictures}register/m_registerTop.jpg?v=4`;

  return (
    <div className="firstRegister loginPage animate__animated animate__fadeIn">
      <img
        src={loader}
        alt="loader"
        className={!state.loading ? "loader" : "loader active animate__animated animate__fadeIn"}
      />

      {isMobile && <img className="topPic" src={m_picTop} alt="top" />}

      <header>
        <h1 className="boldTypeFamily">התחברות</h1>
        <p>ברוכים השבים לאגודה להתנדבות</p>
      </header>

      <div className="inputs">
        <div className="line">
          <CustomFloatInput
            name="IDNO"
            updateValue={updateValue}
            value={state.IDNO}
            placeholder="תעודת זהות"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="number"
            checkInputs={state.checkInputs}
            checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
          />
        </div>

        <div className="line">
          <CustomFloatInput
            name="Password"
            updateValue={updateValue}
            value={state.Password}
            placeholder="סיסמה"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="password"
            checkInputs={state.checkInputs}
            checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
          />
          <div className="singIn reSendPass">
            <NavLink
              className="jumpPage"
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to="/reSendPassNew"
            >
              <span>
                <strong>שכחתי סיסמה</strong>  
              </span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="text-center">
        <Button
          disabled={state.loading}
          className="sendBlueBtn"
          onClick={() => sendForm(sendRules)}
          size="lg"
          variant={sendRules ? "success animate__animated animate__bounceIn" : state.btnSendClass}
        >
          התחברות
        </Button>
        
        {window.ReactNativeWebView === undefined && (
          <div className="singIn">
            <NavLink
              className="jumpPage"
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to="/register"
            >
              <span>
                עדין לא רשומים? <strong>לחצו להרשמה (נרשמים חדשים)</strong>
              </span>
            </NavLink>
          </div>
        )}
      </div>

      {state.responseLogin?.error && (
        <ModalDefaul
          variant="error"
          params={{ title: "שגיאה", text: state.responseLogin.error }}
          callBack={() => setState(prev => ({ ...prev, responseLogin: false }))}
        />
      )}
    </div>
  );
};

export default Login;
