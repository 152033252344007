/**
 * fileFunctions - פונקציות עזר לטיפול בקבצים ובטפסים
 * 
 * כולל פונקציות ל:
 * - שליחת קבצים לשרת (sendFileFormToClientApi)
 * - בדיקת תאריכים (checkDatesAPI)
 * - קריאה לנתונים מה-API (getFromApiSherutLeumi)
 * - המרת תשובות מהשרת למבנה נוח לשימוש (objectsMyforms)
 * - טקסטים לסוגי הקבצים השונים (filesTexts)
 */
import { RestUrls } from "../../../Components/-Helpers-/config";
import { toast } from 'react-toastify';
import axios from 'axios';
import { map } from "lodash";

export function getFromApiSherutLeumi(url, sendObj, setLoading, setResponseData) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;

    setLoading(true);
    
    const json = JSON.stringify(sendObj);
    
    axios.post(`${API_BASE_URL}${url}`, json, {
        headers: {'Content-Type': 'application/json'},
    })
    .then(res => {
        setLoading(false);
        setResponseData(res);
    })
    .catch((error) => {
        setLoading(false);
        setResponseData({});
        toast.error('שגיאה', {
            toastId: 'error'
        });
        console.log(error);
    });
}

//NOT IN USE - OLD OLD OLD
export function sendFormToClientApi(sendData, setLoading, clearData, setShowThanksModal = false, setResponseData = null) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
    
    setLoading(true);
    
    const sendObj = {
        IDNumber: sendData.IDNumber,
        SessionKey: sendData.SessionKey,
        formId: sendData.formId,
        formData: sendData.formData,
    };

    return fetch(`${API_BASE_URL}/api/v2/volunteer/forms/send`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendObj)
    })
    .then((res) => res.json())
    .then((data) => {
        if(data?.Result === 'Success') {
            // First handle UI updates for successful submission
            if(setShowThanksModal) {
                setShowThanksModal(true);
            }
            
            // Now refresh the files list if we have a handler
            if (setResponseData) {
                console.log("Refreshing files list after successful form submission...");
                const listObj = {
                    IDNumber: sendObj.IDNumber,
                    SessionKey: sendObj.SessionKey
                };
                
                return fetch(`${API_BASE_URL}/api/v2/volunteer/forms/list`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(listObj)
                })
                .then(listRes => listRes.json())
                .then(listData => {
                    console.log('Files list refreshed successfully:', listData);
                    
                    // Update the state with the refreshed data
                    setResponseData(listData);
                    
                    // Now complete all operations
                    setLoading(false);
                    clearData();
                })
                .catch(error => {
                    console.error('Error refreshing files list:', error);
                    setLoading(false);
                    clearData();
                });
            } else {
                console.log("No setResponseData handler provided");
                setLoading(false);
                clearData();
            }
        } else {
            console.error("Form submission failed:", data);
            setLoading(false);
            toast.error('שגיאה בשליחת הטופס');
        }
    })
    .catch((error) => {
        console.error("Exception in form submission:", error);
        setLoading(false);
        toast.error('שגיאה בשליחת הטופס');
    });
}

export function sendFileFormToClientApi(sendData, setLoading, clearData, setShowThanksModal = false, onSuccessCallback = null) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;
    
    // Set loading state before starting the upload
    if (setLoading) setLoading(true);

    const formData = new FormData();
    formData.append('file', sendData.file);

    const sendObj = {
        SessionKey: sendData.SessionKey,
        IDNumber: sendData.IDNumber,
        FormID: sendData.formId,
    };

    // Flag to mark if this is a sick leave form (FormID 10)
    const isSickLeaveForm = sendData.formId === '10';

    if(isSickLeaveForm) {
        sendObj.SikDate = sendData.dates.SikDate;
        sendObj.SikDays = sendData.dates.SikDays;
    }
   
    formData.append('jsonData', JSON.stringify(sendObj));

    return fetch(`${API_BASE_URL}/api/v2/volunteer/forms/send`, {
        method: 'post',
        body: formData,
    })
    .then((res) => {
        // First convert the response to JSON to check its content
        return res.json().catch(err => {
            // If it's not valid JSON, just return the original response
            return res;
        });
    })
    .then((data) => {
        // Always clear loading state
        if (setLoading) setLoading(false);

        // Check for success in JSON response
        if (data?.Result === 'Success' || data?.statusText === "OK") {
            console.log("Upload successful, showing thanks modal...");
            
            // Add success toast notification for immediate feedback
            toast.success('המסמך הועלה בהצלחה!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            
            // On success just show the modal
            if(setShowThanksModal) {
                setShowThanksModal(true);
            }
            
            // If this is a sick leave form (FormID 10), we need to fetch updated status
            if (isSickLeaveForm) {
                console.log("Sick leave form submitted, fetching updated form status");
                
                // Make a request to get updated form list
                const listObj = {
                    SessionKey: sendObj.SessionKey,
                    IDNumber: sendObj.IDNumber
                };
                
                // Making a direct fetch to refresh our form list in the background
                fetch(`${API_BASE_URL}/api/v2/volunteer/forms/list`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(listObj)
                })
                .then(listRes => listRes.json())
                .then(listData => {
                    console.log('Forms list refreshed successfully after sick leave submission');
                    
                    // If a success callback was provided, call it with both the original data and the refreshed list
                    if (onSuccessCallback && typeof onSuccessCallback === 'function') {
                        console.log("Calling upload success callback with refreshed form data");
                        // Add a small delay to ensure modal is shown first
                        setTimeout(() => {
                            onSuccessCallback({ ...data, refreshedForms: listData });
                        }, 100);
                    }
                })
                .catch(error => {
                    console.error('Error refreshing forms list:', error);
                    // Still call the success callback even if refresh failed
                    if (onSuccessCallback && typeof onSuccessCallback === 'function') {
                        setTimeout(() => {
                            onSuccessCallback(data);
                        }, 100);
                    }
                });
            } else {
                // For other form types, just call the success callback
                if (onSuccessCallback && typeof onSuccessCallback === 'function') {
                    console.log("Calling upload success callback");
                    // Add a small delay to ensure modal is shown first
                    setTimeout(() => {
                        onSuccessCallback(data);
                    }, 100);
                }
            }
            
            // Only clear data if no success callback was provided
            // When a success callback is provided, it's the callback's 
            // responsibility to clear data after showing the thank you modal
            if (!onSuccessCallback && clearData) {
                clearData();
            }
        } else {
            console.error('Upload failed:', data);
            toast.error('שגיאה בהעלאת הקובץ');
            
            // Always clear data on error
            if (clearData) clearData();
        }
    })
    .catch((err) => {
        console.error('Error in upload process:', err);
        if (setLoading) setLoading(false);
        if (clearData) clearData();
        toast.error('שגיאה בהעלאת הקובץ');
    });
}

export async function sendReadAndSignToClientApi(sendData, setLoading, clearData, showStep2Modal, onComplete) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;

    setLoading(true);

    const sendObj = {
        IDNumber: sendData.IDNumber,
        SessionKey: sendData.SessionKey, 
        FormID: sendData.formId,
    }
    const API = API_BASE_URL + '/api/v2/volunteer/Forms/getcode';

    try {
        const res = await fetch(API, {
            method: "POST",
            headers: {
            "Content-Type" : 'application/json',
            "Accept" : 'application/json'
            },
            body: JSON.stringify(sendObj)
        });

        const data = await res.json();
        console.log("GetCode API response:", data);

        if( data?.Result === 'Success' && data?.PasswordStatus === "Valid" ) {
            // Just show the next modal, don't refresh list yet
            // We'll refresh after the entire process is complete
            setLoading(false);
            clearData();
            showStep2Modal();
            // Call the onComplete callback if provided
            if (typeof onComplete === 'function') onComplete();
        } else {
            console.error('Error in getcode:', data);
            setLoading(false);
            toast.error('שגיאה');
            // Call the onComplete callback if provided
            if (typeof onComplete === 'function') onComplete();
        }

    } catch (e) {
        console.error('Exception in getcode:', e);
        setLoading(false);
        toast.error('שגיאה');
        // Call the onComplete callback if provided
        if (typeof onComplete === 'function') onComplete();
    }
}


export async function sendReadAndSignToClientApiStep2(sendData, setLoading, setEnterSMSCodeModal) {

    const environment = process.env.REACT_APP_ENVIRONMENT;
    const API_BASE_URL = environment === 'dev' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL;

    setLoading(true);

    const sendObj = {
        IDNumber: sendData.IDNumber,
        SessionKey: sendData.SessionKey, 
        FormID: sendData.formId,
        Code: sendData.code
    }

    console.log('Sending verification with data:', sendObj);
   
    
    const API = API_BASE_URL + '/api/v2/volunteer/Forms/send';

    try {
        const res = await fetch(API, {
            method: "POST",
            headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
            },
            body: JSON.stringify(sendObj)
        });

        const data = await res.json();
        console.log("Form send API response:", data);

        if( data?.Result === 'Success' ) {
            // Just close the modal, refresh will be triggered when the user
            // sees the success message and closes the SMS modal
            setLoading(false);
            setEnterSMSCodeModal(false);
            toast.success('המסמך עלה בהצלחה');
            return true; // Return a resolved promise
        } else {
            console.error('Error in form send:', data);
            setLoading(false);
            toast.error('שגיאה');
            return false; // Return a resolved promise with false
        }

    } catch (e) {
        console.error('Exception in form send:', e);
        setLoading(false);
        toast.error('שגיאה');
        throw e; // Let the caller handle the error
    }
}


export function objectsMyforms(forms) {
    let objReturn = {};

    console.log("objectsMyforms called with forms:", forms);
    
    map(forms, item => {
        console.log(`Processing form: FormID${item.FormID}, FormName: ${item.FormName}, Status: ${item.Status}`);
        objReturn['FormID' + item.FormID] = item;
    });

    console.log("objectsMyforms result:", objReturn);
    console.log("Does result contain FormID13?", !!objReturn['FormID13']);
    
    return objReturn;
}


    export function checkDatesAPI(datesInput,setDatesOk,setLoading) {
    const fd = new FormData();

    fd.append('StartDate', datesInput.StartDate);
    fd.append('EndDate', datesInput.EndDate);

        axios.post( "https://sherut-leumi.wdev.co.il/api/documents/getDates", fd, {
        //onUploadProgress: progressEvent => {setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100 ));}
    })
        .then( res => {

            //console.log(res.data);

            if(res?.data) {

                setDatesOk(res?.data);
            
            } else {

                toast.error('שגיאה');

            }

            //console.log(res.data);
        
        })
        
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה');
            console.log(error)
        })
                
                

}



export const filesTexts = {

    tz : {

        title : 'צילום ת.ז + ספח',
        filesType : '(jpg,png,pdf)',
        startText : 'המסמך יאושר אם יכיל את תעודת הזהות והספח יחד, כאשר הספח פתוח במלואו',
        thankText : 'צילום ת"ז + ספח נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב'

    },


   bank : {

        title : "אישור ניהול חשבון בנק",
        filesType : '(jpg,png,pdf)',
        startText : 'המסמך יאושר רק אם יכיל את כל הפרטים: שמך המלא | שם הבנק | מספר הסניף | מספר החשבון',
        thankText : 'אישור ניהול חשבון בנק נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'

    },

    facePicture : {

        title : "תמונת פנים",
        filesType : '(jpg,png,pdf)',
        startText : 'התמונה תאושר רק אם תכיל פנים ברורות, על רקע בהיר, ללא אובייקטים נוספים בתמונה. (תמונת פספורט) *התמונה תופיע גם על גבי כרטיס המתנדב/ת',
        thankText : 'תמונת הפנים נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'

    },

    medicalCertificate : {

        title : "אישור רפואי",
        filesType : '(jpg,png,pdf)',
        startText : 'אישור רפואי | אישור בריאות יאושר רק אם נחתם על ידי הרופא/ה המטפל/ת',
        thankText : 'אישור רפואי נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'

    },

    ishurMahala : {

        title : "אישור מחלה",
        filesType : '(jpg,png,pdf)',
        startText : 'לחצו על הכפתור לבחירת הקובץ',
        thankText : 'אישור רפואי נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.',
        pendingText: 'המסמך ממתין לאישור, ניתן להעלות מסמך חדש',
        existText: 'המסמך אושר, ניתן להעלות מסמך חדש במידת הצורך'

    },

    ishurAvoda : {
        title : "אישור עבודה",
        filesType : '(jpg,png,pdf)',
        startText : 'לחצו על הכפתור לבחירת הקובץ',
        thankText : 'אישור עבודה נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    ishurLimudim : {
        title : "אישור לימודים",
        filesType : '(jpg,png,pdf)',
        startText : 'לחצו על הכפתור לבחירת הקובץ',
        thankText : 'אישור לימודים נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    militar : {

        title : "פטור מהצבא",
        filesType : '(jpg,png,pdf)',
        startText : 'המסמכים היחידים שיאושרו הם: תעודת פטור ומכתב שחרור עם חותמת צה"ל. לא יאושרו: הצהרה בבית דין | מכתב נלווה לתעודת פטור | הודעה על מצב רפואי | הודעה למלש"ב',
        thankText : 'אישור בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'

    },

    klali : {

        title : "מסמך כללי",
        filesType : '(jpg,png,pdf)',
        startText : 'לחצו על הכפתור לבחירת הקובץ',
        thankText : 'מסמך כללי נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'

    },


    diraProblem : {

        title : "דיווח תקלה בדירה",
        btnName : "מעבר לאתר",
        filesType : '',
        startText : 'בלחיצה על הכפתור נעביר אותך לאתר חיצוני.',
        startTextP : '',

    },


    custodian : {

        title : "אישור אפוטרופוס",
        filesType : '(jpg,png,pdf)',
        startText : 'המסמך יאושר אם יכיל חתימות ידניות של זוג ההורים. *במקרים בהם לא ניתן להשיג שתי חתימות, יש לפנות לרכז/ת',
        thankText : 'אישור אפוטרופוס נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'

    },

    formId16 : {
        title : "ועדה רפואית", //בקשה להגשה ל 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'בקשה להגשה לועדה רפואית נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },


    formId17 : {
        title : "סיכום רפואי לועדה רפואית", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'סיכום רפואי לועדה רפואית נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId18 : {
        title : "אישור עבודה", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'אישור עבודה נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId19 : {
        title : "אישור לימודים", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'אישור לימודים נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId20 : {
        title : "מוכרות במנהל מוגבלויות", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'א.מוכרות במנהל מוגבלויות נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },


    formId21 : {
        title : "אבחון פסיכולוגי", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'אבחון פסיכולוגי נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId22 : {
        title : "א.רפואי", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'א.רפואי נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId23 : {
        title : "קצבת ביטוח לאומי", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'קצבת ביטוח לאומי נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId24 : {
        title : "ועדת השמה", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'ועדת השמה נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId25 : {
        title : "דוח תפקודי", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'דוח תפקודי נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId26 : {
        title : "א.מוכרות ברווחה", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'א.מוכרות ברווחה נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId27 : {
        title : 'דו"ח סוציאלי', 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'דו"ח סוציאלי נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    formId28 : {
        title : "תסקיר קצין מבחן", 
        filesType : '(jpg,png,pdf)',
        startText : 'יש לבחור קובץ',
        thankText : 'תסקיר קצין מבחן נשלחה בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },


    


    //external Link
    question : {

        title : "שאלון הכוון",
        btnName : "מעבר לשאלון",
        filesType : '',
        startText : 'בלחיצה על הכפתור נעביר אותך לשאלון באתר הרשות לשירות לאומי אזרחי.',
        startTextP : 'שאלון שנשלח בהצלחה יעודכן ב-2 ימי העבודה הקרובים.',

    },

    //OpenPDFfiles
    link101 : {

        title : "טופס 101",
        btnName : "מעבר לטופס",
        //url : 'https://sherut-leumi.wdev.co.il/api/assets/files/tofes-101.pdf',
        formId : '101'
    },

    //OpenPDFfiles
    link102 : {

        title : "אישור תקופת שירות",
        btnName : "מעבר לטופס",
        //url : '',
        formId : '102'
    },


    // אישור הצבה 
    link103 : {

        title : "אישור הצבה",
        btnName : "מעבר לטופס",
        //url : '',
        formId : '103'
    },

    //דוח תקבולים
    link104 : {

        title : "דוח תקבולים",
        btnName : "מעבר לטופס",
        //url : '',
        formId : '104'
    },

    linkLimudim : {

        title : "אישור לימודים",
        btnName : "מעבר לטופס",
        //url : 'https://www.sherut-leumi.co.il/datiot/album/FRM%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%90%D7%99%D7%A9%D7%95%D7%A8_%D7%9C%D7%99%D7%9E%D7%95%D7%93%D7%99%D7%9D.pdf',
        formId : '119'
    },

    linkAvoda : {

        title : "אישור עבודה",
        btnName : "מעבר לטופס",
        //url : 'https://www.sherut-leumi.co.il/datiot/album/FRM%D7%90%D7%99%D7%A9%D7%95%D7%A8_%D7%A2%D7%91%D7%95%D7%93%D7%94.pdf',
        formId : '118'
    },


    //ReadAndSign
    generalCharge: {
        title : "הרשאת חיוב כללי",
    },

    gius: {
        title : "הצהרת פטור מגיוס",
        startText : "אנא קרא/י בעיון את ההצהרה וחתום/חתמי בתחתית העמוד",
        thankText : "הצהרת פטור מגיוס נשלחה בהצלחה",
        thankpText : "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב."
    },
    
    ipuiCoach: {
        title : 'יפוי כח',
    },

    takanon : {
        title : "תקנון",
    },

    dira : {
        title : "הסכם דירה",
    },
    
    // Add new document type definitions
    ishurAvoda : {
        title : "אישור עבודה",
        filesType : '(jpg,png,pdf)',
        startText : 'לחצו על הכפתור לבחירת הקובץ',
        thankText : 'אישור עבודה נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

    ishurLimudim : {
        title : "אישור לימודים",
        filesType : '(jpg,png,pdf)',
        startText : 'לחצו על הכפתור לבחירת הקובץ',
        thankText : 'אישור לימודים נשלח בהצלחה',
        thankpText : 'יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.'
    },

}