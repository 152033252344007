/**
 * ReadAndSign - קומפוננט לקריאה וחתימה על מסמכים
 * 
 * מאפשר למשתמש לקרוא מסמך ולחתום עליו דיגיטלית
 * למשל: תקנון, הסכם דירה, הרשאת חיוב
 * 
 * Props:
 * - formData: נתוני הטופס
 * - texts: טקסטים להצגה
 */
import React, { useState, useEffect } from 'react'

import v from '../../../img/sherut-leumi/svg/files/v.svg';
import signature from '../../../img/sherut-leumi/svg/files/signature.svg';

import { Modal, Button } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import { Form, FloatingLabel } from 'react-bootstrap'

import { getFromApiSherutLeumi, sendReadAndSignToClientApi } from './fileFunctions';
import loader from "../../../img/preLoader.gif";

export default function ReadAndSign(props) {
    const { formData, texts, userJ } = props;
    
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [enterSMSCodeModal, setEnterSMSCodeModal] = useState(false);
    const [smsCodeInput, setSmsCodeInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
    const [formTextData, setFormTextData] = useState(false);

    if (showFirstModal && !loading && !formTextData) {
        const sendObj = {
            IDNumber: userJ.IDNO,
            SessionKey: userJ.SessionKey,
            FormID: formData.FormID
        }

        getFromApiSherutLeumi('/api/v2/Volunteer/form/getContent', sendObj, setLoading, setFormTextData);
    }

    const openMyModal = () => {
        setShowFirstModal(true);
    }

    const clearData = () => {
        // Function kept for future use
    }

    const showStep2Modal = () => {
        setShowFirstModal(false);
        setEnterSMSCodeModal(true);
    }

    const sendForm = () => {
        setButtonDisabled(true);

        const sendData = {
            IDNumber: userJ.IDNO,
            SessionKey: userJ.SessionKey,
            formId: formData.FormID
        }

        // Define a callback to re-enable the button
        const onComplete = () => {
            setButtonDisabled(false);
        };

        setLoading(true);
        // Pass the onComplete callback as the last parameter
        sendReadAndSignToClientApi(sendData, setLoading, clearData, showStep2Modal, onComplete);
        
        // Safety timeout to prevent the button from staying disabled forever
        setTimeout(() => {
            setButtonDisabled(false);
        }, 10000); // 10 seconds max wait time
    }

    const sendVerificationCode = () => {
        setVerifyButtonDisabled(true);

        const sendData = {
            Code: smsCodeInput,
            FormId: formData.FormID,
            SessionKey: userJ.SessionKey
        }

        setLoading(true);
        
        // Using the same API base as GetCode but with validateCode instead
        getFromApiSherutLeumi('/api/v2/Volunteer/forms/validateCode', sendData, setLoading, (response) => {
            // Re-enable the button regardless of the response
            setVerifyButtonDisabled(false);
         
            if (response.data?.isBusinessError) {
                // Handle business error
                console.error("Business error:", response.data?.message || "Code validation failed");
                // Keep modal open to allow user to retry
            } else {
                // Success - close the modal
                setEnterSMSCodeModal(false);
                
                // Refresh file list after successful verification
                setTimeout(() => {
                    try {
                        import('./FilesPage').then(module => {
                            if (typeof module.refreshFilesList === 'function') {
                                console.log("Refreshing files list after SMS verification...");
                                setSmsCodeInput('');
                                module.refreshFilesList();
                            }
                        });
                    } catch (e) {
                        console.error("Error refreshing files list:", e);
                    }
                }, 1000);
            }
        });
        
        // Safety timeout to prevent the button from staying disabled forever
        setTimeout(() => {
            setVerifyButtonDisabled(false);
        }, 10000); // 10 seconds max wait time
    }
    
  return (
    <div className='fileItem'>
        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <div className='header'>
            <h3>
              <span>{texts.title}</span>
            </h3>
            <p>{texts.filesType}</p>
        </div>

        {formData.Status !== "Missing" ? (
            <div className='blueQuad docExist fullBorder'>
                <img src={v} alt='המסמך קיים במערכת' />
                <p>המסמך קיים במערכת</p>
            </div>
        ) : (
            <div className='blueQuad externalLink fullBorder'>
                <div className='linkP'>קריאה וחתימה</div>
                <button className='readAndSignBtn' onClick={openMyModal}>
                    <img src={signature} alt='signature' />
                </button>
            </div>
        )}
        
        {formTextData?.data?.Html && (
            <Modal
                className='animate__animated animate__fadeInDown animate__faster modalSite filesModal'
                animation={false}
                onHide={() => setShowFirstModal(false)}
                size="lg"
                show={showFirstModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <Button className="closeBtn" onClick={() => setShowFirstModal(false)} variant="secondary">X</Button>
                    
                    <div className="Message">
                        <h2>{texts.title}</h2>
                    </div>

                    <div className='textReadAndSign'>
                        <div dangerouslySetInnerHTML={{ __html: formTextData.data.Html }} />
                    </div>

                    <Grid className="formCont" container spacing={2} alignItems="center" direction="row" justifyContent="center">
                        <Grid item md={5}>
                            <button 
                                style={{backgroundColor: buttonDisabled ? '#ccc' : '#007bff'}} 
                                className={buttonDisabled ? 'blueSignBtn disabled' : 'blueSignBtn'} 
                                onClick={sendForm} 
                                disabled={buttonDisabled}
                            >
                                {buttonDisabled ? 'קוד אימות בדרך...' : 'אישור קריאה וחתימה'}
                            </button>
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        )}

        <Modal
            className='animate__animated animate__fadeInDown animate__faster modalSite filesModal SMSCodeModal'
            animation={false}
            onHide={() => setEnterSMSCodeModal(false)}
            size="sm"
            show={enterSMSCodeModal}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body>
                <Button 
                    className="closeBtn" 
                    onClick={() => {
                        // Close the modal
                        setEnterSMSCodeModal(false);
                        
                        // Refresh the files list
                        try {
                            // Import dynamically to avoid circular dependency
                            import('./FilesPage').then(module => {
                                if (typeof module.refreshFilesList === 'function') {
                                    console.log("Refreshing files list after closing SMS modal...");
                                    module.refreshFilesList();
                                } else {
                                    console.log("Global refresh function not available");
                                }
                            }).catch(err => {
                                console.error("Error importing FilesPage module:", err);
                            });
                        } catch (e) {
                            console.error("Error refreshing files list:", e);
                        }
                    }} 
                    variant="secondary" 
                >X</Button>

                <div className="Message">
                    <h2>הכנסו את הקוד שקיבלתם ב-SMS</h2>
                </div>

                <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                    <Grid item md={12}>
                        <FloatingLabel label='קוד SMS'>
                            <Form.Control 
                                className="customFloatInput"
                                type='text'
                                disabled={false}
                                placeholder='קוד SMS'
                                onChange={e => setSmsCodeInput(e.target.value)}
                                value={smsCodeInput}
                            />
                        </FloatingLabel>
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                    <Grid item md={6}>
                        <button className='blueBtn' onClick={sendVerificationCode} disabled={verifyButtonDisabled}>
                            בדיקת קוד
                        </button>
                    </Grid>
                </Grid>
            </Modal.Body>
        </Modal>
    </div>
  )
}
