import React, { useState, useEffect } from "react";
import { getFromApiSherutLeumi } from "../UserConsole/files/fileFunctions";
import loader from "../../img/preLoader.gif";

export default function PrintView() {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [printDialogShowed, setPrintDialogShowed] = useState(false);

  useEffect(() => {
    const userJ = JSON.parse(localStorage.getItem("userData"));

    if (!loading && !responseData) {
      const sendObj = {
        idno: userJ.IDNO,
        SessionKey: userJ.SessionKey,
        FormID: 101,
      };

      getFromApiSherutLeumi(
        "/api/v2/volunteer/data/getDocument",
        sendObj,
        setLoading,
        setResponseData
      );
    }

    if (responseData?.data?.Html && !loading && !printDialogShowed) {
      setPrintDialogShowed(true);
      setTimeout(() => {
        window.print();
      }, 200);
    }
  }, [loading, responseData, printDialogShowed]);

  return (
    <div className="printView">
      <img
        src={loader}
        alt="loader"
        className={
          loading ? "loader active animate__animated animate__fadeIn" : "loader"
        }
      />

      {responseData?.data?.Html ? (
        <div
          className="showHtml"
          dangerouslySetInnerHTML={{ __html: responseData.data.Html }}
        />
      ) : (
        responseData && (
          <h1 style={{ color: "red", textAlign: "center", padding: "20px 0" }}>
            שגיאה
          </h1>
        )
      )}
    </div>
  );
}
